/* eslint-disable unicorn/prefer-query-selector */
import { getLastModifiedActivityId, removeLastModifiedActivityId } from '../../../../../../../../common/utils/classroomActions';
const scrollToLastModifiedActivity = (element) => {
    element.scrollIntoView({ behavior: 'smooth' });
    removeLastModifiedActivityId();
};
export function scrollIntoActivityWhenPossible() {
    const lastModifiedClassroomId = getLastModifiedActivityId();
    if (!lastModifiedClassroomId)
        return;
    const scrollableArea = document.getElementById('advanced-view-mode-table--scrollable-area');
    if (!scrollableArea)
        return;
    let element = document.getElementById(lastModifiedClassroomId);
    if (element) {
        setTimeout(() => scrollToLastModifiedActivity(element));
        return;
    }
    const observer = new MutationObserver(() => {
        element = document.getElementById(lastModifiedClassroomId);
        if (!element)
            return;
        setTimeout(() => scrollToLastModifiedActivity(element));
        observer.disconnect();
    });
    observer.observe(scrollableArea, {
        childList: true,
        subtree: true
    });
}
