import React from 'react';
import { OverlayTrigger } from '@campus/components';
import BlockedIcon from '@campus/components/assets/icons/blocked.svg';
import { CustomTooltipWrapper, Image } from './styles';
const BlockedItem = ({ size }) => {
    const renderOverlay = (props) => (React.createElement(CustomTooltipWrapper, { id: "tooltip-blocked", ...props }, "Voc\u00EA n\u00E3o tem os direitos de acesso a esta obra"));
    return (React.createElement(OverlayTrigger, { placement: "top", overlay: renderOverlay },
        React.createElement(Image, { src: BlockedIcon, alt: "Conte\u00FAdo blockeado", "$size": size })));
};
export default BlockedItem;
