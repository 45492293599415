import { handleApiCallError } from '../common';
import { addPlanning, getPlanningById } from '../pedagogicalPlanning';
import { addPlanningObject } from '../pedagogicalPlanningObject';
import { addPlanningObjective } from '../pedagogicalPlanningObjective';
function addObjectives(newPlanningId, existingPlanning, abortSignal) {
    return existingPlanning.objectives.map((objective) => {
        return addPlanningObjective({
            abortSignal,
            planningId: newPlanningId,
            programmed: objective.wasProgrammed,
            learningObjectiveId: objective.learningObjective.id
        });
    });
}
function addObjects(newPlanningId, existingPlanning, abortSignal) {
    return existingPlanning.objects.map((object) => {
        return addPlanningObject({
            abortSignal,
            planningId: newPlanningId,
            programmed: object.wasProgrammed,
            knowledgeObjectId: object.knowledgeObject.id
        });
    });
}
export async function duplicatePlanning(params) {
    try {
        const addPlanningResponse = await addPlanning({
            abortSignal: params.abortSignal,
            order: params.newIndex,
            gradeId: params.planning.gradeId,
            teacherId: params.planning.teacherId,
            segmentId: params.planning.segmentId,
            statusId: params.planning.status.id,
            areaSkillId: params.planning.areaSkill.id,
            disciplineId: params.planning.discipline.id,
            group: params.planning.associatedGroup
        });
        const addObjectivesPromises = addObjectives(addPlanningResponse.data.id, params.planning, params.abortSignal);
        const addObjectsPromises = addObjects(addPlanningResponse.data.id, params.planning, params.abortSignal);
        await Promise.all([...addObjectsPromises, ...addObjectivesPromises]);
        return getPlanningById({
            id: addPlanningResponse.data.id,
            abortSignal: params.abortSignal
        });
    }
    catch (error) {
        handleApiCallError(error);
    }
}
