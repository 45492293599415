import styled from 'styled-components';
import { Placeholder } from '../../../../../commons/components';
import { Box } from '../commons/styles/basics';
export const Container = styled(Box) `
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Text = styled(Placeholder.Text) `
  font-size: 18px;
`;
