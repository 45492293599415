import React from 'react';
import { compareDatesWithoutTime } from '../../../../../../../../../common/utils/dates';
import { Column } from './styles';
function datesAreEqual(date1, date2) {
    return compareDatesWithoutTime(date1, date2) === 0;
}
function formatDate(date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
}
export const ClassDate = ({ className, activity, classroom, classroomIndex }) => {
    if (classroomIndex > 0 &&
        datesAreEqual(classroom.date, activity.classrooms[classroomIndex - 1].date)) {
        return null;
    }
    function calculateRowSpan() {
        let rowSpan = 1;
        let nextClassroomIndex = classroomIndex + 1;
        while (nextClassroomIndex < activity.classrooms.length &&
            datesAreEqual(classroom.date, activity.classrooms[nextClassroomIndex].date)) {
            rowSpan += 1;
            nextClassroomIndex += 1;
        }
        return rowSpan;
    }
    return (React.createElement(Column, { className: className, rowSpan: calculateRowSpan() }, classroom.date ? formatDate(classroom.date) : ''));
};
