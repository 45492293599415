import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useContextManager } from '../../providers';
import { Banner, BannerUser, TextUnderline, Avatar } from './styles';
import { getStudentInfo } from '../../services/studentInfo';
import { getTeacherInfo } from '../../services/teacherInfo';
const PeriodBanner = () => {
    const { period } = useContextManager();
    const [showBanner, setShowBanner] = useState(false);
    const [searchParams] = useSearchParams();
    const studentId = searchParams.get('studentId');
    const teacherId = searchParams.get('teacherId');
    const [userInfo, setUserInfo] = useState(null);
    useEffect(() => {
        if (period.active == false) {
            setShowBanner(true);
        }
        if (studentId) {
            const fetchData = async (id) => {
                const result = await getStudentInfo(id);
                setUserInfo(result.data);
            };
            fetchData(studentId);
        }
        if (teacherId) {
            const fetchData = async (id) => {
                const result = await getTeacherInfo(id);
                setUserInfo(result.data);
            };
            fetchData(teacherId);
        }
    }, [period.active, studentId, teacherId]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Banner, { show: showBanner, dismissible: true, closeVariant: "white", onClose: () => setShowBanner(false) },
            React.createElement("p", null,
                "Voc\u00EA est\u00E1 acessando os dados do ano letivo de ",
                period.name,
                " somente para consulta.")),
        studentId || teacherId ? (React.createElement(BannerUser, null,
            React.createElement("p", null,
                React.createElement("strong", null, "Aten\u00E7\u00E3o:"),
                "\u00A0Voc\u00EA est\u00E1 acessando o ambiente do(a)",
                studentId ? ' estudante' : ' professor(a)',
                "\u00A0",
                studentId ? (React.createElement(TextUnderline, null, `${userInfo?.name} (${userInfo?.class.name})`)) : (React.createElement(TextUnderline, null, userInfo?.name)),
                React.createElement(Avatar, { img: userInfo?.imageUrl, size: "36px" })))) : null));
};
export default PeriodBanner;
