import { CampusIcon } from '@campus/components';
import styled from 'styled-components';
export const ContainerAndLine = styled.div `
  padding-top: 11px;
  margin-bottom: 14px;

  border-top: solid 1px ${(props) => props.theme.colors.gray5};

  display: flex;
  row-gap: 5px;
  column-gap: 10px;
  flex-wrap: wrap;
  align-items: flex-start;
`;
export const ShieldCheckIcon = styled(CampusIcon).attrs({
    name: 'shieldCheck'
}) `
  display: block;
  width: 20px;
  height: 20px;
  color: ${(props) => props.theme.colors.green3};
`;
export const ShieldQuestionMarkIcon = styled(CampusIcon).attrs({
    name: 'shieldQuestionMark'
}) `
  display: block;
  width: 20px;
  height: 20px;
  color: ${(props) => props.theme.colors.yellow1};
`;
export const Group = styled.div `
  display: flex;
  column-gap: 4px;
  flex-direction: row;
`;
export const Text = styled.span `
  color: ${(props) => props.theme.colors.gray600};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
`;
