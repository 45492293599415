import { useImperativeHandle, useState } from 'react';
export function useDialog(ref) {
    const [showDialog, setShowDialog] = useState(false);
    useImperativeHandle(ref, () => ({
        isDialogBeingShown() {
            return showDialog;
        },
        hideDialog() {
            setShowDialog(false);
        }
    }));
    return { showDialog, setShowDialog };
}
