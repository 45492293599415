import styled from 'styled-components';
import MenuOptionsDialog from '../MenuOptionsDialog';
export const Container = styled.button `
  padding: 0;

  position: relative;

  border: none;
  background: none;
`;
export const Dialog = styled(MenuOptionsDialog) `
  min-width: 158px;

  z-index: 1;

  position: absolute;
  top: calc(100% + 1px);
  left: -4px;
`;
