import React from 'react';
import { Route } from 'react-router-dom';
import routes from './pages/routes';
import FollowUp from './pages/FollowUp';
import AnnualTimeline from './pages/AnnualTimeline';
import WeeklyPlanning from './pages/WeeklyPlanning';
import LaunchReport from './pages/LaunchReport';
import SkillManagement from './pages/SkillManagement';
export function createPedagogicalPlanningRoutes(params) {
    return (React.createElement(Route, { path: routes.annualTimeline },
        React.createElement(Route, { index: true, element: React.createElement(AnnualTimeline, null) }),
        React.createElement(Route, { path: routes.followUp, element: React.createElement(FollowUp, null) }),
        React.createElement(Route, { path: routes.weeklyPlanning, element: React.createElement(WeeklyPlanning, null) }),
        params?.launchReport?.enable ? (React.createElement(Route, { path: routes.launchReport, element: React.createElement(LaunchReport, null) })) : null,
        React.createElement(Route, { path: routes.skillManagement, element: React.createElement(SkillManagement, null) })));
}
