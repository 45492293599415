import ClassificationType from './ClassificationType';
import Discipline from './Discipline';
class Classification {
    id;
    code;
    description;
    parents;
    items;
    parentsLabel;
    classificationType;
    discipline;
    constructor() {
        this.parents = [];
    }
    static fromJson(data) {
        const classification = new Classification();
        classification.id = data.Id;
        classification.code = data.Code;
        classification.description = data.Description;
        classification.parents =
            data.Parents?.map((item) => Classification.fromJson(item)) ?? [];
        classification.items =
            data.Items?.map((i) => Classification.fromJson(i)) ?? [];
        classification.discipline = Discipline.fromJson(data?.Discipline);
        classification.classificationType = ClassificationType.fromJson(data?.ClassificationType);
        const arrayParentsLabel = classification.parents?.map((item) => item.description);
        if (classification.discipline) {
            arrayParentsLabel.unshift(classification.discipline?.name);
        }
        classification.parentsLabel =
            arrayParentsLabel.length > 0 ? arrayParentsLabel.join(' → ') : '-';
        return classification;
    }
    get typeId() {
        return this.classificationType?.id;
    }
}
export default Classification;
