import styled from 'styled-components';
export const Container = styled.section `
  margin-top: 13px;
  margin-bottom: 19px;

  display: flex;
  row-gap: 5px;
  column-gap: 10px;
  flex-wrap: wrap;
  align-items: flex-start;
`;
