import { useEffect, useMemo, useState } from 'react';
import { useClassDisciplines } from '../../../providers';
import { EvaluationTypeMode, Variant } from '../../../models';
import { getStudentDetail } from '../../../services/student';
export const useStudentPerformace = (studentId) => {
    const { classDisciplines, loadingClassDisciplines } = useClassDisciplines(Variant.STUDENT);
    const [loading, setLoading] = useState(true);
    const [studentDetail, setStudentDetail] = useState();
    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            const studentResult = await getStudentDetail(studentId);
            if (studentResult.data) {
                setStudentDetail(studentResult.data);
            }
            setLoading(false);
        };
        getData();
    }, []);
    const evaluations = useMemo(() => {
        if (classDisciplines) {
            const first = classDisciplines[0];
            return (first?.evaluations?.map((e) => ({
                id: e.id,
                title: e.name,
                color: e.color,
                description: e.evaluationDescription,
                isPerformace: e.evaluationTypeMode == EvaluationTypeMode.PERFORMANCE
            })) ?? []);
        }
        return [];
    }, [classDisciplines]);
    return {
        loading: loading || loadingClassDisciplines,
        discipline: classDisciplines,
        evaluations,
        studentDetail
    };
};
