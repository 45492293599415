import React, { useState, useRef } from 'react';
import { Overlay } from 'react-bootstrap';
import CampusIcon from '../CampusIcon';
import { MenuRoot, MenuOption } from './styles';
const IconMenu = ({ containerRef, options, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef(null);
    const onButtonClick = (e) => {
        e.preventDefault();
        setIsOpen((prev) => !prev);
    };
    return (React.createElement(React.Fragment, null,
        children({ ref: buttonRef, onClick: onButtonClick }),
        React.createElement(Overlay, { rootClose: true, target: buttonRef?.current, show: isOpen, placement: "bottom-end", popperConfig: {
                modifiers: [{ name: 'offset', options: { offset: [0, 6] } }]
            }, onHide: () => setIsOpen(false), container: containerRef?.current }, (props) => (React.createElement(MenuRoot, { ...props }, options
            .filter((option) => option.display)
            .map((option) => (React.createElement(MenuOption, { key: option.title, title: option.title, onClick: (e) => {
                e.preventDefault();
                if (option.onClick) {
                    option.onClick();
                }
                setIsOpen(false);
            }, disabled: option.disabled },
            React.createElement(CampusIcon, { name: option.icon, size: option.size || 13, pb: 3 })))))))));
};
export default IconMenu;
