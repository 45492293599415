import React from 'react';
import { WithItemsContainer, WithoutItemsContainer, Title, ReadOnlyList, Message } from './styles';
const ReadOnlyItemList = ({ presentationItems }) => {
    if (!presentationItems || presentationItems.length === 0) {
        return (React.createElement(WithoutItemsContainer, null,
            React.createElement(Message, null, "Esta obra n\u00E3o possui itens adicionados.")));
    }
    return (React.createElement(WithItemsContainer, null,
        React.createElement(Title, null, "Apresenta\u00E7\u00E3o"),
        React.createElement(ReadOnlyList, { items: presentationItems })));
};
export default ReadOnlyItemList;
