import React from 'react';
import { Card, CampusIcon } from '@campus/components';
import { Container } from './styles';
import Title from '../Title';
const Document = ({ link, name, onChangeTitle, readOnly }) => {
    const handleClick = () => window.open(link);
    return (React.createElement(Card, { overflowHidden: true, fullHeight: true },
        React.createElement(Container, null,
            React.createElement("div", { className: 'iconClick', onClick: handleClick },
                React.createElement(CampusIcon, { name: "attachment" })),
            React.createElement(Title, { value: name, onChange: onChangeTitle, disabled: readOnly }))));
};
export default Document;
