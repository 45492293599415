import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import { RequestStatus } from '@campus/commons';
import { useLaunchReportContext } from '../../../../context';
import { useFetchClasses } from '../../hooks';
import { SelectDropdownIndicator } from '../SelectDropdownIndicator';
import { MultiValueSelectOption } from '../MultiValueSelectOption';
import { MultiValueSelectValue } from '../MultiValueSelectValue';
import { matchDisciplineIds, matchFilters, matchGradeId } from './utils';
import { Label } from '../commonStyles';
import { styles } from './styles';
export const Classes = () => {
    const theme = useTheme();
    const { events } = useLaunchReportContext();
    const { data, requestStatus } = useFetchClasses();
    const [value, setValue] = useState([]);
    const [selectedGradeId, setSelectedGradeId] = useState(null);
    const [selectedDisciplineIds, setSelectedDisciplineIds] = useState([]);
    useEffect(() => {
        const newIds = value.map((c) => c.id);
        events.filters.classIdsChanged.emit({ newIds });
    }, [value, events.filters.classIdsChanged]);
    useEffect(() => {
        return events.filters.gradeIdChanged.subscribe((payload) => {
            setSelectedGradeId(payload?.newId ?? null);
        });
    }, [events.filters.gradeIdChanged]);
    useEffect(() => {
        return events.filters.disciplineIdsChanged.subscribe((payload) => {
            setSelectedDisciplineIds(payload?.newIds ?? []);
        });
    }, [events.filters.disciplineIdsChanged]);
    useEffect(() => {
        setValue((currentValue) => {
            if (!selectedGradeId)
                return currentValue;
            return currentValue.filter((clasz) => {
                return matchGradeId(clasz, selectedGradeId);
            });
        });
    }, [selectedGradeId]);
    useEffect(() => {
        setValue((currentValue) => {
            if (!selectedDisciplineIds?.length)
                return currentValue;
            return currentValue.filter((clasz) => {
                return matchDisciplineIds(clasz, selectedDisciplineIds);
            });
        });
    }, [selectedDisciplineIds]);
    return (React.createElement("div", null,
        React.createElement(Label, null, "Turmas"),
        React.createElement(Select, { options: data ?? [], value: value, isMulti: true, isClearable: false, isSearchable: false, closeMenuOnSelect: false, hideSelectedOptions: false, styles: styles(theme), onChange: (newValue) => setValue(newValue), placeholder: "Todas as Turmas", noOptionsMessage: () => 'Nenhuma opção disponível', loadingMessage: () => 'Carregando turmas...', components: {
                Option: MultiValueSelectOption,
                MultiValue: MultiValueSelectValue,
                DropdownIndicator: SelectDropdownIndicator
            }, isLoading: requestStatus === RequestStatus.Loading, getOptionValue: ({ id }) => id, getOptionLabel: ({ name }) => name, filterOption: ({ data: clasz }) => {
                return matchFilters(clasz, selectedGradeId, selectedDisciplineIds);
            } })));
};
