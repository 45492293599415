export default class LiteraryWork {
    id = '';
    name = '';
    published = false;
    author;
    shortText;
    coverImageUrl;
    relatedVestibulars;
    unitId = '';
    studyPeriodId = '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromJson(json) {
        const instance = new LiteraryWork();
        instance.id = json.Id;
        instance.name = json.Name;
        instance.author = json.Author ?? undefined;
        instance.published = json.Published;
        instance.shortText = json.ShortText ?? undefined;
        instance.coverImageUrl = json.CoverImageUrl ?? undefined;
        instance.relatedVestibulars = json.RelatedVestibulars ?? undefined;
        instance.unitId = json.UnitId;
        instance.studyPeriodId = json.StudyPeriodId;
        return instance;
    }
}
