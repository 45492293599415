import React from 'react';
import { SegmentAndGrade } from './components/SegmentAndGrade';
import { CurriculumComponents } from './components/CurriculumComponents';
import { Container } from './styles';
/* import { Classes } from './components/Classes'; */
export const Filters = () => {
    return (React.createElement(Container, null,
        React.createElement(SegmentAndGrade, null),
        React.createElement(CurriculumComponents, null)));
};
