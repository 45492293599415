import { toast } from '@campus/components';
import { RequestStatus } from '@campus/commons';
import { checkIfClassroomsAreLinkedToOtherActivities, updateClassroomsForSubsequentActivities } from '../../../../../../../../../../../../../../../../services/didaticSequence';
import { updateDidacticSequenceClassrooms } from '../../../../../../../../../../../../../../../../services/agregators/updateDidacticSequenceClassrooms';
import { getGroupActivities, reorderGroupActivities } from '../../../../../../../../../../../../../../../../services/pedagogicalPlanning';
import { useWeeklyPlanningPageContext } from '../../../../../../../../../../../../../../context';
import { useGroupContext } from '../../../../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/context';
import { useIsMounted } from '../../../../../../../../../../../../../../../common/hooks';
import { times } from '../../../../../../../../../../../../../../../common/constants';
export function useActions({ activity, hideMenu, setRequestStatus }) {
    const { isMounted } = useIsMounted();
    const updateActivities = useGroupContext((store) => store.updateActivities);
    const updateActivity = useGroupContext((store) => store.sequenceOperations.update);
    const availableClassrooms = useWeeklyPlanningPageContext((store) => store.commonData.availableClassrooms.data);
    const refetchContent = useWeeklyPlanningPageContext((store) => store.mainTable.advancedViewMode.refetchContent);
    function resetStatusAfterDelay() {
        setTimeout(() => {
            if (isMounted()) {
                setRequestStatus(RequestStatus.NotStarted);
            }
        }, times.toResetARequestStatus);
    }
    function getNewClassrooms(newClassroomNumber) {
        return availableClassrooms
            .filter((group) => {
            return group.classrooms?.some((classroom) => {
                return activity.classrooms.some((c) => {
                    return classroom.id === c?.classroom?.id;
                });
            });
        })
            .flatMap((group) => {
            return group.classrooms?.filter((c) => {
                return c.number === newClassroomNumber;
            });
        });
    }
    async function reorderActivities() {
        const response = await reorderGroupActivities({
            pedagogicalPlanningId: activity.pedagogicalPlanningId
        });
        return !response.error;
    }
    async function getUpdatedGroupActivities() {
        const response = await getGroupActivities({
            pedagogicalPlanningId: activity.pedagogicalPlanningId
        });
        return response.data?.length >= 0 ? response.data : null;
    }
    async function updateSubsequentActivities(classrooms) {
        const response = await updateClassroomsForSubsequentActivities({
            didacticSequenceId: activity.id,
            classIds: classrooms.map((c) => c.class.id)
        });
        return !response.error;
    }
    function updateClassroomsFailed() {
        setRequestStatus(RequestStatus.Failed);
        toast.error('Falha ao atualizar aulas');
        resetStatusAfterDelay();
    }
    function reorderFailed(updatedActivity) {
        setRequestStatus(RequestStatus.Succeeded);
        updateActivity(updatedActivity);
        toast.error('As aulas foram atualizadas com sucesso porém não foi possível reordenar as atividades do bloco');
        hideMenu();
        resetStatusAfterDelay();
    }
    async function getUpdatedActivitiesFailed(updatedActivity) {
        setRequestStatus(RequestStatus.Succeeded);
        updateActivity(updatedActivity);
        toast.error('As aulas foram atualizadas e as atividades do bloco foram reordenadas porém não foi possível recuperar as atividades do bloco atualizadas');
        hideMenu();
        resetStatusAfterDelay();
    }
    function updateSubsequentActivitiesFailed(updatedActivity) {
        setRequestStatus(RequestStatus.Succeeded);
        updateActivity(updatedActivity);
        toast.error('As aulas foram atualizadas com sucesso porém não foi possível alterar as aulas das atividades subsequentes');
        hideMenu();
        resetStatusAfterDelay();
    }
    async function isThereAnyClassroomLinkedToOtherActivities(params) {
        const classrooms = getNewClassrooms(params.newClassroomNumber);
        const response = await checkIfClassroomsAreLinkedToOtherActivities({
            classroomIds: classrooms.map((c) => c.id),
            didacticSequenceId: params.activity.id,
            pedagogicalPlanningId: params.pedagogicalPlanningId
        });
        if (!response.data || response.error) {
            toast.error('Falha ao verificar se já existem atividades vinculadas às aulas da nova atividade');
            return false;
        }
        for (const classId in response.data) {
            if (response.data[classId]) {
                return true;
            }
        }
        return false;
    }
    async function justUpdateClassrooms(newClassroomNumber) {
        setRequestStatus(RequestStatus.Loading);
        const newClassrooms = getNewClassrooms(newClassroomNumber);
        const updateClassroomsResponse = await updateDidacticSequenceClassrooms({
            sequence: activity,
            newClassrooms
        });
        const updatedActivity = updateClassroomsResponse.data;
        if (!updatedActivity || updateClassroomsResponse.error) {
            updateClassroomsFailed();
            return;
        }
        const reorderSucceeded = await reorderActivities();
        if (!reorderSucceeded) {
            reorderFailed(updatedActivity);
            return;
        }
        const updatedActivities = await getUpdatedGroupActivities();
        if (!updatedActivities) {
            getUpdatedActivitiesFailed(updatedActivity);
            return;
        }
        setRequestStatus(RequestStatus.Succeeded);
        updateActivities(updatedActivities);
        hideMenu();
        resetStatusAfterDelay();
    }
    async function updateClassroomsAndSubsequentActivities(newClassroomNumber) {
        setRequestStatus(RequestStatus.Loading);
        const newClassrooms = getNewClassrooms(newClassroomNumber);
        const updateClassroomsResponse = await updateDidacticSequenceClassrooms({
            sequence: activity,
            newClassrooms
        });
        const updatedActivity = updateClassroomsResponse.data;
        if (!updatedActivity || updateClassroomsResponse.error) {
            updateClassroomsFailed();
            return;
        }
        const updateSucceeded = await updateSubsequentActivities(newClassrooms);
        if (!updateSucceeded) {
            updateSubsequentActivitiesFailed(updatedActivity);
            return;
        }
        const reorderSucceeded = await reorderActivities();
        if (!reorderSucceeded) {
            reorderFailed(updatedActivity);
            return;
        }
        setRequestStatus(RequestStatus.Succeeded);
        refetchContent?.();
    }
    return {
        justUpdateClassrooms,
        updateClassroomsAndSubsequentActivities,
        isThereAnyClassroomLinkedToOtherActivities
    };
}
