export const basePath = '/vestibular';
export const dynamicPathSegmentNames = {
    literaryWorkId: 'literaryWorkId'
};
const routes = {
    literaryWork: `${basePath}/literary-work/:${dynamicPathSegmentNames.literaryWorkId}`,
    dynamic: {
        literaryWork(literaryWorkId) {
            return `${basePath}/literary-work/${literaryWorkId}`;
        }
    }
};
export default routes;
