import styled from 'styled-components';
import { EditableText } from '@campus/components';
export const Message = styled.p `
  margin-bottom: 0;

  font-size: 16px;
  font-weight: 500;

  line-height: 24px;

  color: ${(props) => props.theme.colors.gray4};
`;
export const ContainerTitle = styled.div `
  display: flex;
  flex-direction: row;
`;
export const Title = styled(EditableText) `
  color: ${(props) => props.theme.colors.CSA.darkBlue};
  display: flex;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 113.2%;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const ContainerVideo = styled.div `
  margin: 37px 0px 18px 0px;

  @media ${(props) => props.theme.mediaQuery.mobile} {
    width: 400px;
  }
`;
export const Container = styled.div `
  grid-column: content;

  display: flex;
  flex-direction: column;
`;
export const DatePublish = styled.span `
  color: ${(props) => props.theme.colors.gray4};
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 113.2%;
  padding-top: 5px;
`;
export const ContentDeleteLiteraryWorksBody = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
  margin-top: 34px;
`;
export const LabelDeleteLiteraryWorks = styled.span `
  font-style: normal;
  font-size: 20px;
  font-weight: ${(props) => props.theme.weight.semiBold};
  line-height: 100%;
  margin-top: 8px;
  text-align: center;
  color: ${(props) => props.theme.colors.gray800};
  width: 265px;
`;
export const SubLabelDeleteLiteraryWorks = styled.span `
  text-align: center;
  font-style: normal;
  font-weight: ${(props) => props.theme.weight.semiBold};
  font-size: 14px;
  line-height: 130%;
  color: ${(props) => props.theme.colors.gray3};
  margin-bottom: 16px;
  margin-top: 7px;
`;
export const UserPhoto = styled.img `
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100%;
  margin-right: 7px;
`;
export const ContainerData = styled.div `
  margin-top: 8px;
  display: flex;
  flex-direction: row;
`;
export const ContainerInfo = styled.div `
  display: flex;
  flex-direction: column;
`;
export const UserName = styled.span `
  color: ${(props) => props.theme.colors.gray3};
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 113.2%;
`;
