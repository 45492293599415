import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AccessTimeContext from './Context';
import { useContextManager } from '../ContextManagerProvider';
import { AccessTimeState } from '../../models';
import { signalUseOfTheSystem } from '../../services/accesstime';
const timeSend = 60 * 5;
const timeIdle = 60 * 2.5;
const timeSkip = 10;
const AccessTimeProvider = ({ children }) => {
    const location = useLocation();
    const { classroom } = useContextManager();
    const [isOnline, setIsOnline] = useState(true);
    const [countIdle, setCountIdle] = useState(0);
    const [status, setStatus] = useState(AccessTimeState.Active);
    const timerRef = useRef();
    const addCounter = () => {
        if (location.pathname !== '/content')
            setCountIdle((old) => old + timeSkip);
    };
    const resetCounter = () => setCountIdle(0);
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);
    const handleVisibility = () => {
        if (document.visibilityState === 'visible') {
            setStatus(AccessTimeState.Active);
        }
        else {
            setStatus(AccessTimeState.Inactive);
        }
    };
    const startVerifyEvent = () => {
        window.addEventListener('visibilitychange', handleVisibility);
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
        window.addEventListener('keydown', resetCounter);
        window.addEventListener('mousemove', resetCounter);
        window.addEventListener('focus', resetCounter);
        timerRef.current = setInterval(() => {
            if (!isOnline)
                return;
            addCounter();
        }, timeSkip * 1000);
    };
    const sendSignalUseOfTheSystem = async () => {
        await signalUseOfTheSystem(classroom.id);
    };
    useEffect(() => {
        startVerifyEvent();
        return () => {
            window.removeEventListener('visibilitychange', handleVisibility);
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
            window.removeEventListener('keydown', resetCounter);
            window.removeEventListener('mousemove', resetCounter);
            window.removeEventListener('focus', resetCounter);
            clearInterval(timerRef.current);
        };
    }, []);
    useEffect(() => {
        if (countIdle > timeSend) {
            setStatus(AccessTimeState.Inactive);
        }
        else if (countIdle > timeIdle) {
            setStatus(AccessTimeState.Idle);
        }
        else {
            setStatus(AccessTimeState.Active);
        }
    }, [countIdle]);
    useEffect(() => {
        if (status === AccessTimeState.Inactive)
            sendSignalUseOfTheSystem();
    }, [status]);
    return (React.createElement(AccessTimeContext.Provider, { value: { isOnline, status } }, children));
};
export default AccessTimeProvider;
