import React from 'react';
import { formatAsPercentage } from '../../../utils';
import { Content, Box, Item } from '../styles';
const PercentageContainer = ({ total, percentages, showTotal = true }) => {
    if (percentages.length === 0) {
        return React.createElement("span", null, "Sem atividades");
    }
    return (React.createElement(Content, null,
        React.createElement(Box, null, percentages.map((p, i) => (React.createElement(Item, { key: i, ...p })))),
        React.createElement("span", null, showTotal ? formatAsPercentage(total) : '-')));
};
export default PercentageContainer;
