import { useState } from 'react';
import { RequestStatus } from '@campus/commons';
import { timeInMillisToResetFailedStatus } from '../times';
export function useUpdate() {
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    function updateStarted() {
        setRequestStatus(RequestStatus.Loading);
    }
    function updateSuceeded() {
        setRequestStatus(RequestStatus.Succeeded);
    }
    function updateFailed(response, callback) {
        if (!response?.isCancellation()) {
            setRequestStatus(RequestStatus.Failed);
            callback?.();
        }
    }
    function resetStatus() {
        if (requestStatus === RequestStatus.Succeeded) {
            setRequestStatus(RequestStatus.NotStarted);
        }
        else {
            setTimeout(() => setRequestStatus(RequestStatus.NotStarted), timeInMillisToResetFailedStatus);
        }
    }
    return {
        requestStatus,
        updateStarted,
        updateSuceeded,
        updateFailed,
        resetStatus
    };
}
