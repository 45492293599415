import Classification from './Classification';
class ClassificationType {
    id;
    name;
    description;
    classifications;
    static fromJson(data) {
        const classificationType = new ClassificationType();
        classificationType.id = data?.Id;
        classificationType.name = data?.Name;
        classificationType.description = data?.Description;
        const classifications = data?.Classifications;
        classificationType.classifications =
            classifications?.map((i) => Classification.fromJson(i)) ?? [];
        return classificationType;
    }
    get fullName() {
        return `${this.name} - ${this.description}`;
    }
}
export default ClassificationType;
