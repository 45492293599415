import { Api } from '@campus/auth';
import { Response, StudentDetail } from '../models';
export const getStudentDetail = async (studentId) => {
    try {
        const response = await Api.INSTANCE.getApi().get(`/student/${studentId}/detail`);
        return Response.fromData(StudentDetail.fromJson(response.data), response.status);
    }
    catch (e) {
        return Response.error(e);
    }
};
