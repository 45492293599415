import React, { useMemo, useState } from 'react';
import { Event, RequestStatus } from '@campus/commons';
import { useLiteraryWorkPageContext } from '../../context';
import { Context } from './context';
import Question from './components/Question';
import QuestionLists from './components/QuestionLists';
import NoQuestionsAdded from './components/NoQuestionsAdded';
import { useEventHandlers, useFetchQuestionSummaries } from './hooks';
import { Container, PlaceholderContainer, PlaceholderText } from './styles';
const statusAndPlaceholders = {
    [RequestStatus.Loading]: 'Carregando questões...',
    [RequestStatus.Failed]: 'Falha ao carregar questões'
};
const events = {
    aQuestionWasCreated: new Event(),
    aQuestionWasDeleted: new Event(),
    aQuestionWasUpdated: new Event()
};
const QuestionsTab = () => {
    const [selectedQuestionId, setSelectedQuestionId] = useState();
    const { literaryWorkId, questionsCanBe } = useLiteraryWorkPageContext();
    const { data: questionSummaries, requestStatus, refetchData: refetchQuestionSummaries } = useFetchQuestionSummaries({ literaryWorkId });
    const contextContent = useMemo(() => ({
        events,
        questionSummaries,
        selectedQuestionId,
        setSelectedQuestionId
    }), [questionSummaries, selectedQuestionId]);
    useEventHandlers({ ...contextContent, refetchQuestionSummaries });
    const isThereAnySummary = questionSummaries?.length > 0;
    const hideQuestionLists = questionsCanBe !== 'managed' && !isThereAnySummary;
    if (requestStatus in statusAndPlaceholders) {
        return (React.createElement(PlaceholderContainer, null,
            React.createElement(PlaceholderText, null, statusAndPlaceholders[requestStatus])));
    }
    return (React.createElement(Context.Provider, { value: contextContent },
        React.createElement(Container, null,
            hideQuestionLists ? null : React.createElement(QuestionLists, null),
            isThereAnySummary ? React.createElement(Question, null) : React.createElement(NoQuestionsAdded, null))));
};
export default QuestionsTab;
