import React, { forwardRef } from 'react';
import { useDialog } from '../../../../../commons/hooks';
import { Container, HamburgerIcon, List } from './style';
const MenuVideo = forwardRef((props, ref) => {
    const { showDialog, setShowDialog } = useDialog(ref);
    function toggleDialog() {
        setShowDialog((currentValue) => !currentValue);
    }
    const items = [
        {
            label: 'Apagar Vídeo',
            onClick: props.onClickDelete
        }
    ];
    return (React.createElement(Container, { onClick: toggleDialog, "$margin": props.margin },
        React.createElement(HamburgerIcon, null),
        showDialog ? React.createElement(List, { items: items }) : null));
});
MenuVideo.displayName = 'MenuVideo';
export default MenuVideo;
