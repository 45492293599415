import styled from 'styled-components';
import { EditableText, Button } from '@campus/components';
export const ContainerTitle = styled.div `
  display: flex;
  margin-top: 10px;
`;
export const Title = styled(EditableText) `
  color: ${(props) => props.theme.colors.gray2};
  font-style: normal;
  font-weight: 700;
  line-height: 113.2%;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 44px;
`;
export const Container = styled.div `
  flex-direction: column;
  width: 308px;
`;
export const ThumbnailContainer = styled.div `
  width: 308px;
  position: relative;
`;
export const DatePublish = styled.span `
  color: ${(props) => props.theme.colors.gray4};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 113.2%;
  padding-top: 3px;
`;
export const StyledButton = styled(Button) `
  position: absolute;
  top: 0%;
  left: 0%;
  width: 308px;
  height: 173px;
  border: none;
  border-radius: 4px;
  opacity: 0;
`;
export const UserPhoto = styled.img `
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100%;
  margin-right: 7px;
`;
export const ContainerData = styled.div `
  margin-top: 8px;
  display: flex;
`;
export const ContainerInfo = styled.div `
  display: flex;
  flex-direction: column;
`;
export const UserName = styled.span `
  color: ${(props) => props.theme.colors.gray3};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 113.2%;
`;
