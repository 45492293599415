import { stringify } from 'qs';
import { Api } from '@campus/auth';
import { PaginationModel, Response } from '@campus/commons';
import Grade from '../models/Grade';
import Class from '../models/Class';
import LaunchReportTeacher from '../models/LaunchReportTeacher';
import LaunchReportFiltersDiscipline from '../models/LaunchReportFiltersDiscipline';
import LaunchReportEmptyClassListDiscipline from '../models/LaunchReportEmptyClassListDiscipline';
import { calculateNumberOfPages } from './utils/pagination';
const endpoints = {
    get: {
        content({ managerId }) {
            return `/pedagogicalplanningreport/${managerId}`;
        },
        grades: '/grade',
        classes: '/class',
        disciplines: '/discipline/disciplinewithallgrades',
        alertMessage: '/pedagogicalplanningreport/alert-message',
        emptyTeacherClasses({ managerId, teacherId }) {
            return `/pedagogicalplanningreport/${managerId}/unfinished/${teacherId}`;
        }
    },
    sendEmail: {
        toASingleTeacher({ managerId, teacherId }) {
            return `/pedagogicalplanningreport/${managerId}/send-teacher-report/${teacherId}`;
        },
        toManyTeachers({ managerId }) {
            return `/pedagogicalplanningreport/${managerId}/send-teachers-reports`;
        }
    }
};
export async function getReportContent(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = endpoints.get.content(params);
        const skipCount = params.pageIndex * params.pageSize;
        const response = await axiosInstance.get(endpoint, {
            signal: params?.abortSignal,
            params: {
                ManagerId: params.managerId,
                GradeIds: params.gradeId ? [params.gradeId] : null,
                ClassIds: params.classIds,
                DisciplineIds: params.disciplineIds,
                InitialDate: params.startDate,
                LastDate: params.endDate,
                MaxResultCount: params.pageSize,
                SkipCount: skipCount
            },
            paramsSerializer(p) {
                return stringify(p, { arrayFormat: 'indices', skipNulls: true });
            }
        });
        const idsOfAllTeachers = Array.isArray(response.data.TeacherIds)
            ? response.data.TeacherIds
            : [];
        const teachers = PaginationModel.fromJson(LaunchReportTeacher, response.data.Teachers, skipCount);
        const numberOfPages = calculateNumberOfPages(teachers, params.pageSize);
        const result = {
            teachers: teachers.items,
            idsOfAllTeachers,
            numberOfPages
        };
        return Response.fromData(result, response.status);
    }
    catch (error) {
        return Response.fromError(error);
    }
}
export async function getGrades(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = endpoints.get.grades;
        const response = await axiosInstance.get(endpoint, {
            signal: params.abortSignal,
            params: {
                MaxResultCount: 1000,
                ManagerId: params.managerId
            }
        });
        const grades = PaginationModel.fromJson(Grade, response.data).items;
        return Response.fromData(grades, response.status);
    }
    catch (error) {
        return Response.fromError(error);
    }
}
export async function getDisciplines(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = endpoints.get.disciplines;
        const response = await axiosInstance.get(endpoint, {
            signal: params.abortSignal,
            params: {
                ManagerId: params.managerId
            }
        });
        const disciplines = Array.isArray(response.data)
            ? response.data.map((item) => LaunchReportFiltersDiscipline.fromJson(item))
            : [];
        return Response.fromData(disciplines, response.status);
    }
    catch (error) {
        return Response.fromError(error);
    }
}
export async function getClasses(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = endpoints.get.classes;
        const response = await axiosInstance.get(endpoint, {
            signal: params.abortSignal,
            params: {
                IncludeDisciplines: true,
                ManagerId: params.managerId,
                MaxResultCount: 1000
            }
        });
        const classes = PaginationModel.fromJson(Class, response.data).items;
        return Response.fromData(classes, response.status);
    }
    catch (error) {
        return Response.fromError(error);
    }
}
export async function getAlertMessage(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = endpoints.get.alertMessage;
        const response = await axiosInstance.get(endpoint, {
            signal: params.abortSignal,
            params: {
                TeacherId: params.teacherId,
                ManagerId: params.managerId,
                GradeIds: params.gradeId ? [params.gradeId] : null,
                ClassIds: params.classIds,
                DisciplineIds: params.disciplineIds,
                InitialDate: params.startDate,
                LastDate: params.endDate
            },
            paramsSerializer(p) {
                return stringify(p, { arrayFormat: 'indices', skipNulls: true });
            }
        });
        return Response.fromData(response.data, response.status);
    }
    catch (error) {
        return Response.fromError(error);
    }
}
export async function sendEmailToTeacher(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = endpoints.sendEmail.toASingleTeacher(params);
        const response = await axiosInstance.post(endpoint, null, {
            signal: params?.abortSignal
        });
        return Response.fromData(true, response.status);
    }
    catch (error) {
        return Response.fromError(error);
    }
}
export async function sendEmailToTeachers(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = endpoints.sendEmail.toManyTeachers(params);
        const response = await axiosInstance.post(endpoint, params.teacherIds, {
            signal: params?.abortSignal
        });
        return Response.fromData(true, response.status);
    }
    catch (error) {
        return Response.fromError(error);
    }
}
export async function getEmptyTeacherClasses(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = endpoints.get.emptyTeacherClasses(params);
        const skipCount = params.pageIndex * params.pageSize;
        const response = await axiosInstance.get(endpoint, {
            signal: params?.abortSignal,
            params: {
                GradeIds: params.gradeId ? [params.gradeId] : null,
                ClassIds: params.classIds,
                DisciplineIds: params.disciplineIds,
                InitialDate: params.startDate,
                LastDate: params.endDate,
                MaxResultCount: params.pageSize,
                SkipCount: skipCount
            },
            paramsSerializer(p) {
                return stringify(p, { arrayFormat: 'indices', skipNulls: true });
            }
        });
        const disciplines = PaginationModel.fromJson(LaunchReportEmptyClassListDiscipline, response.data, skipCount);
        const numberOfPages = calculateNumberOfPages(disciplines, params.pageSize);
        const result = { numberOfPages, disciplines: disciplines.items };
        return Response.fromData(result, response.status);
    }
    catch (error) {
        return Response.fromError(error);
    }
}
