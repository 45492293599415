import styled from 'styled-components';
import { mergeStyles } from 'react-select';
function selectCommonTextStyles(theme) {
    return {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '24px',
        color: theme.colors.gray700
    };
}
export const hovering = {
    color: 'hsl(0, 0%, 60%)',
    transitionMs: '150ms'
};
export function createSelectStyles(theme, overrides) {
    const textStyles = selectCommonTextStyles(theme);
    return mergeStyles({
        control: (baseStyles) => ({
            ...baseStyles,
            minHeight: '32px',
            boxShadow: 'none',
            padding: '0 8px 0 12px',
            gap: '12px',
            borderRadius: '6px',
            border: `1px solid ${theme.colors.gray200}`,
            ':hover': {
                cursor: 'pointer',
                border: `1px solid ${theme.colors.gray200}`
            }
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            ...textStyles,
            margin: 0,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }),
        noOptionsMessage: (baseStyles) => ({
            ...baseStyles,
            ...textStyles,
            padding: '0 2px'
        }),
        loadingMessage: (baseStyles) => ({
            ...baseStyles,
            ...textStyles,
            padding: '0 2px'
        }),
        input: (baseStyles) => ({
            ...baseStyles,
            ...textStyles,
            margin: 0
        }),
        indicatorSeparator: (baseStyles, { hasValue, selectProps: { isClearable } }) => ({
            ...baseStyles,
            margin: '8px 4px',
            ...(!isClearable || !hasValue ? { display: 'none' } : {})
        }),
        valueContainer: (baseStyles, { isMulti }) => ({
            ...baseStyles,
            ...textStyles,
            padding: 0,
            ...(isMulti ? { flexWrap: 'nowrap' } : {})
        }),
        clearIndicator: (baseStyles) => ({
            ...baseStyles,
            padding: 0,
            color: theme.colors.gray700,
            ':hover': {
                color: hovering.color,
                transition: `color ${hovering.transitionMs}`
            }
        }),
        dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            padding: 0,
            transition: 'none',
            svg: {
                display: 'block',
                width: '20px',
                height: '20px',
                color: theme.colors.gray700,
                transition: `color ${hovering.transitionMs}`
            },
            ':hover svg': {
                color: hovering.color
            }
        }),
        singleValue: (baseStyles) => ({
            ...baseStyles,
            ...textStyles,
            margin: 0
        }),
        multiValue: (baseStyles) => ({
            ...baseStyles,
            margin: 0,
            backgroundColor: 'none',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }),
        multiValueRemove: () => ({
            display: 'none'
        }),
        multiValueLabel: (baseStyles) => ({
            ...baseStyles,
            ...textStyles,
            padding: 0,
            paddingLeft: 0
        }),
        menu: (baseStyles) => ({
            ...baseStyles,
            zIndex: 5,
            boxShadow: 'none',
            background: 'white',
            border: `1px solid ${theme.colors.gray200}`
        }),
        menuList: (baseStyles) => ({
            ...baseStyles,
            padding: '4px 0',
            overflowX: 'hidden'
        }),
        option: (baseStyles, { isMulti, isDisabled }) => ({
            ...baseStyles,
            ...textStyles,
            lineHeight: '18px',
            padding: '6px 8px 6px 12px',
            overflowWrap: 'anywhere',
            background: 'none',
            ':active': {
                background: theme.colors.gray50
            },
            ':hover': {
                background: theme.colors.gray50
            },
            ...(isDisabled ? {} : { cursor: 'pointer' }),
            ...(isMulti
                ? {
                    display: 'flex',
                    columnGap: '5px',
                    alignItems: 'center',
                    flexWrap: 'nowrap'
                }
                : {})
        })
    }, overrides);
}
export const Label = styled.span `
  display: block;

  font-size: 14px;
  font-weight: 800;

  line-height: 20px;

  color: ${({ theme }) => theme.colors.gray300};
`;
