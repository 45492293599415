import { RequestStatus } from '@campus/commons';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  padding: 10px 10px 9px 9px;
`;
export const StyledWrapper = styled.div `
  display: grid;
  column-gap: 5px;
  grid-template-columns: 1fr auto;

  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
`;
export const StyledTextArea = styled.textarea `
  margin-top: auto;
  padding: 8px 0 8px 12px;
  background-color: white;

  color: ${(props) => props.theme.colors.gray700};

  height: 36px;
  min-height: 32px;
  max-height: 200px;

  overflow-y: auto;
  overflow-x: hidden;

  border: none;
  border-radius: inherit;

  &,
  &::placeholder {
    font-style: normal;
    font-size: 12px;
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.gray4};
  }

  &:focus {
    outline: none;
  }
`;
export const StyledButton = styled.button `
  align-self: center;

  margin-right: 4px;

  display: inline-flex;
  height: 24px;
  min-width: 54px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;

  flex-shrink: 0;

  border-radius: 3px;
  background: ${(props) => props.$status === RequestStatus.Failed
    ? props.theme.colors.error
    : props.theme.colors.blue1};
  border: 1px solid
    ${(props) => props.$status === RequestStatus.Failed
    ? props.theme.colors.error
    : props.theme.colors.blue1};
  color: ${(props) => props.theme.colors.white};
  font-size: 12px;

  &:not(:disabled):hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.6;
  }
`;
