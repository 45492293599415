import React from 'react';
import { RequestStatus } from '@campus/commons';
import { useLiteraryWorkPageContext } from '../../context';
import { ReadOnlyVideoList, EditableVideoList } from './components';
import { useFetchVideoAnalysis } from './hooks';
import { Message, MessagesContainer, Container } from './styles';
const statusAndMessages = {
    [RequestStatus.Loading]: 'Carregando itens da obra...',
    [RequestStatus.Failed]: 'Falha ao carregar itens da obra'
};
const VideoAnalysisTab = () => {
    const { isReadOnlyOnGeneral, literaryWorkId } = useLiteraryWorkPageContext();
    const { data, requestStatus } = useFetchVideoAnalysis({ literaryWorkId });
    if (requestStatus in statusAndMessages) {
        return (React.createElement(MessagesContainer, null,
            React.createElement(Message, null, statusAndMessages[requestStatus])));
    }
    return (React.createElement(Container, null, isReadOnlyOnGeneral ? (React.createElement(ReadOnlyVideoList, { videoAnalisysItems: data })) : (React.createElement(EditableVideoList, { videoAnalisysItems: data }))));
};
export default VideoAnalysisTab;
