import { updateDidaticSequence } from '../../../../../../../../../../../../../services/didaticSequence';
export function getSequenceIndex(sequences, sequenceId) {
    return sequences.findIndex((item) => {
        return item?.id === sequenceId;
    });
}
export async function updateSequenceIndex(sequence, newIndex) {
    await updateDidaticSequence({
        newIndex,
        id: sequence.id,
        isDeleted: sequence.isDeleted,
        description: sequence.description,
        impactTheMDC: sequence.impactTheMDC,
        evaluationTypeId: sequence.evaluationType?.id
    });
}
export function updateSequencesIndices(updated, oldValue) {
    return updated
        .map((item, index) => [item, index])
        .filter((_, index) => {
        const foundInOldValue = oldValue.at(index);
        return !foundInOldValue || updated.at(index)?.id !== foundInOldValue.id;
    })
        .map(([item, index]) => updateSequenceIndex(item, index));
}
