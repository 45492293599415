class PageModel {
    totalCount;
    itemsCount;
    hasNextPage;
    static fromJson(data) {
        const pageModel = new PageModel();
        pageModel.totalCount = data?.totalCount;
        pageModel.hasNextPage = data?.totalCount > data?.itemsCount;
        pageModel.itemsCount = data?.itemsCount;
        return pageModel;
    }
}
export default PageModel;
