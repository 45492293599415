import { useCallback, useEffect, useState } from 'react';
import { getAllStages } from '../../../services';
export const useStage = () => {
    const [stage, setStage] = useState();
    const [stages, setStages] = useState([]);
    const [loading, setLoading] = useState(true);
    const changeStage = (stage) => {
        setStage(stage);
        sessionStorage.setItem('@campus:stage', stage.id);
    };
    const getStages = useCallback(async () => {
        setLoading(true);
        const result = await getAllStages();
        const oldStageId = sessionStorage.getItem('@campus:stage');
        const data = result.data;
        if (data) {
            setStages(data.items);
            const oldStage = data.items.find((s) => s.id === oldStageId);
            const stage = oldStage ?? data.items[0];
            changeStage(stage);
        }
        setLoading(false);
    }, []);
    useEffect(() => {
        getStages();
    }, [getStages]);
    return {
        stage,
        stages,
        loading,
        changeStage
    };
};
