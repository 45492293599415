import MessageContent from './MessageContent';
import User from './User';
class Message {
    title;
    creationDate;
    creationUser;
    content;
    static fromJson(data) {
        const message = new Message();
        message.title = data.Title;
        message.creationDate = data.CreationDate;
        message.creationUser = User.fromJson(data.CreationUser);
        message.content = MessageContent.fromJson(data.Content);
        return message;
    }
}
export default Message;
