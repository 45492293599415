import { Api } from '@campus/auth';
import { Response } from '@campus/commons';
import { endpointBasePath } from './common';
const apiEndpoints = {
    isDone: `${endpointBasePath}/upload`
};
export async function isUploadComplete(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = apiEndpoints.isDone;
        const response = await axiosInstance.get(endpoint, {
            params: {
                url: params.uploadUrl
            }
        });
        return Response.fromData(response.data, response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
