import { LiteraryWorkQuestionKind } from '../../../../../../models';
import { kindsAndInfos } from '../../commons/types';
export function allowChangingQuestionKind(params) {
    if (!params.questionSummaries)
        return false;
    const questionKind = 'questionKind' in params
        ? params.questionKind
        : params.questionSummaries.find((s) => s.id === params.questionId)?.kind;
    if (questionKind === undefined)
        return false;
    const otherKind = questionKind === LiteraryWorkQuestionKind.Objective
        ? LiteraryWorkQuestionKind.Discursive
        : LiteraryWorkQuestionKind.Objective;
    const numberOfQuestionsOfTheOtherKind = params.questionSummaries
        .filter((s) => s.kind === otherKind)
        .reduce((sum) => sum + 1, 0);
    const maximumQuantity = kindsAndInfos[otherKind].maximumQuantity;
    return numberOfQuestionsOfTheOtherKind < maximumQuantity;
}
