import { useCallback, useEffect } from 'react';
import { useFetch } from '@campus/commons';
import { getLiteraryWorkById } from '../../../services';
export function useFetchLiteraryWork({ literaryWorkId }) {
    const { data, requestStatus, fetchStarted, handleFailure, handleSuccess } = useFetch({
        initialData: null
    });
    const fetchLiteraryWork = useCallback(async () => {
        fetchStarted({ resetData: true });
        try {
            const response = await getLiteraryWorkById({
                id: literaryWorkId
            });
            handleSuccess(response?.data);
        }
        catch (error) {
            handleFailure(error);
        }
    }, [fetchStarted, handleFailure, handleSuccess, literaryWorkId]);
    useEffect(() => {
        fetchLiteraryWork();
    }, [fetchLiteraryWork]);
    return { data, requestStatus };
}
