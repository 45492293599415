import styled from 'styled-components';
import { StyledColumnWrapper } from '../common/styles';
export const StyledWrapper = styled(StyledColumnWrapper) `
  padding: 12px 16px;

  display: flex;
  justify-content: center;

  border-radius: inherit;

  background-color: ${(props) => props.$backgroundColor ?? 'white'};
`;
export const StyledDisciplineName = styled.span `
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  align-self: start;

  line-height: 100%;
  writing-mode: vertical-rl;
  rotate: -180deg;
  word-break: break-all;

  color: ${(props) => props.$color ?? 'black'};
`;
