import { useMemo } from 'react';
import { useClassDisciplines } from '../../../providers';
import { EvaluationTypeMode, Variant } from '../../../models';
export const useStudentPerformace = () => {
    const { classDisciplines, loadingClassDisciplines } = useClassDisciplines(Variant.STUDENT);
    const evaluations = useMemo(() => {
        if (classDisciplines) {
            const first = classDisciplines[0];
            return (first?.evaluations?.map((e) => ({
                title: e.name,
                color: e.color,
                description: e.evaluationDescription,
                isPerformace: e.evaluationTypeMode == EvaluationTypeMode.PERFORMANCE
            })) ?? []);
        }
        return [];
    }, [classDisciplines]);
    return {
        loading: loadingClassDisciplines,
        discipline: classDisciplines,
        evaluations
    };
};
