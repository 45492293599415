import Discipline from './Discipline';
class Contents {
    id;
    contentType;
    endTime;
    finished;
    maxDuration;
    name;
    startTime;
    totalWeight;
    discipline;
    evaluationTypeName;
    evaluationTypeColor;
    state;
    stateText;
    isNew;
    static fromJson(data) {
        const contents = new Contents();
        contents.id = data.Id;
        contents.contentType = data.ContentType;
        contents.endTime = data.EndTime;
        contents.finished = data.Finished;
        contents.maxDuration = data.MaxDuration;
        contents.name = data.Name;
        contents.startTime = data.StartTime;
        contents.totalWeight = data.TotalWeight;
        contents.evaluationTypeColor = data.EvaluationTypeColor;
        contents.evaluationTypeName = data.EvaluationTypeName;
        contents.state = data.State;
        contents.stateText = data.StateText;
        contents.isNew = data.IsNew;
        contents.discipline = Discipline.fromJson(data.Discipline);
        return contents;
    }
}
export default Contents;
