import React from 'react';
import { ArrowLeft, CarouselItemAnimation, Container, ContentIconButton, DivSpace } from './styles';
import useCarousel from './hook/useCarousel';
import CampusIcon from '../CampusIcon';
const Carousel = ({ className, items, itemsPerPage, isMobile }) => {
    const { goBack, goNext, hasNext, hasPrevious, itemsPagination } = useCarousel(items, isMobile ? 1 : itemsPerPage);
    const renderItems = () => {
        return itemsPagination.map((item) => (React.createElement(CarouselItemAnimation, { key: `item-${item.id}`, className: "carousel__item" }, item.component)));
    };
    return (React.createElement(Container, { "$isMobile": isMobile, className: className },
        hasPrevious && (React.createElement(ContentIconButton, { onClick: goBack, disabled: !hasPrevious, className: "carousel__go-back" },
            React.createElement(ArrowLeft, { name: "arrowRight", color: "#333333" }))),
        !hasPrevious && React.createElement(DivSpace, { className: "carousel__empty-space" }),
        renderItems(),
        hasNext && (React.createElement(ContentIconButton, { onClick: goNext, className: "carousel__go-next" },
            React.createElement(CampusIcon, { name: "arrowRight", color: "#333333" })))));
};
export default Carousel;
