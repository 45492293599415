import styled from 'styled-components';
import { Placeholder } from '../../../commons/components';
export const Container = styled.div `
  grid-column: content;

  padding-bottom: 20px;
`;
export const PlaceholderContainer = styled(Placeholder.Container) `
  grid-column: content;

  min-height: 100px;
`;
export const PlaceholderText = styled(Placeholder.Text) ``;
