import React from 'react';
import { Header, Step, Title, StyleProgressBar } from './styles';
const StepProgress = ({ title, color, current, total, showStep = true }) => {
    const hasContentCount = current !== null;
    return (React.createElement("div", null,
        React.createElement(Header, null,
            React.createElement(Title, null, title),
            React.createElement(Step, null,
                hasContentCount && showStep ? `${current}/ ` : '',
                total)),
        showStep && (React.createElement(StyleProgressBar, { "$color": color, now: (current / total) * 100 }))));
};
export default StepProgress;
