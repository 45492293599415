import React, { useState, useEffect } from 'react';
import { Outlet, Route } from 'react-router-dom';
import routes, { basePath } from './pages/routes';
import { AccessDenied } from './pages/AccessDenied';
import LiteraryWorks from './pages/LiteraryWorks';
import LiteraryWork from './pages/LiteraryWork';
const WithPermissionCheck = ({ children, pages, userHasManagementPermission }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasPermission, setHasPermission] = useState(false);
    useEffect(() => {
        const fetchPermission = async () => {
            try {
                setIsLoading(true);
                const response = await userHasManagementPermission();
                setHasPermission(response.data ?? false);
            }
            catch {
                setHasPermission(false);
            }
            finally {
                setIsLoading(false);
            }
        };
        fetchPermission();
    }, [userHasManagementPermission]);
    if (isLoading)
        return React.createElement("p", null, "Carregando...");
    return hasPermission ? (React.createElement(React.Fragment, null, children)) : (React.createElement(AccessDenied, { title: pages.title ?? 'Acesso Negado', message: "Voc\u00EA n\u00E3o tem permiss\u00E3o para gerenciar as obras liter\u00E1rias." }));
};
export function createVestibularRoutes(options) {
    return (React.createElement(Route, { path: basePath, ...(options.userHasManagementPermission
            ? {
                element: (React.createElement(WithPermissionCheck, { ...options },
                    React.createElement(Outlet, null)))
            }
            : {}) },
        React.createElement(Route, { index: true, element: React.createElement(LiteraryWorks, { ...options.pages, ...options.pages.literaryWorks }) }),
        React.createElement(Route, { path: routes.literaryWork, element: React.createElement(LiteraryWork, { ...options.pages, ...options.pages.literaryWork }) })));
}
