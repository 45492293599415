import styled from 'styled-components';
import { ButtonAddContent, ContentItemEditableList, ContentItemReadOnlyList } from '@campus/content-items';
export const AddItemButton = styled(ButtonAddContent) ``;
const BaseContainer = styled.div `
  display: flex;
  flex-direction: column;
`;
export const WithItemsContainer = styled(BaseContainer) `
  padding-top: 40px;
  row-gap: 18px;
`;
export const WithoutItemsContainer = styled(BaseContainer) `
  padding-top: 24px;
  row-gap: 14px;
`;
export const Title = styled.h2 `
  margin-bottom: 0;

  font-size: 30px;
  font-weight: 700;

  line-height: 113.2%;

  color: ${(props) => props.theme.colors.gray2};
`;
const commonListStyles = `
  margin: 0;
  padding: 0;
  max-width: revert;
`;
const ckEditorRelatedStyles = `
  .ck.ck-editor__editable_inline {
    padding: 0;
  }

  .ck.ck-editor__editable_inline > :first-child {
    margin-top: 0;
  }
`;
export const ReadOnlyList = styled(ContentItemReadOnlyList) `
  ${commonListStyles}
  ${ckEditorRelatedStyles}
`;
export const EditableList = styled(ContentItemEditableList) `
  ${commonListStyles}
  ${ckEditorRelatedStyles}
`;
export const Message = styled.p `
  margin-bottom: 0;

  font-size: 16px;
  font-weight: 500;

  line-height: 24px;

  color: ${(props) => props.theme.colors.gray4};
`;
