import React, { forwardRef } from 'react';
import { useDialog } from '../../../../../commons/hooks';
import { HamburgerIcon } from '../../../../../commons/styles';
import { Container, Dialog } from './styles';
const QuestionItemMenu = forwardRef(({ onEditOptionClick, onDeleteOptionClick }, ref) => {
    const { showDialog, setShowDialog } = useDialog(ref);
    function onClick(event) {
        event.stopPropagation();
        setShowDialog((currentValue) => !currentValue);
    }
    function onKeyDown(event) {
        if (event.altKey)
            return;
        if (event.ctrlKey)
            return;
        if (event.key === 'Tab') {
            setShowDialog(false);
        }
        else if (event.key === 'Enter' || event.key === ' ') {
            event.stopPropagation();
            event.preventDefault();
            setShowDialog((currentValue) => !currentValue);
        }
    }
    return (React.createElement(Container, { onClick: onClick, onKeyDown: onKeyDown },
        React.createElement(HamburgerIcon, null),
        showDialog ? (React.createElement(Dialog, { onEditOptionClick: onEditOptionClick, onDeleteOptionClick: onDeleteOptionClick })) : null));
});
QuestionItemMenu.displayName = 'QuestionItemMenu';
export default QuestionItemMenu;
