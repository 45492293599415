import { DataPoint } from './dataPoint';
export function createChartData(models) {
    if (!models?.length)
        return [];
    const dataPoints = models.map((m) => new DataPoint(m));
    // bubbles will be grouped in pairs and, in each
    // iteration, a pair is created if possible. The
    // created pair is just a new bubble, that is placed
    // in the middle of the other two bubbles. These
    // bubbles that generated the new bubble are them
    // removed from the list. The new bubble will then
    // be possibly grouped with other bubbles that intersect
    // it, and so on
    let aNewGroupingWasMade = false;
    do {
        aNewGroupingWasMade = false;
        for (let i = 0; i < dataPoints.length; ++i) {
            for (let j = 0; j < dataPoints.length; ++j) {
                if (i === j)
                    continue;
                if (dataPoints[i].canGroupWith(dataPoints[j])) {
                    dataPoints[i].groupWith(dataPoints[j]);
                    dataPoints.splice(j, 1);
                    aNewGroupingWasMade = true;
                    break;
                }
            }
            if (aNewGroupingWasMade)
                break;
        }
    } while (aNewGroupingWasMade);
    return dataPoints;
}
