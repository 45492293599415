// this hook will overwrite the properties defined
// on :root styles, like --ck-z-default
export function useOverwriteRootCustomProperties() {
    function overwritePropertyValue(propertyName, newValue) {
        const oldValue = document.documentElement.style.getPropertyValue(propertyName);
        document.documentElement.style.setProperty(propertyName, newValue);
        return () => {
            document.documentElement.style.setProperty(propertyName, oldValue);
        };
    }
    return { overwritePropertyValue };
}
