import { generateGroupUpdater } from '../../helpers';
import { addItem, isIndexValid, swapItemsInPlace, reorderItemsInPlace, getNewItemIndexIfItMoves } from '../../../../../../../../../../../utils/arrays';
import { getSequenceIndex, updateSequenceIndex, updateSequencesIndices } from './helpers';
export function createSequenceOperationsSlice(params) {
    const updateGroup = generateGroupUpdater(params.updateGroupOnMainTable);
    return (set) => ({
        add(sequence, atIndex) {
            set((store) => {
                const oldGroup = store._groupFromNonFilteredContent;
                const oldSequence = oldGroup.didacticSequence ?? [];
                let didacticSequence = [...oldSequence];
                didacticSequence = addItem(sequence, didacticSequence, atIndex);
                return updateGroup(store, { didacticSequence });
            });
        },
        remove(sequenceId) {
            set((store) => {
                const oldGroup = store._groupFromNonFilteredContent;
                const oldSequence = oldGroup.didacticSequence ?? [];
                let didacticSequence = [...oldSequence];
                didacticSequence = didacticSequence.filter((s) => s.id !== sequenceId);
                return updateGroup(store, { didacticSequence });
            });
        },
        move(sequenceId, direction) {
            set((store) => {
                const oldGroup = store._groupFromNonFilteredContent;
                const oldSequence = oldGroup.didacticSequence ?? [];
                const sequenceIndex = getSequenceIndex(oldSequence, sequenceId);
                if (sequenceIndex === -1)
                    return store;
                const otherSequenceIndex = getNewItemIndexIfItMoves(oldSequence, sequenceIndex, direction);
                const didacticSequence = [...oldSequence];
                updateSequenceIndex(didacticSequence[sequenceIndex], otherSequenceIndex);
                updateSequenceIndex(didacticSequence[otherSequenceIndex], sequenceIndex);
                swapItemsInPlace(didacticSequence, sequenceIndex, otherSequenceIndex);
                return updateGroup(store, { didacticSequence });
            });
        },
        update(updatedSequence, index) {
            set((store) => {
                const oldGroup = store._groupFromNonFilteredContent;
                const oldSequence = oldGroup.didacticSequence ?? [];
                index ??= getSequenceIndex(oldSequence, updatedSequence.id);
                if (!isIndexValid(index, oldSequence))
                    return store;
                const didacticSequence = [...oldSequence];
                didacticSequence[index] = updatedSequence;
                return updateGroup(store, { didacticSequence });
            });
        },
        reorder(firstIndex, secondIndex) {
            set((store) => {
                const oldGroup = store._groupFromNonFilteredContent;
                const oldSequence = oldGroup.didacticSequence ?? [];
                if (!isIndexValid(firstIndex, oldSequence))
                    return store;
                if (!isIndexValid(secondIndex, oldSequence))
                    return store;
                const didacticSequence = [...oldSequence];
                reorderItemsInPlace(didacticSequence, firstIndex, secondIndex);
                updateSequencesIndices(didacticSequence, oldSequence);
                return updateGroup(store, { didacticSequence });
            });
        }
    });
}
