import React from 'react';
import { getColumnDefinition } from '../../../../../../helpers/columns';
import { StyledLabel, StyledWrapper, StyledContentWrapper } from './styles';
const Column = (props) => {
    const columnDefinition = getColumnDefinition({
        allColumns: props.allColumns,
        columnInstance: props.columnInfos
    });
    const headerProps = props.columnInfos.getHeaderProps();
    headerProps.style.minWidth = headerProps.style.width;
    if (props.asTh) {
        headerProps.style.display = 'table-cell';
        headerProps.style.padding = 0;
    }
    return (React.createElement(StyledWrapper, { ...headerProps, as: props.asTh ? 'th' : 'div' },
        React.createElement(StyledContentWrapper, { title: props?.title ?? null, "$removeRightBorder": props.removeRightBorder, "$borderOn": columnDefinition?.borderOn, "$centered": columnDefinition?.centered ?? false },
            React.createElement(StyledLabel, null, props.columnInfos.render('Header')))));
};
export default Column;
