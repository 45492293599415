import Stage from './Stage';
import AreaSkill from './AreaSkill';
import Discipline from './Discipline';
import DidaticSequence from './DidaticSequence';
import PedagogicalPlanningStatus from './PedagogicalPlanningStatus';
import PedagogicalPlanningObject from './PedagogicalPlanningObject';
import PedagogicalPlanningObjective from './PedagogicalPlanningObjective';
import Comment from './Comment';
export default class PedagogicalPlanning {
    id;
    order;
    associatedGroup;
    gradeId;
    segmentId;
    teacherId;
    stage;
    areaSkill;
    discipline;
    status;
    didaticSequences;
    objects;
    objectives;
    comments;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new PedagogicalPlanning();
        instance.id = json.Id;
        instance.order = json.Order;
        instance.associatedGroup = json.Group;
        instance.gradeId = json.GradeId;
        instance.segmentId = json.SegmentId;
        instance.teacherId = json.TeacherId;
        instance.stage = Stage.fromJson(json.Semester);
        instance.areaSkill = AreaSkill.fromJson(json.AreaSkill);
        instance.discipline = Discipline.fromJson(json.Discipline);
        instance.status = PedagogicalPlanningStatus.fromJson(json.PedagogicalPlanningStatus);
        instance.didaticSequences =
            json.DidacticSequences?.map((item) => DidaticSequence.fromJson(item)) ?? [];
        instance.objects =
            json.PedagogicalPlanningObjects?.map((item) => PedagogicalPlanningObject.fromJson(item)) ?? [];
        instance.objectives =
            json.PedagogicalPlanningObjectives?.map((item) => PedagogicalPlanningObjective.fromJson(item)) ?? [];
        instance.comments = Array.isArray(json.Comments)
            ? json.Comments.map((item) => Comment.fromJson(item))
            : [];
        return instance;
    }
}
