import styled from 'styled-components';
export const MessagesContainer = styled.div `
  grid-column: content;

  min-height: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Message = styled.p `
  margin-bottom: 0;

  font-size: 20px;
  font-weight: 600;

  text-align: center;

  line-height: 90%;

  color: ${(props) => props.theme.colors.gray700};
`;
export const Container = styled.div `
  grid-column: full-width;

  display: grid;
  grid-template-columns: inherit;
`;
