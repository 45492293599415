import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
import { Placeholder } from '../commons/components';
export const HamburgerIcon = styled(CampusIcon).attrs({
    name: 'hamburger'
}) `
  display: block;

  color: ${(props) => props.theme.colors.gray1};
`;
export const ContentContainer = styled.div `
  grid-column: content;

  padding-bottom: 1rem;
`;
export const PlaceholderContainer = styled(Placeholder.Container) `
  margin-top: 71px;
  min-height: 200px;
  flex-direction: column;
`;
export const NotAllowedContainer = styled(PlaceholderContainer) `
  margin-top: 66px;
  min-height: 0px;
`;
export const PadlockIcon = styled(CampusIcon).attrs({
    name: 'closedPadlock'
}) `
  display: block;

  fill: none;

  stroke-width: 2px;

  color: ${(props) => props.theme.colors.CSA.red};

  margin-bottom: 8px;
`;
export const PlaceholderText = styled(Placeholder.Text) ``;
