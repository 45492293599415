import React from 'react';
import { Card, CardHeader } from '../';
import { CardStyled, ContainerLoading, IconLoadingContent, IconGearLeft, IconGearRight, TextLoading } from './styles';
const VideoProcessing = ({ small }) => (React.createElement(Card, { overflowHidden: true },
    React.createElement(CardStyled, { "$small": small },
        !small && React.createElement(CardHeader, { title: "Upload de V\u00EDdeo" }),
        React.createElement(ContainerLoading, { "$small": !!small },
            React.createElement(IconLoadingContent, null,
                React.createElement(IconGearLeft, null),
                React.createElement(IconGearRight, null)),
            React.createElement(TextLoading, null,
                "O v\u00EDdeo est\u00E1 em ",
                React.createElement("strong", null, "processamento"),
                " e em breve estar\u00E1 publicado!")))));
export default VideoProcessing;
