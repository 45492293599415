import styled from 'styled-components';
export const Container = styled.div `
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 14px;
`;
export const TableAndNoContent = styled.div `
  width: 100%;

  overflow: auto;
`;
