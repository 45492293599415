import { useCallback, useEffect } from 'react';
import { useFetch } from '@campus/commons';
import { getPresentationItems } from '../../../../../services';
export function useFetchPresentationItems({ literaryWorkId }) {
    const { data, requestStatus, fetchStarted, handleFailure, handleSuccess } = useFetch({
        initialData: []
    });
    const fetchItems = useCallback(async () => {
        fetchStarted({ resetData: true });
        try {
            const response = await getPresentationItems({
                literaryWorkId
            });
            handleSuccess(response?.data);
        }
        catch (error) {
            handleFailure(error);
        }
    }, [fetchStarted, handleFailure, handleSuccess, literaryWorkId]);
    useEffect(() => {
        fetchItems();
    }, [fetchItems]);
    return { data, requestStatus };
}
