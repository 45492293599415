import ClassificationTypeItem from './ClassificationTypeInsightsItem';
class ClassificationTypeInsights {
    items;
    strongest;
    weakest;
    static fromJson(data) {
        const classificationType = new ClassificationTypeInsights();
        classificationType.items = data?.Items?.map(item => ClassificationTypeItem.fromJson(item));
        classificationType.strongest = data?.Strongest?.map(item => ClassificationTypeItem.fromJson(item));
        classificationType.weakest = data?.Weakest?.map(item => ClassificationTypeItem.fromJson(item));
        return classificationType;
    }
}
export default ClassificationTypeInsights;
