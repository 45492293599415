import { Api } from '@campus/auth';
import { Response } from '../';
import { StudentRateList, StudentTimeList } from '../models';
export const studentTimeStats = async (classId, studentId) => {
    try {
        const response = await Api.INSTANCE.getApi().get(`/time/${studentId}`);
        return Response.fromData(StudentTimeList.fromJson(response.data), response.status);
    }
    catch (e) {
        return Response.error(e);
    }
};
export const studentRateStats = async (classId, studentId) => {
    try {
        const response = await Api.INSTANCE.getApi().get(`/student/${studentId}/rate`);
        return Response.fromData(StudentRateList.fromJson(response.data), response.status);
    }
    catch (e) {
        return Response.error(e);
    }
};
