import { Api } from '@campus/auth';
import { PaginationModel, Response } from '@campus/commons';
import { endpointBasePath } from './common';
import { LiteraryWorkVideoItem, LiteraryWorkVideoLink } from '../models';
const endpointResourceName = 'video';
const apiEndpoints = {
    get: {
        all({ literaryWorkId, maxResultCount = 50, skipCount = 0 }) {
            return `${endpointBasePath}/${literaryWorkId}/${endpointResourceName}?maxResultCount=${maxResultCount}&skipCount=${skipCount}`;
        },
        byId({ literaryWorkId, videoId }) {
            return `${endpointBasePath}/${literaryWorkId}/${endpointResourceName}/${videoId}`;
        }
    },
    add({ literaryWorkId }) {
        return `${endpointBasePath}/${literaryWorkId}/${endpointResourceName}`;
    },
    createLink({ literaryWorkId }) {
        return `${endpointBasePath}/${literaryWorkId}/upload/${endpointResourceName}`;
    },
    update({ literaryWorkId, item }) {
        return `${endpointBasePath}/${literaryWorkId}/${endpointResourceName}/${item.id}`;
    },
    delete({ literaryWorkId, videoId }) {
        return `${endpointBasePath}/${literaryWorkId}/${endpointResourceName}/${videoId}`;
    },
    updateProcessing(literaryWorkId) {
        return `${endpointBasePath}/${literaryWorkId}/${endpointResourceName}/updateprocessing`;
    }
};
export async function getByIdVideoAnalysisItems(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = apiEndpoints.get.byId(params);
        const response = await axiosInstance.get(endpoint);
        const item = LiteraryWorkVideoItem.fromJson(response.data);
        return Response.fromData(item, response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
export async function getAllVideoAnalysisItems(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const skipCount = params.skipCount ?? 0;
        const endpoint = apiEndpoints.get.all(params);
        const response = await axiosInstance.get(endpoint);
        const amount = response.data.TotalCount;
        const itemsCount = response.data.ItemsCount;
        const fetched = (skipCount ?? 0) + itemsCount;
        const hasNextPage = fetched < amount;
        const items = response.data.Items?.map((item) => {
            return LiteraryWorkVideoItem.fromJson(item);
        }) ?? [];
        const paginated = new PaginationModel(items, amount, itemsCount, hasNextPage);
        return Response.fromData(paginated, response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
export async function addVideoAnalysisItems(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = apiEndpoints.add(params);
        const response = await axiosInstance.post(endpoint, {
            Url: params.url,
            Title: params.title
        });
        const item = LiteraryWorkVideoItem.fromJson(response.data);
        return Response.fromData(item, response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
export async function deleteVideoAnalysisItems(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = apiEndpoints.delete(params);
        const response = await axiosInstance.delete(endpoint);
        return Response.fromData(true, response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
export async function updateVideoAnalysisItems(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = apiEndpoints.update(params);
        const response = await axiosInstance.patch(endpoint, {
            Url: params.item.url,
            Title: params.item.title,
            Order: params.item.order
        });
        const item = LiteraryWorkVideoItem.fromJson(response.data);
        return Response.fromData(item, response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
export async function createLinkVideoAnalysisItems(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = apiEndpoints.createLink(params);
        const response = await axiosInstance.put(endpoint, {
            FileName: params.fileName,
            FileSize: params.fileSize,
            Provider: params.provider
        });
        const item = LiteraryWorkVideoLink.fromJson(response.data);
        return Response.fromData(item, response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
export async function updateVideoProcessing(literaryWorkId, videosIds) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = apiEndpoints.updateProcessing(literaryWorkId);
        const response = await axiosInstance.post(endpoint, {
            videosIds
        });
        return Response.fromData(response.data, response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
