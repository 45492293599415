import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
export const Container = styled.div `
  z-index: 10;

  padding: 5px;

  max-height: 150px;

  overflow: hidden;

  display: ${(props) => (props.$show ? 'flex' : 'none')};
  flex-direction: column;

  border: 1px solid ${(props) => props.theme.colors.blue1};
  border-radius: 4px;

  background-color: ${(props) => props.theme.colors.blue1}1A;
`;
export const ScrollableArea = styled.div `
  max-height: 100%;

  overflow: auto;
`;
export const Item = styled.div `
  padding: 2px;

  cursor: pointer;

  min-height: 24px;

  border-radius: 2px;

  display: flex;
  gap: 5px;
  align-items: center;

  &:hover {
    background-color: ${(props) => props.theme.colors.blue1}4D;
  }

  &:hover span {
    color: ${(props) => props.theme.colors.blue1};
  }
`;
export const Avatar = styled.div `
  background-image: ${(props) => `url(${props.$img})`};

  width: 20px;
  height: 20px;

  border-radius: 50%;

  background-size: cover;
  background-position: top center;
`;
export const Name = styled.span `
  color: ${(props) => props.theme.colors.gray700};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;
export const IconContainer = styled.div `
  display: contents;

  > div {
    width: 20px;
    height: 20px;

    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.blue1};

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const AllUsersIcon = styled(CampusIcon).attrs({
    name: 'allUsers'
}) `
  display: block;

  & path {
    stroke: ${(props) => props.theme.colors.white};
    fill: none;
  }
`;
export const UserIcon = styled(CampusIcon).attrs({
    name: 'avatar'
}) `
  display: block;

  & path {
    fill: ${(props) => props.theme.colors.white};
  }
`;
