import React, { useState } from 'react';
import { RequestStatus } from '@campus/commons';
import { ConfirmationModal } from '@campus/components';
import { deleteQuestion } from '../../../../../../services';
import { useLiteraryWorkPageContext } from '../../../../context';
import { times } from '../../../../commons/times';
import { useQuestionsTabContext } from '../../context';
import { useQuestionActionFeedbacks } from '../../hooks';
import { Content, Text } from './styles';
const statusAndButtonTexts = {
    [RequestStatus.NotStarted]: 'Apagar',
    [RequestStatus.Loading]: 'Apagando questão...',
    [RequestStatus.Failed]: 'Falha ao apagar questão'
};
const ConfirmQuestionDeletionModal = ({ questionId, hideModal }) => {
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    const { literaryWorkId } = useLiteraryWorkPageContext();
    const { events } = useQuestionsTabContext();
    const feedbacks = useQuestionActionFeedbacks();
    async function onConfirmDeletionButtonClick() {
        if (requestStatus !== RequestStatus.NotStarted)
            return;
        setRequestStatus(RequestStatus.Loading);
        try {
            await deleteQuestion({ literaryWorkId, questionId });
            events.aQuestionWasDeleted.emit({ questionId });
            feedbacks.questionWasDeleted(questionId);
            hideModal();
        }
        catch {
            setRequestStatus(RequestStatus.Failed);
            setTimeout(() => {
                setRequestStatus(RequestStatus.NotStarted);
            }, times.toResetRequestStatusAfterFailure);
        }
    }
    const confirmActionButtonText = statusAndButtonTexts[requestStatus] ??
        statusAndButtonTexts[RequestStatus.NotStarted];
    return (React.createElement(ConfirmationModal, { show: true, doNotHideModalOnSubmit: true, title: "Apagar Quest\u00E3o", labelCancel: "Cancelar", labelConfirm: confirmActionButtonText, disableConfirmButton: requestStatus !== RequestStatus.NotStarted, description: React.createElement(Content, null,
            React.createElement(Text, null,
                "Deseja realmente ",
                React.createElement("b", null, "apagar"),
                " a quest\u00E3o?")), onHide: hideModal, onSubmit: onConfirmDeletionButtonClick }));
};
export default ConfirmQuestionDeletionModal;
