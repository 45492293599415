import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
export const dateToString = (date) => {
    if (date === null) {
        return null;
    }
    if (date && isValid(date)) {
        return format(date, `yyyy-MM-dd\'T\'HH:mm`);
    }
    return undefined;
};
export const formatDate = (date) => {
    if (date === null) {
        return null;
    }
    if (date && isValid(date)) {
        return format(date, `dd/MM/yyyy HH\'h\'mm`);
    }
    return undefined;
};
