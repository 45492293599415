import React from 'react';
import { Container, ItemContainer, ItemLabel } from './styles';
function withStopPropagation(eventHandler) {
    return (event) => {
        event.stopPropagation();
        eventHandler?.(event);
    };
}
const OptionListDialog = ({ items, className }) => {
    return (React.createElement(Container, { className: className, onClick: withStopPropagation() }, items.map((item) => (React.createElement(ItemContainer, { key: item.label, onClick: withStopPropagation(item.onClick) },
        React.createElement(ItemLabel, null, item.label))))));
};
export default OptionListDialog;
