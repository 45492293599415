import React from 'react';
import { Column } from './styles';
import { StyledCode } from '../Percentage/styles';
export const Skill = ({ code, skill, isActive }) => {
    return (React.createElement(Column, null,
        React.createElement(StyledCode, { "$isActive": isActive },
            "(",
            code,
            ")"),
        '',
        " ",
        skill));
};
