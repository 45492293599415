import { useCallback, useEffect } from 'react';
import { useFetch } from '@campus/commons';
import { getQuestionItemData } from '../../../../../services';
export function useFetchQuestionItemData({ literaryWorkId, questionId, studentId }) {
    const { data, requestStatus, fetchStarted, handleFailure, handleSuccess } = useFetch({ initialData: null });
    const fetchData = useCallback(async () => {
        if (!questionId)
            return;
        fetchStarted({ resetData: true });
        try {
            const response = await getQuestionItemData({
                literaryWorkId,
                questionId,
                ...(studentId ? { studentId } : {})
            });
            handleSuccess(response?.data);
        }
        catch (error) {
            handleFailure(error);
        }
    }, [
        fetchStarted,
        handleFailure,
        handleSuccess,
        literaryWorkId,
        questionId,
        studentId
    ]);
    useEffect(() => {
        fetchData();
    }, [fetchData]);
    return { data, requestStatus, refetchData: fetchData };
}
