import React from 'react';
import { Skeleton } from '@campus/components';
import { DashboardCard, Description, Information } from '../../styles';
import TitleContainer from '../TitleContainer';
import { studentRateStats } from '../../../../services/dashboard';
import { useContextManager } from '../../../../providers';
import RateTime from '../Charts/RateTime';
const RhythmCard = ({ studentId }) => {
    const { classroom } = useContextManager();
    const [loadingRate, setLoadingRate] = React.useState(true);
    const [rate, setRate] = React.useState();
    React.useEffect(() => {
        const getData = async () => {
            setLoadingRate(true);
            const result = await studentRateStats(classroom.id, studentId);
            setRate(result.data);
            setLoadingRate(false);
        };
        getData();
    }, [classroom.id]);
    return (React.createElement(React.Fragment, null, loadingRate ? (React.createElement(DashboardCard, { className: "d-flex justify-content-center align-items-center p-0" },
        React.createElement(Skeleton, { height: "100%", width: "100%" }))) : (React.createElement(DashboardCard, null,
        React.createElement(TitleContainer, { title: 'ÍNDICE DE RITMO DIÁRIO', timeSeries: rate.daysCount, videoURL: process.env.REACT_APP_VIDEO_DAILY_RHYTHM }),
        React.createElement(Information, null,
            "Ritmo M\u00E9dio (\u00FAltimos ",
            rate.daysCount,
            " dias)"),
        React.createElement(RateTime, { stats: rate.stats }),
        React.createElement(Description, null, "Indica o ritmo di\u00E1rio do estudante, avaliando seu engajamento e desempenho em todos os componentes curriculares, comparando com o ritmo da turma.")))));
};
export default RhythmCard;
