import { v4 as uuidv4 } from 'uuid';
import Classroom from './Classroom';
import Student from './Student';
class ContentAudience {
    id;
    classroom;
    classId;
    studentId;
    student;
    static fromJson(data) {
        const contentAudience = new ContentAudience();
        contentAudience.id = data.Id;
        const classroom = Classroom.fromJson(data?.Class);
        contentAudience.classroom = classroom;
        contentAudience.classId = data.ClassId ?? classroom.id;
        const student = Student.fromJson(data?.Student);
        contentAudience.student = student;
        contentAudience.studentId = data.StudentId ?? student.id;
        return contentAudience;
    }
    static fromClassroom(classroom) {
        const contentAudience = new ContentAudience();
        contentAudience.id = uuidv4();
        contentAudience.classId = classroom.id;
        return contentAudience;
    }
    static fromStudent(student, classroom) {
        const contentAudience = new ContentAudience();
        contentAudience.id = uuidv4();
        contentAudience.studentId = student.id;
        contentAudience.classId = classroom.id;
        return contentAudience;
    }
    toJson() {
        return {
            ClassId: this.classId,
            StudentId: this.studentId
        };
    }
}
export default ContentAudience;
