class Teacher {
    id;
    name;
    imageUrl;
    biography;
    nameFormatted;
    static fromJson(data) {
        const teacher = new Teacher();
        teacher.id = data.Id;
        teacher.name = data.Name || data.User?.Name;
        teacher.imageUrl = data.ImageUrl;
        teacher.biography = data.Biography;
        teacher.setNameFormatted();
        return teacher;
    }
    setNameFormatted() {
        this.nameFormatted = this.name.split(/(\s).+\s/).join("");
    }
}
export default Teacher;
