import { useCallback, useEffect } from 'react';
import { useFetch } from '@campus/commons';
import { toast } from '@campus/components';
import { getQuestionSummaries } from '../../../../../services';
export function useFetchQuestionSummaries({ literaryWorkId }) {
    const { data, requestStatus, fetchStarted, handleFailure, handleSuccess, resetStatus } = useFetch({ initialData: [] });
    const fetchData = useCallback(async (onError) => {
        try {
            const response = await getQuestionSummaries({ literaryWorkId });
            handleSuccess(response?.data);
        }
        catch (error) {
            onError(error);
        }
        finally {
            resetStatus();
        }
    }, [literaryWorkId, handleSuccess, resetStatus]);
    const refetchData = useCallback(async () => {
        fetchData(() => {
            toast.error('Falha ao obter questões atualizadas');
        });
    }, [fetchData]);
    useEffect(() => {
        fetchStarted();
        fetchData(handleFailure);
    }, [fetchStarted, fetchData, handleFailure]);
    return { data, requestStatus, refetchData };
}
