import { useAuthority } from '../providers/AuthorityProvider';
const REDIRECT_TEACHER = process.env.REACT_APP_REDIRECT_TEACHER;
const REDIRECT_STUDENT = process.env.REACT_APP_REDIRECT_STUDENT;
const REDIRECT_TEACHER_STUDENT = process.env.REACT_APP_REDIRECT_TEACHER_STUDENT;
export const useRedirect = () => {
    const { currentContext } = useAuthority();
    const contextUrl = `&tenantId=${currentContext?.tenantId}&unityId=${currentContext?.unityId}&period=${currentContext?.studyPeriodId}`;
    const openStudent = (id) => {
        const url = `${REDIRECT_STUDENT}?studentId=${id}${contextUrl}`;
        window.open(url);
    };
    const openTeacher = (id) => {
        const url = `${REDIRECT_TEACHER}?teacherId=${id}${contextUrl}`;
        window.open(url);
    };
    const openTeacherStudent = (id) => {
        const url = `${REDIRECT_TEACHER_STUDENT}?student/${id}${contextUrl}`;
        window.open(url);
    };
    return { openStudent, openTeacher, openTeacherStudent };
};
