import React from 'react';
import { Discipline, ThemeUnit } from '../../../../../../../common/components/PageRelated/Body/components/Table/Columns';
import Skill from './components/Columns/Skill';
import DidacticSequence from './components/Columns/DidacticSequence';
import KnowledgeObjects from './components/Columns/KnowledgeObjects';
import LearningObjectives from './components/Columns/LearningObjectives';
export const columns = [
    {
        id: 'disciplineName',
        Header: 'Componente Curricular',
        centered: true,
        sticky: 'left',
        borderOn: 'right',
        width: 190,
        minWidth: 190,
        accessor: (planning) => planning.areaSkill?.discipline,
        Cell: ({ value }) => React.createElement(Discipline, { discipline: value })
    },
    {
        id: 'themeUnit',
        Header: 'Unidade Temática',
        sticky: 'left',
        borderOn: 'right',
        width: 190,
        minWidth: 190,
        accessor: (planning) => planning.areaSkill?.themeUnit,
        Cell: ({ value }) => React.createElement(ThemeUnit, { themeUnit: value })
    },
    {
        id: 'skill',
        Header: 'Habilidade',
        sticky: 'left',
        borderOn: 'right',
        centered: true,
        doNotAddRightBorderToCell: true,
        width: 320,
        minWidth: 320,
        accessor: (planning) => planning,
        Cell: ({ row }) => React.createElement(Skill, { row: row })
    },
    {
        id: 'learningObjectives',
        Header: 'Objetivos de Aprendizagem',
        borderOn: 'right',
        centered: true,
        width: 300,
        minWidth: 300,
        accessor: (planning) => planning,
        Cell: ({ value }) => React.createElement(LearningObjectives, { planning: value })
    },
    {
        id: 'knowledgeObjects',
        Header: 'Objetos de Conhecimento',
        borderOn: 'right',
        width: 230,
        minWidth: 230,
        centered: true,
        accessor: (planning) => planning.objects,
        Cell: ({ value }) => React.createElement(KnowledgeObjects, { objects: value })
    },
    {
        id: 'didaticSequence',
        Header: 'Sequência Didática',
        borderOn: 'right',
        centered: true,
        width: 600,
        minWidth: 600,
        spanAcrossAllRows: true,
        accessor: (planning) => planning,
        Cell: () => React.createElement(DidacticSequence, null)
    },
    {
        id: 'fakeColumn',
        Header: '',
        width: 0,
        minWidth: 0,
        columns: [
            {
                id: 'innerFakeColumn',
                Header: '',
                width: 0,
                minWidth: 0
            }
        ]
    }
];
