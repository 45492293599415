import { Api } from '@campus/auth';
import { Response, PaginationModel, Stage } from '../models';
export const getAllStages = async () => {
    try {
        const response = await Api.INSTANCE.getApi().get('/stage');
        return Response.fromData(PaginationModel.fromJson(Stage, response.data), response.status);
    }
    catch (e) {
        return Response.error(e);
    }
};
