import React from 'react';
import LogoImage from '@campus/components/assets/logo.svg';
import { ContextType, useAuthority } from '@campus/auth';
import { OverlayTrigger, Popover, useSideMenu } from '@campus/components';
import UserPopover from '../UserPopover';
import { Avatar, HeaderBackground, HeaderContainer, HeaderLogo, HeaderStyled, HeaderTitle, VerticalDivider } from './styles';
const Header = () => {
    const { currentContext } = useAuthority();
    const userImage = currentContext?.userImage;
    const isStudent = currentContext?.person?.contextType === ContextType.STUDENT;
    const { setHiden } = useSideMenu();
    return (React.createElement(HeaderStyled, { isStudent: isStudent },
        React.createElement(HeaderBackground, { isStudent: isStudent }),
        React.createElement(HeaderContainer, { isStudent: isStudent },
            React.createElement(HeaderLogo, { alt: "Campus", src: LogoImage, onClick: () => setHiden(false) }),
            React.createElement(VerticalDivider, null),
            React.createElement(HeaderTitle, null, "P\u00E1gina Inicial"),
            React.createElement(OverlayTrigger, { trigger: "click", placement: "left", rootClose: true, overlay: React.createElement(Popover, null,
                    React.createElement(UserPopover, null)) },
                React.createElement(Avatar, { url: userImage })))));
};
export default Header;
