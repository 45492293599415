import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
export const StyledWrapper = styled.div `
  position: relative;

  display: flex;
  column-gap: 8px;
  align-items: flex-start;

  border-bottom: 1px solid ${(props) => props.theme.colors.gray200};

  padding-top: 3px;
  padding-left: 8px;
  padding-right: 6px;
  padding-bottom: ${(props) => (props.$addPaddingBottom ? '10px' : '0')};

  scroll-margin-top: 30px;
`;
export const StyledPopupAndHamburgerIconWrapper = styled.div `
  height: 100%;
  position: relative;
`;
export const StyledHamburgerIconWrapper = styled.div `
  cursor: pointer !important;

  & div {
    display: block;
    position: relative;

    width: 18px;
    height: 18px;
  }
`;
export const StyledHamburgerIcon = styled(CampusIcon).attrs({
    name: 'hamburger'
}) `
  position: absolute;
  top: 0;
`;
