import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from '@campus/components';
import { useAuthority } from '@campus/auth';
import { useStudentPerformace } from './hooks/useStudentPerformace';
import PerformaceTable from './components/PerformaceTable';
import PrintablePdf from './components/PrintablePdf';
import { DivPage, EntityContent, EntityImage, ImportantInfo, ImportantInfoTile, TableContainer, ReportHeader, ReportHeaderContent, ReportHeaderLeft, ReportHeaderRight, ReportFooterLabel, SmallTitle, TableFooter } from './styles';
import Variant from '../../models/Variant';
import { useContextManager, ClassDisciplinesProvider } from '../../providers';
import { formatDate } from '../../utils/dateToString';
const StudentPerformanceToPrint = ({ studentId, refFirstPage, refSecondPage, toPrintPdf = false, printPDF = () => { } }) => {
    const navigate = useNavigate();
    const { classroom, classrooms, period } = useContextManager();
    if (!!period && period.active !== true) {
        navigate(-1);
    }
    let defaultClassroomName = classroom?.name;
    if (classrooms?.filter((c) => !!c.gradeId)?.length === 1) {
        defaultClassroomName = classrooms.find((c) => !!c.gradeId)?.name;
    }
    const { unityName, currentContext } = useAuthority();
    const { loading, discipline, evaluations, studentDetail } = useStudentPerformace(studentId);
    const userName = studentDetail?.user?.name;
    const dateGenerated = formatDate(new Date());
    const hasSecondPage = discipline?.length > 19;
    if (loading) {
        return (React.createElement(TableContainer, null,
            React.createElement(Skeleton, { height: "500px", width: "100%" })));
    }
    return hasSecondPage ? (React.createElement(ClassDisciplinesProvider, { variant: Variant.STUDENT },
        React.createElement(DivPage, { ref: refFirstPage },
            React.createElement(PrintablePdf, { toPrintPdf: toPrintPdf, printPDF: printPDF }),
            React.createElement(ReportHeader, null,
                React.createElement(ReportHeaderContent, null,
                    React.createElement(ReportHeaderLeft, null,
                        React.createElement("h1", null, "Boletim Escolar"),
                        React.createElement("span", null,
                            "RELAT\u00D3RIO DE APRENDIZAGEM ",
                            period.name)),
                    React.createElement(ReportHeaderRight, null,
                        React.createElement("label", null,
                            React.createElement("strong", null, "ALUNO:"),
                            " ",
                            userName),
                        React.createElement("label", null,
                            React.createElement("strong", null, "S\u00C9RIE/TURMA:"),
                            " ",
                            defaultClassroomName),
                        React.createElement("label", null,
                            React.createElement("strong", null, "UNIDADE:"),
                            " ",
                            unityName))),
                React.createElement(EntityContent, null,
                    React.createElement(EntityImage, { key: `${currentContext.name}`, src: currentContext?.logoUrl, "aria-label": currentContext.name }))),
            React.createElement(TableContainer, null,
                React.createElement(SmallTitle, null, "P\u00E1gina 1/2"),
                React.createElement(PerformaceTable, { discipline: discipline.slice(0, 15), evaluations: evaluations }))),
        React.createElement(DivPage, { ref: refSecondPage },
            React.createElement(TableContainer, null,
                React.createElement(SmallTitle, null, "P\u00E1gina 2/2"),
                React.createElement(PerformaceTable, { discipline: discipline.slice(15, discipline.length), evaluations: evaluations }),
                React.createElement(TableFooter, null,
                    React.createElement(ReportFooterLabel, null,
                        "Boletim escolar gerado no dia ",
                        dateGenerated))),
            React.createElement(ImportantInfo, null,
                React.createElement(SmallTitle, null, "Informa\u00E7\u00F5es Importantes"),
                React.createElement(ImportantInfoTile, { key: "mdc", "$color": "#003C71" },
                    React.createElement("b", null, "Meu Desempenho Campus (MDC):"),
                    "\u00A0 \u00C9 a nota consolidada do estudante at\u00E9 o momento. Ela \u00E9 atualizada em tempo real a partir da somat\u00F3ria dos percentuais de Atividades Processuais e Atividades S\u00EDnteses."),
                evaluations.map((e, i) => (React.createElement(ImportantInfoTile, { key: `${i}${e.title}`, "$color": e.color },
                    React.createElement("b", null,
                        e.title,
                        ":"),
                    "\u00A0",
                    e.description))))))) : (React.createElement(ClassDisciplinesProvider, { variant: Variant.STUDENT },
        React.createElement(DivPage, { ref: refFirstPage },
            React.createElement(PrintablePdf, { toPrintPdf: toPrintPdf, printPDF: printPDF }),
            React.createElement(ReportHeader, null,
                React.createElement(ReportHeaderContent, null,
                    React.createElement(ReportHeaderLeft, null,
                        React.createElement("h1", null, "Boletim Escolar"),
                        React.createElement("span", null,
                            "RELAT\u00D3RIO DE APRENDIZAGEM ",
                            period.name)),
                    React.createElement(ReportHeaderRight, null,
                        React.createElement("label", null,
                            React.createElement("strong", null, "ALUNO:"),
                            " ",
                            userName),
                        React.createElement("label", null,
                            React.createElement("strong", null, "S\u00C9RIE/TURMA:"),
                            " ",
                            defaultClassroomName),
                        React.createElement("label", null,
                            React.createElement("strong", null, "UNIDADE:"),
                            " ",
                            unityName))),
                React.createElement(EntityContent, null,
                    React.createElement(EntityImage, { key: `${currentContext.name}`, src: currentContext?.logoUrl, "aria-label": currentContext.name }))),
            React.createElement(TableContainer, null,
                React.createElement(PerformaceTable, { discipline: discipline, evaluations: evaluations }),
                React.createElement(TableFooter, null,
                    React.createElement(ReportFooterLabel, null,
                        "Boletim escolar gerado no dia ",
                        dateGenerated))),
            React.createElement(ImportantInfo, null,
                React.createElement(SmallTitle, null, "Informa\u00E7\u00F5es Importantes"),
                React.createElement(ImportantInfoTile, { key: "mdc", "$color": "#003C71" },
                    React.createElement("b", null, "Meu Desempenho Campus (MDC):"),
                    "\u00A0 \u00C9 a nota consolidada do estudante at\u00E9 o momento. Ela \u00E9 atualizada em tempo real a partir da somat\u00F3ria dos percentuais de Atividades Processuais e Atividades S\u00EDnteses."),
                evaluations.map((e, i) => (React.createElement(ImportantInfoTile, { key: `${i}${e.title}`, "$color": e.color },
                    React.createElement("b", null,
                        e.title,
                        ":"),
                    "\u00A0",
                    e.description)))))));
};
export default StudentPerformanceToPrint;
