import styled from 'styled-components';
export const Content = styled.div `
  padding: 15px 0;
`;
export const Text = styled.p `
  margin: 0;

  font-size: 17px;
  font-weight: 500;

  line-height: 100%;

  color: ${(props) => props.theme.colors.gray1};
`;
