import React, { useMemo } from 'react';
import { Event, useManagerId } from '@campus/commons';
import { useFilters } from '../hooks';
import Context from './context';
export const Provider = ({ children }) => {
    const managerId = useManagerId();
    const events = useMemo(() => {
        return {
            filters: {
                gradeIdChanged: new Event(),
                classIdChanged: new Event(),
                disciplineIdChanged: new Event(),
                filtersChanged: new Event()
            },
            table: {
                pageIndexChanged: new Event(),
                dataFetched: new Event(),
                dataFetchFailed: new Event()
            }
        };
    }, []);
    const getFilters = useFilters(events);
    return (React.createElement(Context.Provider, { value: { managerId, events, getFilters } }, children));
};
