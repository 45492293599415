import React from 'react';
import { RequestStatus } from '@campus/commons';
import { useAddOrEditActivityPopupContext } from '../../../common/addOrEditActivity/context';
import { StyledBlueButton } from '../../../common/styles';
import { shouldButtonBeDisabled } from './helpers';
const statusesAndButtonContent = {
    [RequestStatus.NotStarted]: 'Editar Atividade',
    [RequestStatus.Loading]: 'Salvando alterações...',
    [RequestStatus.Succeeded]: 'Alterações salvas!',
    [RequestStatus.Failed]: 'Falha ao editar'
};
const EditActivityButton = ({ activity, status, onClick }) => {
    const { impactTheMDC, classesAndInfos, sequenceDescription, selectedEvaluationType, anImageIsBeingUploaded } = useAddOrEditActivityPopupContext((store) => ({
        impactTheMDC: store.impactTheMDC,
        classesAndInfos: store.classesAndInfos.data,
        sequenceDescription: store.sequenceDescription,
        selectedEvaluationType: store.selectedEvaluationType,
        anImageIsBeingUploaded: store.anImageIsBeingUploaded
    }));
    const disabled = shouldButtonBeDisabled({
        impactTheMDC,
        classesAndInfos,
        anImageIsBeingUploaded,
        sequence: activity,
        description: sequenceDescription,
        evaluationType: selectedEvaluationType
    });
    return (React.createElement(StyledBlueButton, { onClick: onClick, disabled: disabled }, statusesAndButtonContent[status]));
};
export default EditActivityButton;
