import styled from 'styled-components';
export const ContainerListVideos = styled.div `
  grid-column: content;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  margin: 35px 0px 40px 0px;
  column-gap: 21px;
  row-gap: 21px;
`;
