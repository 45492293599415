import React from 'react';
import { RequestStatus } from '@campus/commons';
import { Container, Text } from './styles';
export const commonPlaceholders = {
    questionDataUnavailable: 'As informações da questão não estão disponíveis',
    loadingQuestionData: 'Carregando informações da questão...',
    failedToLoadQuestionData: 'Falha ao carregar informações da questão'
};
export function statusToPlaceholder(status) {
    switch (status) {
        case RequestStatus.Loading:
            return commonPlaceholders.loadingQuestionData;
        case RequestStatus.Failed:
            return commonPlaceholders.failedToLoadQuestionData;
        default:
            return null;
    }
}
const QuestionPlaceholder = ({ children }) => {
    return (React.createElement(Container, null,
        React.createElement(Text, null, children)));
};
export default QuestionPlaceholder;
