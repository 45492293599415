import React, { useMemo, useState } from 'react';
import { Tabs } from '../../commons/types';
import { PresentationTab, VideoAnalysisTab, QuestionsTab } from '../components';
export function useTabs() {
    const [selectedTab, setSelectedTab] = useState(Tabs.Presentation);
    const selectedTabComponent = useMemo(() => {
        switch (selectedTab) {
            case Tabs.Presentation:
                return React.createElement(PresentationTab, null);
            case Tabs.VideoAnalysis:
                return React.createElement(VideoAnalysisTab, null);
            case Tabs.Questions:
                return React.createElement(QuestionsTab, null);
        }
    }, [selectedTab]);
    return {
        selectedTab,
        setSelectedTab,
        selectedTabComponent
    };
}
