import React from 'react';
import { useAuthority } from '@campus/auth';
import Banner from '@campus/components/assets/images/banner.png';
import { ALink, Img } from './styles';
const MainBanner = () => {
    const { currentContext } = useAuthority();
    const ImgBanner = currentContext?.bannerImageUrl ?? Banner;
    const ImgBannerMobile = currentContext?.bannerImageMobileUrl ?? Banner;
    const redirectUrl = currentContext?.bannerRedirectUrl;
    return (React.createElement(ALink, { href: redirectUrl, target: "_blank" },
        React.createElement(Img, { src: ImgBanner, imgMobile: ImgBannerMobile, alt: "Bem-vindo ao Campus!" })));
};
export default MainBanner;
