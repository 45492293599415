import styled from 'styled-components';
export const Box = styled.div `
  background: #fff;

  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.1);

  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
`;
export const Line = styled.hr `
  margin: 0;

  height: 1px;

  opacity: 0.1;

  background: #000;
`;
