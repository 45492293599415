import { useRef, useEffect, useCallback } from 'react';
export function useFilters(events) {
    const filters = useRef({
        segmentId: null,
        gradeId: null,
        disciplineId: null,
        classId: null
    });
    useEffect(() => {
        return events.filters.gradeIdChanged.subscribe((payload) => {
            filters.current.segmentId = payload.newSegmentId ?? null;
            filters.current.gradeId = payload.newGradeId ?? null;
            filters.current.disciplineId = null;
            filters.current.classId = null;
            events.filters.filtersChanged.emit({ filters: filters.current });
        });
    }, [events.filters.filtersChanged, events.filters.gradeIdChanged]);
    useEffect(() => {
        return events.filters.disciplineIdChanged.subscribe((payload) => {
            filters.current.disciplineId = payload.newId ?? null;
            filters.current.classId = null;
            events.filters.filtersChanged.emit({ filters: filters.current });
        });
    }, [events.filters.disciplineIdChanged, events.filters.filtersChanged]);
    useEffect(() => {
        return events.filters.classIdChanged.subscribe((payload) => {
            filters.current.classId = payload.newId ?? null;
            events.filters.filtersChanged.emit({ filters: filters.current });
        });
    }, [events.filters.classIdChanged, events.filters.filtersChanged]);
    return useCallback(() => filters.current, []);
}
