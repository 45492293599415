import { useState } from 'react';
const useDragAndDrop = ({ onChange }) => {
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDraggingOver(true);
    };
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDraggingOver(false);
    };
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDraggingOver(false);
        onChange(e.dataTransfer.files);
    };
    return {
        isDraggingOver,
        handleDragOver,
        handleDragLeave,
        handleDrop
    };
};
export default useDragAndDrop;
