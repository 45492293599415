import styled from 'styled-components';
export const Container = styled.div `
  grid-column: content;

  display: flex;
  column-gap: 9px;
`;
export const TabContainer = styled.div `
  padding: 5px 12px 4px 13px;

  border-radius: 4px 4px 0px 0px;

  cursor: ${(props) => (props.$isSelected ? 'initial' : 'pointer')};

  background: ${(props) => (props.$isSelected ? 'white' : '#ECECEC')};

  box-shadow: ${(props) => props.$isSelected
    ? '0px -2px 5px 0px rgba(0, 0, 0, 0.1)'
    : '0px -2px 4px 0px rgba(0, 0, 0, 0.07) inset'};
`;
export const TabText = styled.span `
  color: #4a4a4a;

  font-size: 12px;
  font-weight: 700;

  line-height: 21px;
`;
