import React from 'react';
import { Table } from '@campus/components';
import PercentageContainer from './Percentage';
import { DisciplineColor, DisciplineText, DisciplineTitle, DisciplineTitleContainer, TableHead, TablePanel, TableHeadTitle, TableTd } from '../styles';
const PerformaceTableMobile = ({ discipline }) => {
    const disciplineFilter = discipline.filter((d) => {
        return d.showPerformance;
    });
    return (React.createElement(TablePanel, null,
        React.createElement(Table, { "$hasScoreBorder": false },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement(TableHeadTitle, null, "Componentes Curriculares"),
                    React.createElement(TableHead, null, "MDC"))),
            React.createElement("tbody", null, disciplineFilter.map((d, i) => (React.createElement("tr", { key: `${i}-${d.id}` },
                React.createElement(DisciplineTitle, null,
                    React.createElement(DisciplineTitleContainer, null,
                        React.createElement(DisciplineColor, { "$color": d.color }),
                        React.createElement(DisciplineText, null, d.name))),
                React.createElement(TableTd, { total: d.currentWeight, showTotal: d.currentCount > 0 && d.currentWeight > 0 },
                    React.createElement(PercentageContainer, { percentages: d.evaluations.map((e) => ({
                            color: e.color,
                            percentage: e.weight
                        })), total: d.currentWeight, showTotal: d.currentCount > 0 && d.currentWeight > 0 })))))))));
};
export default PerformaceTableMobile;
