import React from 'react';
import { Container, ContainerItem, Value, Description, Separator } from './styles';
const Steps = ({ steps, current }) => {
    return (React.createElement(Container, null, steps.map((step, index) => (React.createElement(ContainerItem, { key: step.value },
        React.createElement("div", null,
            React.createElement(Value, { current: current === step.value }, step.value)),
        React.createElement(Description, { current: current === step.value }, step.description.split(" ").map((label) => (React.createElement("span", null, label)))),
        steps.length - 1 !== index && (React.createElement(Separator, { current: current === step.value })))))));
};
export default Steps;
