import { createStore } from 'zustand';
import PedagogicalPlanningGroup from '../../../../../../../../../../../models/PedagogicalPlanningGroup';
import { createSequenceOperationsSlice } from './slices/sequenceOperations/slice';
export function createGroupStore(params) {
    return createStore()((set, ...args) => ({
        group: params.groupFromFilteredContent,
        _groupFromNonFilteredContent: params.groupFromNonFilteredContent,
        updateActivities(activities) {
            const newGroup = new PedagogicalPlanningGroup();
            const oldGroup = params.groupFromNonFilteredContent;
            newGroup.number = oldGroup.number;
            newGroup.plannings = oldGroup.plannings;
            newGroup.didacticSequence = activities;
            for (const planning of newGroup.plannings) {
                planning.didaticSequences = activities.filter((a) => a.pedagogicalPlanningId === planning.id);
            }
            params.updateGroupOnMainTable(newGroup);
        },
        sequenceOperations: createSequenceOperationsSlice({
            updateGroupOnMainTable: params.updateGroupOnMainTable
        })(set, ...args)
    }));
}
