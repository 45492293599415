class ClassificationTypeItem {
    id;
    code;
    description;
    total;
    totalAnswers;
    totalCorrect;
    performance;
    performanceStatus;
    static fromJson(data) {
        const classificationTypeItem = new ClassificationTypeItem();
        classificationTypeItem.id = data.Id;
        classificationTypeItem.code = data.Code;
        classificationTypeItem.description = data.Description;
        classificationTypeItem.total = data.Total;
        classificationTypeItem.totalAnswers = data.TotalAnswers;
        classificationTypeItem.totalCorrect = data.TotalCorrect;
        classificationTypeItem.performance = data.Performance;
        classificationTypeItem.performanceStatus = data.PerformanceStatus;
        return classificationTypeItem;
    }
}
export default ClassificationTypeItem;
