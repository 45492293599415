import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
import PopupBox from '../../../../../../../../common/components/Popups/PopupBox';
export const AlertIcon = styled(CampusIcon) `
  width: 20px;
  color: ${(props) => props.theme.colors.orange300};
  display: block;
`;
export const AlertButton = styled.button `
  display: flex;
  height: 32px;
  width: 32px;
  padding: 6px;

  justify-content: center;
  align-items: center;
  border-radius: 6px;
  color: ${(props) => props.theme.colors.orange300};
  border: 1px solid ${({ theme }) => theme.colors.orange300};
  background: ${({ theme }) => theme.colors.orange50};

  ${(props) => {
    if (props.disabled) {
        return 'opacity: 0.8;';
    }
    return `
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }
    `;
}}
`;
export const AlertPopupStyled = styled(PopupBox) `
  position: absolute;

  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.orange300};
  background: ${({ theme }) => theme.colors.orange50};

  padding: 11px 10px 13px 7px;
  min-width: 291px;
  max-height: 500px;

  top: calc(100% + -17px);
  right: 14px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.orange50};
    border-radius: 6px;
    margin: 1px 0px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.orange500};
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: ${({ theme }) => theme.colors.orange300};
  }

  @media (${(props) => props.theme.mediaQuery.tabletDown}) {
    max-height: 300px;
  }

  @media (${(props) => props.theme.mediaQuery.desktopDown}) {
    max-height: 353px;
  }
`;
export const ListItems = styled.ul `
  margin: 0;
  padding-left: 16px;
  overflow-y: auto;
`;
export const Item = styled.li `
  margin-bottom: 6px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.orange500};

  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;
export const LinkStyled = styled.a `
  color: ${({ theme }) => theme.colors.orange500};
  text-decoration-line: underline;
`;
