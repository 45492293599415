import { Api } from '@campus/auth';
import { Message, Response } from '../models';
export const listMessage = async ({ studentId, classId, stageId, page }) => {
    try {
        const response = await Api.INSTANCE.getApi().get(`/message/${studentId}`, {
            params: { classId, stageId, page: page ?? 1 }
        });
        return Response.fromData(response.data.map((item) => Message.fromJson(item)), response.status);
    }
    catch (e) {
        return Response.error(e);
    }
};
