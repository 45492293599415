import { Api } from '@campus/auth';
import { Classroom, PaginationModel, Response } from '../models';
export const listClassroom = async ({ teacherId, studentId, managerId, disciplineMatrixId }) => {
    try {
        const response = await Api.INSTANCE.getApi().get(`/class`, {
            params: {
                TeacherId: teacherId,
                StudentId: studentId,
                ManagerId: managerId,
                DisciplineMatrixId: disciplineMatrixId,
                SkipCount: 0,
                MaxResultCount: 1000
            }
        });
        return Response.fromData(PaginationModel.fromJson(Classroom, response.data), response.status);
    }
    catch (e) {
        return Response.error(e);
    }
};
