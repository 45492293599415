import React, { useEffect, useState } from 'react';
import { ContentItem } from '@campus/content-items';
import { useLiteraryWorkPageContext } from '../../../../context';
import { useQuestionsTabContext } from '../../context';
import { useFetchQuestionItemData, useHandleQuestionUpdated, useUploadForQuestion } from '../../hooks';
import { commentBoxInfos } from '../../commons/commentBox';
import QuestionPlaceholder, { commonPlaceholders, statusToPlaceholder } from '../QuestionPlaceholder';
import { Container, Question } from './styles';
const QuestionThatCanBeAnswered = () => {
    const [contentItem, setContentItem] = useState();
    const { literaryWorkId } = useLiteraryWorkPageContext();
    const { selectedQuestionId } = useQuestionsTabContext();
    const { createUploadInstance } = useUploadForQuestion({
        literaryWorkId,
        questionId: selectedQuestionId ?? ''
    });
    const { data, requestStatus, refetchData } = useFetchQuestionItemData({
        literaryWorkId,
        questionId: selectedQuestionId
    });
    useHandleQuestionUpdated({ refetchQuestionData: refetchData });
    useEffect(() => {
        setContentItem(data?.contentItem);
    }, [data?.contentItem]);
    let placeholder = statusToPlaceholder(requestStatus);
    if (!placeholder && (!data || !data.contentItem)) {
        placeholder = commonPlaceholders.questionDataUnavailable;
    }
    if (placeholder) {
        return React.createElement(QuestionPlaceholder, null, placeholder);
    }
    return (React.createElement(Container, null,
        React.createElement(Question, { hideSettingsBox: true, contentItem: contentItem, commentBox: commentBoxInfos, createUploadInstance: createUploadInstance, onChange: (updatedItem) => {
                setContentItem(() => {
                    const newItem = new ContentItem();
                    return Object.assign(newItem, updatedItem);
                });
            } })));
};
export default QuestionThatCanBeAnswered;
