import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Col, Row } from 'react-bootstrap';
import ToolCard from '../ToolCard';
import DisabledCards from '../DisabledCards';
import { Header, Title, ImageIcon, DivStyled } from './styles';
const MyTools = ({ tools, marginTop, breakpoint }) => {
    return (React.createElement(DivStyled, { "$marginTop": marginTop },
        React.createElement(Header, null,
            React.createElement(Title, null, "Minhas Ferramentas")),
        React.createElement(Row, { className: "gx-3 gy-3", xs: 2, sm: 3, md: 4, lg: 3, xl: 5, xxl: 5 },
            tools.map((tool) => (React.createElement(Col, { key: uuidv4(), onClick: tool.click, role: "button" },
                React.createElement(ToolCard, { name: tool.name, icon: () => (React.createElement(ImageIcon, { src: tool.icon.src, alt: tool.icon.alt, "$bgColor": tool.icon.bgColor })) })))),
            React.createElement(DisabledCards, { listLength: tools.length, customMinHeight: 100, breakpoint: breakpoint }))));
};
export default MyTools;
