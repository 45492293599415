import styled from 'styled-components';
export const Container = styled.li.attrs((props) => ({
    className: props.$isSelected ? 'selected' : ''
})) `
  padding: 10px 11px 8px 10px;

  min-height: 33px;

  display: flex;
  column-gap: 8px;
  align-items: center;
  justify-content: space-between;

  &:not(.selected) {
    cursor: pointer;

    &:hover {
      // converted from #457AF5 to add transparency
      background: rgba(69, 122, 245, 0.05);
    }
  }
`;
export const Description = styled.p `
  margin: 0;

  font-size: 14px;
  font-weight: 600;

  line-height: 110%;

  color: ${(props) => props.$isSelected
    ? props.$questionIsDiscursive
        ? props.theme.colors.green3
        : props.theme.colors.CSA.blue
    : props.theme.colors.gray3};
`;
