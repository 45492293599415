import React from 'react';
import { CampusChartContainer } from './styles';
import { Chart, registerables } from 'chart.js';
function getGradient(canvas) {
    let gradient;
    let width = window.innerWidth || document.body.clientWidth;
    gradient = canvas.createLinearGradient(0, 100, 0, 0);
    gradient.addColorStop(1, '#1db000');
    gradient.addColorStop(0.3, '#e8e010');
    gradient.addColorStop(0, '#df850f');
    return gradient;
}
const RateTime = ({ stats }) => {
    const accessTimeRef = React.useRef();
    React.useLayoutEffect(() => {
        Chart.register(...registerables);
        const canvas = accessTimeRef.current.getContext('2d');
        var gradientGridLine = canvas.createLinearGradient(0, 140, 0, 0);
        gradientGridLine.addColorStop(0, 'rgba(0, 0, 0, 1)');
        gradientGridLine.addColorStop(0, 'rgba(0, 0, 0, 0.2)');
        gradientGridLine.addColorStop(1, 'rgba(0, 0, 0, 0)');
        const data = {
            datasets: [
                {
                    data: stats.map((s) => s.rate),
                    showLine: true,
                    pointBorderColor: '#f2f2f2',
                    pointBorderWidth: 2.5,
                    pointHoverBorderColor: 'rgba(255, 255, 255, 0.2)',
                    pointHoverBorderWidth: 10,
                    pointRadius: 4,
                    clip: 10,
                    xAxisID: 'xAxes',
                    yAxisID: 'yAxes',
                    label: 'Ritmo',
                    borderColor: getGradient(canvas),
                    pointBackgroundColor: getGradient(canvas),
                    fill: false
                }
            ],
            labels: stats.map((s) => s.studentLetter)
        };
        const chart = new Chart(accessTimeRef.current, {
            data,
            type: 'line',
            options: {
                elements: {
                    line: {
                        borderJoinStyle: 'round'
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                        labels: {
                            font: {
                                family: 'Gilroy'
                            }
                        }
                    },
                    tooltip: {
                        callbacks: {
                            label: (tooltipItem) => ` ${tooltipItem.raw}%`,
                            title: (tooltipItem) => tooltipItem[0].dataset.label,
                            footer: (tooltipItem) => stats[tooltipItem[0].dataIndex].date
                        },
                        titleFont: {
                            size: 16
                        },
                        bodyFont: {
                            size: 14
                        },
                        footerMarginTop: 8
                    }
                },
                scales: {
                    xAxes: {
                        display: false,
                        grid: {
                            drawBorder: true,
                            borderColor: 'rgba(189, 189, 189, 0.5)'
                        },
                        ticks: {
                            padding: 0,
                            font: {
                                family: 'Gilroy',
                                size: 8,
                                weight: '400'
                            },
                            color: '#BDBDBD'
                        }
                    },
                    yAxes: {
                        display: true,
                        grid: {
                            drawBorder: true,
                            borderColor: 'rgba(189, 189, 189, 0.5)'
                        },
                        max: 100,
                        min: 0,
                        alignToPixels: true,
                        beginAtZero: true,
                        suggestedMin: 0,
                        ticks: {
                            padding: 0,
                            font: {
                                family: 'Gilroy',
                                size: 11,
                                weight: '400'
                            },
                            color: '#BDBDBD',
                            stepSize: 25
                        }
                    }
                }
            }
        });
        return () => {
            Chart.unregister();
            chart.destroy();
        };
    }, []);
    return (React.createElement("div", { className: "w-100" },
        React.createElement(CampusChartContainer, { ref: accessTimeRef })));
};
export default RateTime;
