import React, { useState } from 'react';
import CornerDownRight from '@campus/components/assets/icons/corner-down-right-inverse.svg';
import { Title, ActionContent, ActionButton, AddedLabel } from '../styles';
const ClassificationSubItem = ({ subItem, isAdded, onAdd, onRemove, forRemoving = false, disabled = false }) => {
    const [showAction, setShowAction] = useState(false);
    let variant = 'default';
    if (isAdded) {
        variant = forRemoving ? 'remove' : 'added';
    }
    const renderVariant = () => {
        switch (variant) {
            case 'added':
                return (React.createElement(ActionContent, { "$show": true, "$variant": "added" },
                    React.createElement(AddedLabel, null, "Adicionado")));
            case 'remove':
                return (React.createElement(ActionContent, { "$show": showAction, "$variant": "remove" },
                    React.createElement(ActionButton, { onClick: () => onRemove(subItem.id), "$variant": "remove" }, "Remover")));
            default:
                return (React.createElement(ActionContent, { "$show": showAction, "$variant": "default" },
                    React.createElement(ActionButton, { onClick: () => onAdd(subItem.id), "$variant": "add" }, "Adicionar")));
        }
    };
    return (React.createElement(Title, { "$variant": variant, "$disabled": disabled, onMouseEnter: () => setShowAction(true), onMouseLeave: () => setShowAction(false), tabIndex: 0 },
        React.createElement("img", { alt: "", "aria-hidden": true, src: CornerDownRight }),
        React.createElement("strong", null,
            "(",
            subItem.code,
            ")"),
        "\u00A0",
        subItem.description,
        renderVariant()));
};
export default ClassificationSubItem;
