import { useEffect } from 'react';
import { useQuestionsTabContext } from '../context';
export function useHandleQuestionUpdated({ refetchQuestionData }) {
    const { events, selectedQuestionId } = useQuestionsTabContext();
    useEffect(() => {
        return events.aQuestionWasUpdated.subscribe((payload) => {
            if (selectedQuestionId === payload?.updatedQuestion.id) {
                refetchQuestionData();
            }
        });
    }, [events.aQuestionWasUpdated, refetchQuestionData, selectedQuestionId]);
}
