import { useCallback, useEffect, useState } from 'react';
import { addPresentationItem, deletePresentationItem, updatePresentationItem, updatePresentationItemIndex } from '../../../../../../services';
import { swapItems, getValidationErrorsFrom } from './utils';
import { errorMessages } from './errors';
export function useItemsManager(props) {
    const { items: initialItems, literaryWorkId, onError } = props;
    const [items, setItems] = useState(initialItems);
    useEffect(() => {
        if (initialItems)
            setItems(initialItems);
    }, [initialItems]);
    const handleError = useCallback((error, fallbackMessage) => {
        const errors = getValidationErrorsFrom(error);
        onError(errors.length > 0 ? errors : fallbackMessage);
    }, [onError]);
    const addItem = useCallback(async (type) => {
        try {
            const response = await addPresentationItem({
                literaryWorkId,
                presentationItemType: type
            });
            const newItem = response.data;
            if (!newItem) {
                onError(errorMessages.addIsMissingData);
                return;
            }
            newItem.afterCreation();
            setItems((currentValue) => {
                return [...currentValue, newItem];
            });
        }
        catch (error) {
            handleError(error, errorMessages.addFailed);
        }
    }, [literaryWorkId, onError, handleError]);
    const removeItem = useCallback(async (item) => {
        try {
            await deletePresentationItem({
                literaryWorkId,
                presentationItemId: item.id
            });
            setItems((currentValue) => {
                return currentValue.filter((i) => {
                    return i.id !== item.id;
                });
            });
        }
        catch (error) {
            handleError(error, errorMessages.removeFailed);
        }
    }, [literaryWorkId, handleError]);
    const reorderItem = useCallback(async (params) => {
        try {
            await updatePresentationItemIndex({
                literaryWorkId,
                newIndex: params.toIndex,
                presentationItemId: params.item.id
            });
            setItems((currentValue) => {
                const newValue = [...currentValue];
                swapItems(params.fromIndex, params.toIndex, newValue);
                return newValue;
            });
        }
        catch (error) {
            handleError(error, errorMessages.reorderFailed);
        }
    }, [literaryWorkId, handleError]);
    const updateItem = useCallback(async (item) => {
        try {
            const response = await updatePresentationItem({
                literaryWorkId,
                presentationItemId: item.id,
                newContent: item.data
            });
            const updatedItem = response.data;
            if (!updatedItem) {
                onError(errorMessages.updateIsMissingData);
                return;
            }
            setItems((currentValue) => {
                return currentValue.map((i) => {
                    return i.id === item.id ? updatedItem : i;
                });
            });
        }
        catch (error) {
            handleError(error, errorMessages.updateFailed);
        }
    }, [literaryWorkId, onError, handleError]);
    return { items, addItem, reorderItem, removeItem, updateItem };
}
