import React, { useState } from 'react';
import { Card, Image } from '@campus/components';
import { Container, ImageStyled } from './styles';
import Title from '../Title';
import { ViewModal } from '../';
const ImageRead = ({ link, name, onChangeTitle, readOnly }) => {
    const [openModal, setOpenModal] = useState(false);
    const toggleModal = () => setOpenModal((prev) => !prev);
    return (React.createElement(React.Fragment, null,
        React.createElement(Card, { overflowHidden: true, fullHeight: true },
            React.createElement(Container, null,
                React.createElement(Image, { src: link, onClick: toggleModal }),
                React.createElement(Title, { value: name, onChange: onChangeTitle, disabled: readOnly }))),
        React.createElement(ViewModal, { handleClose: toggleModal, open: openModal },
            React.createElement(ImageStyled, { src: link, alt: "imagem" }))));
};
export default ImageRead;
