import React, { Fragment } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useWeeklyPlanningPageContext } from '../../../../../../../../context';
import { GroupContextProvider } from '../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/context';
import { ThemeUnit, Discipline } from '../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns';
import { borderShouldBeDashed, borderShouldBeRed } from '../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/components/Rows/helpers/borders';
import Skill from '../Columns/Skill';
import DidacticSequence from '../Columns/DidacticSequence';
import KnowledgeObjects from '../Columns/KnowledgeObjects';
import LearningObjectives from '../Columns/LearningObjectives';
import Comments from '../Columns/Comments';
import { Column, Container } from './styles';
const Group = ({ index: groupIndex, showDisciplineColumn, group: groupFromFilteredContent }) => {
    const updateGroup = useWeeklyPlanningPageContext((store) => store.mainTable.advancedViewMode.operations.updateGroup);
    const getGroupFromNonFilteredContent = useWeeklyPlanningPageContext((store) => store.mainTable.advancedViewMode.operations.getGroupFromNonFilteredContent);
    const selectedSegment = useWeeklyPlanningPageContext((store) => store.curriculumComponents.selectedSegment);
    const selectedGrade = useWeeklyPlanningPageContext((store) => store.curriculumComponents.selectedGrade);
    const selectedDisciplines = useWeeklyPlanningPageContext((store) => store.curriculumComponents.selectedDisciplines);
    return (React.createElement(GroupContextProvider, { updateGroupOnMainTable: updateGroup, groupFromFilteredContent: groupFromFilteredContent, groupFromNonFilteredContent: getGroupFromNonFilteredContent(groupFromFilteredContent.number) },
        React.createElement(Draggable, { index: groupIndex, draggableId: groupFromFilteredContent.number.toString() }, (provided) => (React.createElement(Container, { ref: provided.innerRef, ...provided.draggableProps }, groupFromFilteredContent.plannings.map((planning, planningIndex) => {
            const $inFirstRow = planningIndex === 0;
            const $inLastRow = planningIndex ===
                groupFromFilteredContent.plannings.length - 1;
            const $withDashedBorder = borderShouldBeDashed({
                relatedPlanning: planning
            });
            const $withRedBorder = borderShouldBeRed({
                relatedPlanning: planning,
                selectedSegment,
                selectedGrade,
                selectedDisciplines
            });
            const columnProps = {
                $inFirstRow,
                $inLastRow,
                $withRedBorder,
                $withDashedBorder
            };
            return (React.createElement(Fragment, { key: planning.id },
                showDisciplineColumn ? (React.createElement(Column, { ...columnProps, "$stickyLeft": 0, "$firstColumn": true },
                    React.createElement(Discipline, { discipline: planning.areaSkill.discipline }))) : null,
                React.createElement(Column, { ...columnProps, "$firstColumn": !showDisciplineColumn, "$stickyLeft": showDisciplineColumn ? 47 : 0 },
                    React.createElement(ThemeUnit, { themeUnit: planning.areaSkill.themeUnit })),
                React.createElement(Column, { ...columnProps, "$withoutRightBorder": true, "$stickyLeft": showDisciplineColumn ? 47 + 47 : 47 },
                    React.createElement(Skill, { planning: planning, planningIndex: planningIndex, draggableProvided: provided })),
                React.createElement(Column, { ...columnProps },
                    React.createElement(LearningObjectives, { planning: planning })),
                React.createElement(Column, { ...columnProps },
                    React.createElement(KnowledgeObjects, { planning: planning })),
                planningIndex === 0 ? (React.createElement(React.Fragment, null,
                    React.createElement(Column, { ...columnProps, "$rowSpan": groupFromFilteredContent.plannings.length },
                        React.createElement(DidacticSequence, { planning: planning })),
                    React.createElement(Column, { ...columnProps, "$lastColumn": true, "$rowSpan": groupFromFilteredContent.plannings.length },
                        React.createElement(Comments, { comments: groupFromFilteredContent.comments, pedagogicalPlanningId: planning.id })))) : null));
        }))))));
};
export default Group;
