import React, { useState } from 'react';
import Avatar from '@campus/components/assets/icons/avatar.svg';
import { Content, Image, StatusBackground, StatusBorder, Name, AvatarPercentage } from './styles';
const capitalizeFirstLetter = (string) => {
    const stringSplitted = string.toLowerCase().trim().split(' ');
    let newString = '';
    for (const word of stringSplitted) {
        newString +=
            word.length > 1
                ? `${word.charAt(0).toUpperCase() + word.slice(1)} `
                : `${word} `;
    }
    return newString;
};
const SquaredAvatar = ({ name, image, status, percentage }) => {
    const [showAvatar, setShowAvatar] = useState(!image);
    const userName = capitalizeFirstLetter(name) ?? '';
    const firstName = userName.split(' ')[0];
    let secoundName = userName.split(' ')[1];
    if (secoundName.length <= 3 && userName.split(' ')[2]) {
        secoundName += ` ${userName.split(' ')[2]}`;
    }
    return (React.createElement("div", { className: "position-relative" },
        percentage !== null && (React.createElement(AvatarPercentage, { "$status": status, "$percentageHigherZero": percentage > 0 },
            React.createElement("span", null,
                percentage.toFixed(2).replace('.', ','),
                "%"))),
        React.createElement(Content, { "aria-label": userName },
            showAvatar ? (React.createElement(Image, { src: Avatar, alt: userName })) : (React.createElement(Image, { src: image, alt: userName, onError: () => setShowAvatar(true) })),
            React.createElement(Name, null,
                firstName,
                " ",
                React.createElement("br", null),
                " ",
                secoundName),
            status && React.createElement(StatusBackground, { "$status": status }),
            status && status !== 'neutral' && (React.createElement(StatusBorder, { "$status": status, percentageHigherZero: percentage > 0 })))));
};
export default SquaredAvatar;
