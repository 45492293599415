import styled from 'styled-components';
export const Container = styled.div `
  display: grid;
  grid-template-columns: var(--columns);

  &:has(> *.in-first-row.with-red-border) > .spannable {
    border-top-color: ${({ theme }) => theme.colors.error};
  }

  &:has(> *.in-first-row.with-dashed-border) > .spannable {
    border-top-width: 2px;
    border-top-style: dashed;
  }

  &:has(> *.in-last-row.with-red-border) > .spannable {
    border-bottom-color: ${({ theme }) => theme.colors.error};
  }

  &:has(> *.in-last-row.with-dashed-border) > .spannable {
    border-bottom-width: 2px;
    border-bottom-style: dashed;
  }
`;
export const Column = styled.div.attrs((props) => ({
    className: (props.$inFirstRow ? 'in-first-row' : '') +
        (props.$inLastRow ? ' in-last-row' : '') +
        (props.$firstColumn ? ' first-column' : '') +
        (props.$lastColumn ? ' last-column' : '') +
        (props.$withRedBorder ? ' with-red-border' : '') +
        (props.$withDashedBorder ? ' with-dashed-border' : '') +
        (props.$withoutRightBorder ? ' without-right-border' : '') +
        (Number.isInteger(props.$rowSpan) ? ' spannable' : '') +
        (Number.isInteger(props.$stickyLeft) ? ' sticky' : '')
})) `
  background: white;

  border-style: solid;
  border-width: 0 1px 1px 0;
  border-color: ${({ theme }) => theme.colors.gray200};

  &.spannable {
    grid-row: span ${({ $rowSpan }) => $rowSpan};
  }

  &.sticky {
    z-index: 1;
    position: sticky;
    left: ${({ $stickyLeft }) => $stickyLeft}px;
  }

  &.with-red-border.in-first-row {
    border-top-color: ${({ theme }) => theme.colors.error};
  }

  &.with-red-border.in-last-row {
    border-bottom-color: ${({ theme }) => theme.colors.error};
  }

  &.with-red-border.first-column {
    border-left-color: ${({ theme }) => theme.colors.error};
  }

  &.with-red-border.last-column {
    border-right-color: ${({ theme }) => theme.colors.error};
  }

  &.with-dashed-border.in-first-row {
    border-top-width: 2px;
    border-top-style: dashed;
  }

  &.with-dashed-border.in-last-row {
    border-bottom-width: 2px;
    border-bottom-style: dashed;
  }

  &.with-dashed-border.first-column {
    border-left-width: 2px;
    border-left-style: dashed;
  }

  &.with-dashed-border.last-column {
    border-right-width: 2px;
    border-right-style: dashed;
  }

  &.first-column {
    border-left-width: 1px;
  }

  &.in-first-row {
    border-top-width: 1px;
  }

  &.without-right-border {
    border-right-width: 0;
  }

  &.first-column.in-first-row {
    border-top-left-radius: 4px;
  }

  &.last-column.in-first-row {
    border-top-right-radius: 4px;
  }

  &.first-column.in-last-row {
    border-bottom-left-radius: 4px;
  }

  &.last-column.spannable,
  &.last-column.in-last-row {
    border-bottom-right-radius: 4px;
  }
`;
