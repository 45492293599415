import React from 'react';
import { useLiteraryWorkPageContext } from '../../../../context';
import { useQuestionsTabContext } from '../../context';
import QuestionPlaceholder from '../QuestionPlaceholder';
import ReadOnlyQuestion from '../ReadOnlyQuestion';
import ManageableQuestion from '../ManageableQuestion';
import QuestionThatCanBeAnswered from '../QuestionThatCanBeAnswered/QuestionThatCanBeAnswered';
const Question = () => {
    const { questionsCanBe } = useLiteraryWorkPageContext();
    const { selectedQuestionId } = useQuestionsTabContext();
    if (!selectedQuestionId) {
        return (React.createElement(QuestionPlaceholder, null, "Nenhuma quest\u00E3o selecionada"));
    }
    switch (questionsCanBe) {
        case 'viewed':
            return React.createElement(ReadOnlyQuestion, null);
        case 'answered':
            return React.createElement(QuestionThatCanBeAnswered, null);
        default:
            return React.createElement(ManageableQuestion, null);
    }
};
export default Question;
