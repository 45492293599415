class ContentMessages {
    messageType;
    text;
    title;
    static fromJson(data) {
        const contentMessages = new ContentMessages();
        contentMessages.messageType = data?.MessageType;
        contentMessages.text = data?.Text;
        contentMessages.title = data?.Title;
        return contentMessages;
    }
}
export default ContentMessages;
