import React from 'react';
import CloseRadius from '@campus/components/assets/icons/close-radius.svg';
import { Container, StyledModal, CloseImg } from './styles';
const ViewModal = ({ open, handleClose, children }) => {
    return (React.createElement(StyledModal, { show: open, onHide: handleClose, centered: true, fullscreen: true },
        React.createElement(StyledModal.Header, null,
            React.createElement(CloseImg, { src: CloseRadius, alt: "Fechar V\u00EDdeo", onClick: handleClose })),
        React.createElement(StyledModal.Body, null,
            React.createElement(Container, null, children))));
};
export default ViewModal;
