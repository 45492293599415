import { useCallback, useEffect, useState } from 'react';
import { mapTagsInputItems, toast } from '@campus/components';
import { createLiteraryWork, updateLiteraryWork, uploadCoverImage } from '../../../../../services/literaryWork';
export const useModalLiteraryWork = (onHide, onSaved, literaryWork) => {
    const [literaryWorkState, setLiteraryWorkState] = useState(literaryWork ?? {
        id: '',
        name: '',
        author: '',
        published: false,
        shortText: '',
        relatedVestibulars: []
    });
    const [tags, setTags] = useState(mapTagsInputItems(literaryWork?.relatedVestibulars ?? []));
    const [coverImageFileUrl, setCoverImageFileUrl] = useState(literaryWorkState?.coverImageUrl);
    const [coverImageFile, setCoverImageFile] = useState(null);
    const [validatedFields, setValidatedFields] = useState(true);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (literaryWorkState?.relatedVestibulars) {
            setTags(mapTagsInputItems(literaryWorkState.relatedVestibulars));
        }
    }, [literaryWorkState?.relatedVestibulars]);
    const handleHide = useCallback(() => {
        setLiteraryWorkState(literaryWork ?? {
            id: '',
            name: '',
            author: '',
            published: false,
            shortText: '',
            relatedVestibulars: []
        });
        setTags([]);
        setCoverImageFileUrl('');
        setValidatedFields(true);
        onHide();
    }, [literaryWork, onHide]);
    const onChange = (key, value) => {
        setLiteraryWorkState({ ...literaryWorkState, [key]: value });
    };
    const handleChangeImage = (files) => {
        const file = files?.[0] ?? null;
        const imageUrl = file ? URL.createObjectURL(file) : undefined;
        setCoverImageFileUrl(imageUrl);
        setCoverImageFile(file);
    };
    const onRemoveRelatedVestibular = (vestibular) => {
        if (literaryWorkState) {
            const relatedVestibulars = literaryWorkState.relatedVestibulars?.filter((item) => item !== vestibular) ?? [];
            setLiteraryWorkState({ ...literaryWorkState, relatedVestibulars });
        }
    };
    const onAddRelatedVestibular = (vestibular) => {
        if (literaryWorkState) {
            const relatedVestibulars = [
                ...(literaryWorkState.relatedVestibulars ?? []),
                vestibular
            ];
            onChange('relatedVestibulars', relatedVestibulars);
        }
        else {
            onChange('relatedVestibulars', [vestibular]);
        }
    };
    const handleCreateLiteraryWork = useCallback(async () => {
        setValidatedFields(true);
        try {
            const result = await createLiteraryWork(literaryWorkState);
            if (!result.data) {
                throw new Error('Erro ao adicionar obra literária');
            }
            if (coverImageFile != null) {
                await uploadCoverImage(result.data, coverImageFile);
            }
            toast.success('Obra literária adicionada com sucesso');
            onSaved();
            handleHide();
        }
        catch (errorData) {
            toast.error(errorData?.error?.message ?? 'Erro ao adicionar obra literária');
        }
    }, [coverImageFile, literaryWorkState, onSaved, handleHide]);
    const handleUpdateLiteraryWork = useCallback(async (literaryWorkToUpdate) => {
        setValidatedFields(true);
        try {
            if (coverImageFile == null && !coverImageFileUrl) {
                delete literaryWorkToUpdate.coverImageUrl;
            }
            else if (coverImageFile != null) {
                await uploadCoverImage(literaryWorkToUpdate, coverImageFile);
            }
            const result = await updateLiteraryWork(literaryWorkToUpdate);
            if (!result.data) {
                throw new Error('Failed to create literary work');
            }
            toast.success('Obra literária atualizada com sucesso');
            onSaved();
            handleHide();
        }
        catch (errorData) {
            toast.error(errorData?.error?.message ?? 'Erro ao adicionar obra literária');
        }
    }, [coverImageFile, coverImageFileUrl, onSaved, handleHide]);
    const onSave = useCallback(async () => {
        setLoading(true);
        if (literaryWorkState?.name && literaryWorkState?.author) {
            await (literaryWork?.id
                ? handleUpdateLiteraryWork(literaryWorkState)
                : handleCreateLiteraryWork());
        }
        else {
            setValidatedFields(false);
        }
        setLoading(false);
    }, [
        handleCreateLiteraryWork,
        handleUpdateLiteraryWork,
        literaryWorkState,
        literaryWork?.id
    ]);
    return {
        literaryWorkState,
        tags,
        coverImageFileUrl,
        validatedFields,
        loading,
        onChange,
        handleChangeImage,
        onRemoveRelatedVestibular,
        onAddRelatedVestibular,
        onSave,
        handleHide
    };
};
