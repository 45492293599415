import { Response } from '@campus/commons';
import { getDidaticSequenceById } from '../didaticSequence';
import { addClassroomAndDidacticSequence, deleteClassroomAndSequence } from '../classroomAndDidaticSequence';
function getCorrespondingClassroomAndDidacticSequence(params, classroom) {
    return params.sequence?.classrooms?.find((c) => {
        return c?.classroom?.id === classroom?.id;
    });
}
function addNewClassrooms(params) {
    return params.newClassrooms.map((classroom) => {
        const classroomAndDidaticSequence = getCorrespondingClassroomAndDidacticSequence(params, classroom);
        return addClassroomAndDidacticSequence({
            executed: false,
            executedClassroomId: null,
            classroomId: classroom.id,
            sequenceId: params.sequence.id,
            abortSignal: params.abortSignal,
            foreseen: classroomAndDidaticSequence?.foreseen ?? true
        });
    });
}
function deleteSequenceClassrooms(params) {
    return params.sequence.classrooms.map((c) => {
        return deleteClassroomAndSequence({
            abortSignal: params.abortSignal,
            classroomAndSequenceId: c.id
        });
    });
}
export async function updateDidacticSequenceClassrooms(params) {
    try {
        await Promise.all(deleteSequenceClassrooms(params));
        await Promise.all(addNewClassrooms(params));
        return await getDidaticSequenceById({
            id: params.sequence.id,
            abortSignal: params.abortSignal
        });
    }
    catch (error) {
        return Response.fromError(error);
    }
}
