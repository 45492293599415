import { useEffect } from 'react';
export function useEventHandlers({ events, questionSummaries, refetchQuestionSummaries, selectedQuestionId, setSelectedQuestionId }) {
    useEffect(() => {
        const event1 = events.aQuestionWasCreated;
        const event2 = events.aQuestionWasDeleted;
        const unsubscribe1 = event1.subscribe(refetchQuestionSummaries);
        const unsubscribe2 = event2.subscribe(refetchQuestionSummaries);
        return () => {
            unsubscribe1();
            unsubscribe2();
        };
    }, [
        events.aQuestionWasCreated,
        events.aQuestionWasDeleted,
        refetchQuestionSummaries
    ]);
    useEffect(() => {
        return events.aQuestionWasDeleted.subscribe((payload) => {
            if (selectedQuestionId === payload?.questionId) {
                // eslint-disable-next-line unicorn/no-useless-undefined
                setSelectedQuestionId(undefined);
            }
        });
    }, [selectedQuestionId, events.aQuestionWasDeleted, setSelectedQuestionId]);
    useEffect(() => {
        return events.aQuestionWasUpdated.subscribe((payload) => {
            if (!payload || !payload.updatedQuestion)
                return;
            const summary = questionSummaries.find((s) => s.id === payload.updatedQuestion.id);
            if (!summary)
                return;
            if (payload.updatedQuestion.questionType !== summary.type) {
                refetchQuestionSummaries();
            }
        });
    }, [events.aQuestionWasUpdated, questionSummaries, refetchQuestionSummaries]);
}
