import { getDistinct } from '../../../utils/arrays';
import { disciplineIdsInStorage, gradeIdInStorage, segmentIdInStorage } from './storage';
export function getLastSelectedSegment(segments) {
    if (!segments || segments.length === 0)
        return null;
    const lastSelectedSegmentId = segmentIdInStorage();
    if (!lastSelectedSegmentId)
        return null;
    return segments.find((s) => s.id === lastSelectedSegmentId);
}
export function getLastSelectedGrade(grades) {
    if (!grades || grades.length === 0)
        return null;
    const lastSelectedGradeId = gradeIdInStorage();
    if (!lastSelectedGradeId)
        return null;
    return grades.find((g) => g.id === lastSelectedGradeId);
}
export function getLastSelectedDisciplines(disciplines) {
    if (!disciplines || disciplines.length === 0)
        return [];
    const ids = disciplineIdsInStorage();
    if (!ids)
        return [];
    return getDistinct(disciplines.filter((d) => ids.includes(d.id)), (d) => d.id);
}
