import React from 'react';
import { Skeleton } from '@campus/components';
import { DashboardCard, Description, Information } from '../../styles';
import TitleContainer from '../TitleContainer';
import AccessTime from '../Charts/AccessTime';
import { studentTimeStats } from '../../../../services/dashboard';
import { useContextManager } from '../../../../providers';
const AccessCard = ({ studentId }) => {
    const { classroom } = useContextManager();
    const [loadingStats, setLoadingStats] = React.useState(true);
    const [stats, setStats] = React.useState();
    React.useEffect(() => {
        const getData = async () => {
            setLoadingStats(true);
            const result = await studentTimeStats(classroom.id, studentId);
            setStats(result.data);
            setLoadingStats(false);
        };
        getData();
    }, [classroom.id]);
    return (React.createElement(React.Fragment, null, loadingStats ? (React.createElement(DashboardCard, { className: "d-flex justify-content-center align-items-center p-0" },
        React.createElement(Skeleton, { height: "100%", width: "100%" }))) : (React.createElement(DashboardCard, null,
        React.createElement(TitleContainer, { title: 'TEMPO DE ACESSO', timeSeries: stats.stats.length, videoURL: process.env.REACT_APP_VIDEO_ACCESS_TIME }),
        React.createElement(Information, null,
            React.createElement("span", null, "Tempo M\u00E9dio de Acesso"),
            React.createElement("span", null,
                stats.averageTime,
                " min")),
        React.createElement(AccessTime, { stats: stats.stats }),
        React.createElement(Description, null, "Indica o tempo e a frequ\u00EAncia de acesso do estudante na plataforma. Mais importante que a quantidade de tempo \u00E9 a frequ\u00EAncia.")))));
};
export default AccessCard;
