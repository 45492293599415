import React from 'react';
import { Skeleton } from '@campus/components';
import Flow from '../Charts/Flow';
import FlowTitleContainer from '../FlowTitleContainer';
import TitleContainer from '../TitleContainer';
import { ChartDescription } from './styles';
import { DashboardCard } from '../../styles';
import { useStudentPerformace } from '../../../../containers/StudentPerformance/hooks/useStudentPerformace';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
const FlowCard = () => {
    const { isMobile } = useWindowDimensions();
    const { loading, discipline } = useStudentPerformace();
    return (React.createElement(React.Fragment, null, loading ? (React.createElement(DashboardCard, { className: "d-flex justify-content-center align-items-center p-0", "$lastCard": true },
        React.createElement(Skeleton, { height: "100%", width: "100%" }))) : (React.createElement(DashboardCard, { "$lastCard": true },
        isMobile && (React.createElement(TitleContainer, { title: 'FLUXO CAMPUS', videoURL: process.env.REACT_APP_VIDEO_CAMPUS_FLOW })),
        !isMobile && React.createElement(FlowTitleContainer, { title: 'FLUXO CAMPUS' }),
        React.createElement("div", null,
            React.createElement(Flow, { discipline: discipline }),
            React.createElement(ChartDescription, null, "Indica como est\u00E1 o engajamento (realiza\u00E7\u00E3o dos exerc\u00EDcios) e o desempenho (Meu Desempenho Campus) do estudante por componente curricular."))))));
};
export default FlowCard;
