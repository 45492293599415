import EvaluationType from './EvaluationType';
import ClassroomAndDidacticSequence from './ClassroomAndDidacticSequence';
export default class DidaticSequence {
    id;
    order;
    isDeleted;
    impactTheMDC;
    description;
    pedagogicalPlanningId;
    evaluationType;
    classrooms;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new DidaticSequence();
        instance.id = json.Id;
        instance.order = json.Order;
        instance.isDeleted = json.Deleted;
        instance.description = json.Description;
        instance.impactTheMDC = json.ImpactTheMDC;
        instance.pedagogicalPlanningId = json.PedagogicalPlanningId;
        instance.evaluationType = EvaluationType.fromJson(json.EvaluationType);
        instance.classrooms = json.Classrooms
            ? json.Classrooms.map((c) => ClassroomAndDidacticSequence.fromJson(c))
            : [];
        return instance;
    }
}
