import React, { useState } from 'react';
import { FooterPagination, FooterPaginationNumberPages, ButtonPage } from './styles';
const PaginationBar = ({ fetchData, numberPages, pageZeroButtonRef }) => {
    const [page, setPage] = useState(0);
    const loadPrevPage = async () => {
        const newPage = page - 1;
        setPage(newPage);
        await fetchData(newPage);
    };
    const loadNextPage = async () => {
        const newPage = page + 1;
        setPage(newPage);
        await fetchData(newPage);
    };
    const loadPage = async (page) => {
        setPage(page);
        await fetchData(page);
    };
    return (React.createElement(FooterPagination, null,
        React.createElement(ButtonPage, { onClick: loadPrevPage, disabled: page === 0, "$selected": false }, "Anterior"),
        React.createElement(FooterPaginationNumberPages, null, numberPages.map((p) => (React.createElement(React.Fragment, null,
            p === 0 && (React.createElement(ButtonPage, { ref: pageZeroButtonRef, key: p, onClick: () => loadPage(p), disabled: p === page, "$selected": p === page }, p + 1)),
            p !== 0 && (React.createElement(ButtonPage, { key: p, onClick: () => loadPage(p), disabled: p === page, "$selected": p === page }, p + 1)))))),
        React.createElement(ButtonPage, { onClick: loadNextPage, disabled: page === numberPages.length - 1, "$selected": false }, "Pr\u00F3xima")));
};
export default PaginationBar;
