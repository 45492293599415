import React from 'react';
import { StyledModal, TitleContent } from './styles';
import Modal from '../Modal';
const StandardModal = ({ show, onHide, title, size = 'sm', subTitle, renderBody, renderFooter, renderStyledCloseButton, closeButton = true }) => {
    return (React.createElement(StyledModal, { show: show, onHide: onHide, size: size },
        React.createElement(Modal.Header, { closeButton: closeButton },
            React.createElement(TitleContent, null,
                React.createElement(Modal.Title, null, title),
                subTitle && React.createElement("span", null, subTitle)),
            renderStyledCloseButton && renderStyledCloseButton()),
        renderBody && React.createElement(Modal.Body, null, renderBody()),
        renderFooter && React.createElement(Modal.Footer, null, renderFooter())));
};
export default StandardModal;
