import React, { useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editors from '@campus/editor';
import { customProperties } from './stylesRelated';
import { createImageUploadAdapterPlugin } from './customPlugins';
import { useOverwriteRootCustomProperties } from './hooks/useOverwriteRootCustomProperties';
export const Editor = (props) => {
    const { overwritePropertyValue } = useOverwriteRootCustomProperties();
    function saveData(editor) {
        if (props.saveContentAfterDelay) {
            const data = editor.getData();
            return props.saveContentAfterDelay(data);
        }
    }
    function onBlur(_event, editor) {
        if (props.onBlur) {
            props.onBlur(editor.getData());
        }
    }
    function onContentChange(_event, editor) {
        if (props.onChange) {
            props.onChange(editor.getData());
        }
    }
    function imageUploadAdapterPlugin(editor) {
        return createImageUploadAdapterPlugin(editor, props.onImageUpload);
    }
    useEffect(() => {
        if (!props.customPropertiesAndTheirValues)
            return;
        // overwritePropertyValue returns an undo function 
        // that sets the previous value back to the property,
        // and these undo functions will be called when the 
        // component unmounts to avoid permanent overwrites
        const undoCallbacks = [];
        const entries = Object.entries(props.customPropertiesAndTheirValues);
        for (const [key, value] of entries) {
            const propertyName = customProperties[key];
            undoCallbacks.push(overwritePropertyValue(propertyName, value));
        }
        return () => {
            for (const callback of undoCallbacks) {
                callback();
            }
        };
    }, []);
    return (React.createElement(CKEditor, { editor: Editors.CustomClassicEditor, onBlur: onBlur, onChange: onContentChange, data: props.content ?? '', disabled: props.readOnly ?? false, config: {
            placeholder: props.placeholder ?? '',
            link: { addTargetToExternalLinks: true },
            autosave: { waitingTime: 10000, save: saveData },
            extraPlugins: [imageUploadAdapterPlugin]
        } }));
};
