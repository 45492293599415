class CustomImageUploadAdapter {
    loader;
    uploadHandler;
    constructor(loader, handler) {
        this.loader = loader;
        this.uploadHandler = handler;
    }
    upload() {
        return this.loader.file.then((file) => {
            return new Promise((resolve, reject) => {
                this.uploadHandler(file)
                    .then((response) => {
                    resolve({ default: response });
                })
                    .catch((error) => reject(error));
            });
        });
    }
}
export default CustomImageUploadAdapter;
