import React from 'react';
import { OptionListDialog } from '@campus/components';
const MenuOptionsDialog = ({ className, onEditOptionClick, onDeleteOptionClick }) => {
    return (React.createElement(OptionListDialog, { ...(className ? { className } : {}), items: [
            {
                label: 'Editar Questão',
                onClick: onEditOptionClick
            },
            {
                label: 'Apagar Questão',
                onClick: onDeleteOptionClick
            }
        ] }));
};
export default MenuOptionsDialog;
