import React, { useState } from 'react';
import Popup from './components/Popup';
import Infos from './components/Infos';
import CodeAndDescription from '../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/SkillRelated/components/CodeAndDescription';
import { StyledFirstColumnWrapper, StyledPopupWrapper, StyledWrapper } from './styles';
import { StyledIconsWrapper, StyledHamburgerIconWrapper, StyledHamburgerIcon, StyledPadLockIconWrapper, StyledPadLockIcon, StyledCircleWithCheck, StyledCircleWithCheckWrapper } from '../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/SkillRelated/components/Icons/styles';
const Skill = (props) => {
    const [canShowPopup, setCanShowPopup] = useState(false);
    const showPopup = () => setCanShowPopup(true);
    const hidePopup = () => setCanShowPopup(false);
    const isPlanningDeleted = props.planning.status.isDeleted() ?? false;
    const isPlanningFinished = props.planning.status.isFinished() ?? false;
    const isPlanningFinishedOrDeleted = isPlanningFinished || isPlanningDeleted;
    const isPlanningEvaluative = props.planning.areaSkill.evaluative;
    const hamburguerIcon = {
        ref: isPlanningFinishedOrDeleted ? null : props.draggableProvided?.innerRef,
        props: isPlanningFinishedOrDeleted
            ? {}
            : props.draggableProvided?.dragHandleProps
    };
    return (React.createElement(StyledWrapper, null,
        React.createElement(StyledFirstColumnWrapper, null,
            React.createElement(StyledIconsWrapper, null,
                React.createElement(StyledHamburgerIconWrapper, { onClick: showPopup, ref: hamburguerIcon.ref, ...hamburguerIcon.props },
                    React.createElement(StyledHamburgerIcon, null)),
                isPlanningFinishedOrDeleted ? (React.createElement(StyledPadLockIconWrapper, null,
                    React.createElement(StyledPadLockIcon, null))) : null,
                isPlanningEvaluative ? (React.createElement(StyledCircleWithCheckWrapper, null,
                    React.createElement(StyledCircleWithCheck, null))) : null),
            React.createElement(StyledPopupWrapper, null,
                React.createElement(Popup, { key: props.planning.id, planning: props.planning, canBeDisplayed: canShowPopup, planningIndex: props.planningIndex, closePopup: hidePopup }))),
        React.createElement("div", null,
            React.createElement(CodeAndDescription, { areaSkill: props.planning.areaSkill, addLineThroughDescription: isPlanningDeleted }),
            React.createElement(Infos, { areaSkill: props.planning.areaSkill }))));
};
export default Skill;
