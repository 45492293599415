import styled from 'styled-components';
import { Placeholder } from '../../../commons/components';
export const Container = styled.div `
  grid-column: full-width;

  display: grid;
  row-gap: 27.58px;
  grid-template-columns: inherit;
`;
export const ContentContainer = styled.div `
  grid-column: content;

  display: grid;
  column-gap: 15.77px;
  grid-template-columns: 141.232px 1fr;
`;
export const WorkInfosContainer = styled.div `
  padding: 8px 0 11.42px 0;
`;
export const WorkName = styled.h3 `
  margin-bottom: 2px;

  font-size: 24px;
  font-weight: 700;

  line-height: 113.2%;

  color: ${(props) => props.theme.colors.gray2};
`;
export const WorkAuthor = styled.p `
  margin-bottom: 13px;

  font-size: 18px;
  font-weight: 500;

  line-height: 120%;

  color: ${(props) => props.theme.colors.gray600};
`;
export const WorkShortText = styled.p `
  font-size: 16px;
  font-weight: 500;

  line-height: 120%;

  color: ${(props) => props.theme.colors.gray600};
`;
export const PlaceholderContainer = styled(Placeholder.Container) `
  grid-column: content;

  min-height: 200px;
`;
export const PlaceholderText = styled(Placeholder.Text) ``;
