import React from 'react';
import { StandardModal } from '@campus/components';
import { useStudentPerformace } from '../../../../containers/StudentPerformance/hooks/useStudentPerformace';
import { Description, StyledCloseButton, StyledFlow } from './styles';
const FlowModal = ({ open, handleClose }) => {
    const { discipline } = useStudentPerformace();
    const renderBody = () => (React.createElement(React.Fragment, null,
        React.createElement(StyledFlow, { discipline: discipline, increaseDataPointSize: true }),
        React.createElement(Description, null, "Indica como est\u00E1 o engajamento (realiza\u00E7\u00E3o dos exerc\u00EDcios) e o desempenho (Meu Desempenho Campus) do estudante por componente curricular.")));
    const renderStyledCloseButton = () => (React.createElement(StyledCloseButton, { onClick: handleClose }, "Fechar"));
    return (React.createElement(StandardModal, { show: open, onHide: handleClose, title: "Fluxo Campus", subTitle: "\u00DAltimos 21 dias", renderBody: renderBody, renderStyledCloseButton: renderStyledCloseButton, size: "lg", closeButton: false }));
};
export default FlowModal;
