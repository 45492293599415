import { Api } from '@campus/auth';
import { ContentItem, ContentItemQuestionData, ContentItemState, ContentItemType, Question } from '@campus/content-items';
import { Response, UploadResult } from '@campus/commons';
import LiteraryWorkQuestionSummary from '../models/LiteraryWorkQuestionSummary';
import { endpointBasePath } from './common';
const allowedUploadTypesForQuestion = [
    'image',
    'audio',
    'document'
];
const endpointResourceName = 'question';
const questionEndpointsBasePath = '/question';
const apiEndpoints = {
    get: {
        details({ literaryWorkId, questionId }) {
            return `${endpointBasePath}/${literaryWorkId}/question/${questionId}/details`;
        },
        itemData({ literaryWorkId, questionId }) {
            return `${endpointBasePath}/${literaryWorkId}/${endpointResourceName}/${questionId}`;
        },
        summaries({ literaryWorkId }) {
            return `${endpointBasePath}/${literaryWorkId}/${endpointResourceName}`;
        }
    },
    add: {
        question: questionEndpointsBasePath,
        literaryWorkAndQuestionRelationship({ literaryWorkId }) {
            return `${endpointBasePath}/${literaryWorkId}/${endpointResourceName}`;
        }
    },
    update({ questionId }) {
        return `${questionEndpointsBasePath}/${questionId}`;
    },
    delete({ literaryWorkId, questionId }) {
        return `${endpointBasePath}/${literaryWorkId}/${endpointResourceName}/${questionId}`;
    },
    upload: {
        forQuestion(params) {
            const { literaryWorkId, questionId, whatToUpload } = params;
            return `${endpointBasePath}/${literaryWorkId}/${endpointResourceName}/${questionId}/upload/${whatToUpload}`;
        }
    }
};
export async function getQuestionSummaries(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = apiEndpoints.get.summaries(params);
        const response = await axiosInstance.get(endpoint);
        const summaries = Array.isArray(response.data)
            ? response.data.map((summary) => LiteraryWorkQuestionSummary.fromJson(summary))
            : [];
        return Response.fromData(summaries, response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
function createContentItemWithQuestionData(questionData) {
    const contentItem = new ContentItem();
    contentItem.saved = true;
    contentItem.state = ContentItemState.Read;
    contentItem.itemType = ContentItemType.QUESTION;
    contentItem.data = ContentItemQuestionData.fromJson(questionData);
    return contentItem;
}
export async function getQuestion(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = apiEndpoints.get.details(params);
        const response = await axiosInstance.get(endpoint);
        const question = Question.fromJson(response.data);
        return Response.fromData(question, response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
export async function getQuestionItemData(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = apiEndpoints.get.itemData(params);
        const response = await axiosInstance.get(endpoint, {
            params: { studentId: params.studentId }
        });
        const questionWasAnswered = response.data.Answered;
        const contentItem = createContentItemWithQuestionData(response.data.Data);
        return Response.fromData({ contentItem, questionWasAnswered }, response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
export async function addQuestion(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const addQuestionResponse = await axiosInstance.post(apiEndpoints.add.question, {
            Comment: params.comment,
            QuestionType: params.questionType,
            Items: params.items?.map((item) => item.toJson()),
            Answers: params.answers?.map((answer) => answer.toJson())
        });
        const question = Question.fromJson(addQuestionResponse.data);
        const { status } = await axiosInstance.post(apiEndpoints.add.literaryWorkAndQuestionRelationship(params), {
            QuestionId: question.id,
            QuestionType: params.questionType
        });
        return Response.fromData(question, status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
export async function deleteQuestion(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = apiEndpoints.delete(params);
        const response = await axiosInstance.delete(endpoint);
        return Response.fromData(true, response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
export async function updateQuestion(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = apiEndpoints.update(params);
        const response = await axiosInstance.put(endpoint, {
            Comment: params.comment,
            QuestionType: params.questionType,
            Items: params.items?.map((item) => item.toJson()),
            Answers: params.answers?.map((answer) => answer.toJson())
        });
        const question = Question.fromJson(response.data);
        return Response.fromData(question, response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
export async function uploadForQuestion(params) {
    if (!allowedUploadTypesForQuestion.includes(params.whatToUpload)) {
        return Response.fromError(new Error('Tipo inválido.'));
    }
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = apiEndpoints.upload.forQuestion(params);
        const response = await axiosInstance.put(endpoint, {
            Provider: params.provider,
            FileName: params.fileName,
            FileSize: params.fileSize
        });
        return Response.fromData(UploadResult.fromJson(response.data, params.provider), response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
