import React, { useState } from 'react';
import { LiteraryWorkQuestionKind } from '../../../../../../models';
import QuestionList from '../QuestionList';
import { CreateQuestionModal } from '../QuestionModals';
import { Container } from './styles';
const QuestionLists = () => {
    const [createQuestionModal, setCreateQuestionModal] = useState({
        show: false,
        questionKind: LiteraryWorkQuestionKind.Objective
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, null,
            React.createElement(QuestionList, { questionKind: LiteraryWorkQuestionKind.Objective, handleAddQuestionButtonClicked: () => {
                    const questionKind = LiteraryWorkQuestionKind.Objective;
                    setCreateQuestionModal({ show: true, questionKind });
                } }),
            React.createElement(QuestionList, { questionKind: LiteraryWorkQuestionKind.Discursive, handleAddQuestionButtonClicked: () => {
                    const questionKind = LiteraryWorkQuestionKind.Discursive;
                    setCreateQuestionModal({ show: true, questionKind });
                } })),
        createQuestionModal.show ? (React.createElement(CreateQuestionModal, { questionKind: createQuestionModal.questionKind, hideModal: () => {
                setCreateQuestionModal((currentValue) => {
                    return { ...currentValue, show: false };
                });
            } })) : null));
};
export default QuestionLists;
