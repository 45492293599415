import React from 'react';
import DocumentsColumn from './components/DocumentsColumn';
import { StyledTitle } from '../common/styles';
import { StyledColumnsWrapper, StyledTitleWrapper, StyledWrapper } from './styles';
const Popup = (props) => {
    return (React.createElement(StyledWrapper, { showClassroomAlertIcon: props.showClassroomAlertIcon, isFullScreen: false, canBeDisplayed: props.canBeDisplayed, handleExternalClick: props.hidePopup },
        React.createElement(StyledTitleWrapper, null,
            React.createElement(StyledTitle, null, "Exportar")),
        props.children ?? (React.createElement(StyledColumnsWrapper, null,
            React.createElement(DocumentsColumn, { onGenerateClassReport: props.generateClassReport, onGenerateClassDiaryReport: props.generateClassDiaryReport })))));
};
export default Popup;
