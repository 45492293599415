import React from 'react';
import { ThemeUnit, ReadOnlySkill, ReadOnlyLearningObjectives, ReadOnlyKnowledgeObjects, Discipline } from '../../../../../common/components/PageRelated/Body/components/Table/Columns';
import Stage from './components/TableBody/components/Columns/Stage';
export const columns = [
    {
        id: 'disciplineName',
        Header: 'CC',
        title: 'Componente Currícular',
        centered: true,
        sticky: 'left',
        borderOn: 'right',
        width: 47,
        minWidth: 47,
        accessor: (areaSkill) => areaSkill.discipline,
        Cell: ({ value }) => React.createElement(Discipline, { discipline: value })
    },
    {
        id: 'themeUnit',
        Header: 'UT',
        title: 'Unidade Temática',
        sticky: 'left',
        borderOn: 'right',
        centered: true,
        width: 47,
        minWidth: 47,
        accessor: (skill) => skill.themeUnit,
        Cell: ({ value }) => React.createElement(ThemeUnit, { themeUnit: value })
    },
    {
        id: 'skill',
        Header: 'Habilidade',
        sticky: 'left',
        borderOn: 'right',
        centered: true,
        doNotAddRightBorderToCell: true,
        width: 346,
        minWidth: 346,
        accessor: (skill) => skill,
        Cell: ({ value }) => React.createElement(ReadOnlySkill, { skill: value })
    },
    {
        id: 'learningObjectives',
        Header: 'Objetivos de Aprendizagem',
        borderOn: 'right',
        centered: true,
        width: 350,
        minWidth: 350,
        accessor: (skill) => skill,
        Cell: ({ value }) => React.createElement(ReadOnlyLearningObjectives, { areaSkill: value })
    },
    {
        id: 'knowledgeObjects',
        Header: 'Objetos de Conhecimento',
        borderOn: 'right',
        width: 230,
        minWidth: 230,
        centered: true,
        accessor: (skill) => skill.knowledgeObjects,
        Cell: ({ value }) => React.createElement(ReadOnlyKnowledgeObjects, { objects: value })
    },
    {
        id: 'stage',
        Header: 'Etapa',
        borderOn: 'right',
        centered: true,
        width: 150,
        minWidth: 150,
        accessor: (skill) => skill,
        Cell: ({ value }) => React.createElement(Stage, { areaSkill: value })
    }
];
