import { useCallback, useEffect } from 'react';
import { useFetch } from '@campus/commons';
import { getQuestion } from '../../../../../services';
export function useFetchQuestion({ literaryWorkId, questionId }) {
    const { data, requestStatus, fetchStarted, handleFailure, handleSuccess } = useFetch({ initialData: null });
    const fetchData = useCallback(async () => {
        if (!questionId)
            return;
        fetchStarted({ resetData: true });
        try {
            const response = await getQuestion({ literaryWorkId, questionId });
            handleSuccess(response?.data);
        }
        catch (error) {
            handleFailure(error);
        }
    }, [fetchStarted, handleFailure, handleSuccess, literaryWorkId, questionId]);
    useEffect(() => {
        fetchData();
    }, [fetchData]);
    return { data, requestStatus, refetchData: fetchData };
}
