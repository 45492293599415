import { toast } from '@campus/components';
import { RequestStatus } from '@campus/commons';
import { addDidacticSequenceAndRelatedClassrooms } from '../../../../../../services/agregators';
import { setLastModifiedActivityId } from '../../../../utils/classroomActions';
import * as actions from '../../common/addOrEditActivity/actions';
export function useActions({ store, abortSignal, pedagogicalPlanningId, newActivityIsUnforeseen, setRequestStatus, handleActivityAdded, hideModalAfterDelay }) {
    function getUpdatedGroupActivities() {
        return actions.getUpdatedGroupActivities({
            abortSignal,
            pedagogicalPlanningId
        });
    }
    function reorderActivities() {
        return actions.reorderActivities({
            abortSignal,
            pedagogicalPlanningId
        });
    }
    function updateSubsequentActivities(newActivity) {
        return actions.updateSubsequentActivities({
            newActivity,
            abortSignal,
            pedagogicalPlanningId,
            getAllSelectedClassrooms() {
                return store.getState().getAllSelectedClassrooms();
            }
        });
    }
    async function isThereAnyClassroomLinkedToOtherActivities() {
        setRequestStatus(RequestStatus.Loading);
        const result = await actions.isThereAnyClassroomLinkedToOtherActivities({
            abortSignal,
            pedagogicalPlanningId,
            getAllSelectedClassrooms() {
                return store.getState().getAllSelectedClassrooms();
            },
            onError() {
                toast.error('Falha ao verificar se já existem atividades vinculadas às aulas da nova atividade');
            }
        });
        setRequestStatus(RequestStatus.NotStarted);
        return result;
    }
    async function addActivity() {
        const state = store.getState();
        const response = await addDidacticSequenceAndRelatedClassrooms({
            abortSignal,
            impactTheMDC: state.impactTheMDC,
            planningId: pedagogicalPlanningId,
            description: state.sequenceDescription,
            classrooms: state.getAllSelectedClassrooms(),
            areClassroomsUnforeseen: newActivityIsUnforeseen,
            evaluationTypeId: state.selectedEvaluationType?.id
        });
        if (response.data) {
            return response.data;
        }
        return null;
    }
    async function getUpdatedActivitiesFailed(newActivity) {
        setRequestStatus(RequestStatus.Succeeded);
        toast.error('A atividade foi adicionada e as atividades do bloco foram reordenadas porém não foi possível recuperar as atividades do bloco atualizadas');
        handleActivityAdded({
            newActivity,
            updatedGroupActivities: null,
            otherActivitiesWereUpdated: false
        });
        hideModalAfterDelay();
    }
    function reorderFailed(newActivity) {
        setRequestStatus(RequestStatus.Succeeded);
        toast.error('A atividade foi adicionada com sucesso porém não foi possível reordenar as atividades do bloco');
        handleActivityAdded({
            newActivity,
            updatedGroupActivities: null,
            otherActivitiesWereUpdated: false
        });
        hideModalAfterDelay();
    }
    function addActivityFailed() {
        setRequestStatus(RequestStatus.Failed);
        toast.error('Falha ao adicionar atividade');
    }
    function updateSubsequentActivitiesFailed(newActivity) {
        setRequestStatus(RequestStatus.Succeeded);
        toast.error('A atividade foi adicionada com sucesso porém não foi possível alterar as aulas das atividades subsequentes');
        handleActivityAdded({
            newActivity,
            updatedGroupActivities: null,
            otherActivitiesWereUpdated: false
        });
        hideModalAfterDelay();
    }
    async function justAddActivity() {
        setRequestStatus(RequestStatus.Loading);
        const newActivity = await addActivity();
        if (!newActivity) {
            addActivityFailed();
            return;
        }
        const reorderSucceeded = await reorderActivities();
        if (!reorderSucceeded) {
            reorderFailed(newActivity);
            return;
        }
        const updatedActivities = await getUpdatedGroupActivities();
        if (!updatedActivities) {
            getUpdatedActivitiesFailed(newActivity);
            return;
        }
        setRequestStatus(RequestStatus.Succeeded);
        handleActivityAdded({
            newActivity,
            otherActivitiesWereUpdated: false,
            updatedGroupActivities: updatedActivities
        });
        hideModalAfterDelay();
    }
    async function addActivityAndUpdateOtherOnes() {
        setRequestStatus(RequestStatus.Loading);
        const newActivity = await addActivity();
        if (!newActivity) {
            addActivityFailed();
            return;
        }
        setLastModifiedActivityId(newActivity.id);
        const updateSucceeded = await updateSubsequentActivities(newActivity);
        if (!updateSucceeded) {
            updateSubsequentActivitiesFailed(newActivity);
            return;
        }
        const reorderSucceeded = await reorderActivities();
        if (!reorderSucceeded) {
            reorderFailed(newActivity);
            return;
        }
        setRequestStatus(RequestStatus.Succeeded);
        handleActivityAdded({
            newActivity,
            updatedGroupActivities: null,
            otherActivitiesWereUpdated: true
        });
        hideModalAfterDelay();
    }
    return {
        justAddActivity,
        addActivityAndUpdateOtherOnes,
        isThereAnyClassroomLinkedToOtherActivities
    };
}
