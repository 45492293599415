import React from 'react';
import { Card, StepProgress, TextAvatar } from '@campus/components';
import { ContextType, useAuthority } from '@campus/auth';
import { formatAsPercentage } from '../../utils';
import { Header, HeaderContent, Content, Title, ProgressBarContent, ProgressBarItem, WeightContent, WeightHeader, WeightTitle, WeightPercentage, WeightPercentageText, MessageWithoutPerformanceBox, MessageWithoutPerformance } from './styles';
const DisciplineCard = ({ discipline, small = false }) => {
    const { currentContext } = useAuthority();
    const isTeacher = currentContext?.contextType === ContextType.TEACHER;
    return (React.createElement(Card, { fullHeight: true },
        React.createElement(Header, null,
            React.createElement(HeaderContent, null,
                React.createElement(TextAvatar, { color: discipline.color, text: discipline.name }),
                React.createElement(Title, null, discipline.name)),
            !small && (React.createElement(WeightContent, null, discipline.showPerformance ? (React.createElement("div", null,
                React.createElement(WeightHeader, null,
                    React.createElement(WeightTitle, null, "Meu Desempenho Campus"),
                    React.createElement(WeightPercentage, null, discipline.currentWeight &&
                        discipline.currentCount > 0 &&
                        discipline.currentWeight > 0 ? (formatAsPercentage(discipline.currentWeight)) : (React.createElement(WeightPercentageText, null, "-")))),
                React.createElement(ProgressBarContent, null, discipline.evaluations.map((evaluation, index) => (React.createElement(ProgressBarItem, { "$color": evaluation.color, now: evaluation.weight, key: index })))))) : (React.createElement("div", null,
                React.createElement(MessageWithoutPerformanceBox, null,
                    React.createElement(MessageWithoutPerformance, null, "Componente Curricular sem MDC"))))))),
        React.createElement(Content, null, discipline.engagement.map((engagement, i) => (React.createElement(StepProgress, { key: `${i}${engagement.name}`, title: engagement.name, color: discipline.color, total: engagement.contentCount, current: engagement.currentCount, showStep: !isTeacher }))))));
};
export default DisciplineCard;
