import React from 'react';
import { Container, CreationDate, TeacherName, Content } from './styles';
function formatCreationDate(date) {
    if (!date)
        return '';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}
export const Comment = ({ comment }) => {
    return (React.createElement(Container, null,
        React.createElement(TeacherName, { "$color": comment.user?.color ?? null },
            React.createElement("span", null, comment.user?.name ?? 'Não identificado')),
        comment.createdAt ? (React.createElement(CreationDate, null, formatCreationDate(comment.createdAt))) : null,
        React.createElement(Content, { dangerouslySetInnerHTML: { __html: comment.content } })));
};
