import React from 'react';
import { useLiteraryWorkPageContext } from '../../../../context';
import { useQuestionsTabContext } from '../../context';
import { useFetchQuestion, useHandleQuestionUpdated, useUploadForQuestion } from '../../hooks';
import { commentBoxInfos } from '../../commons/commentBox';
import QuestionPlaceholder, { commonPlaceholders, statusToPlaceholder } from '../QuestionPlaceholder';
import { Container, Question } from './styles';
const ReadOnlyQuestion = () => {
    const { literaryWorkId } = useLiteraryWorkPageContext();
    const { selectedQuestionId } = useQuestionsTabContext();
    const { createUploadInstance } = useUploadForQuestion({
        literaryWorkId,
        questionId: selectedQuestionId ?? ''
    });
    const { data: question, requestStatus, refetchData: refetchQuestionData } = useFetchQuestion({ literaryWorkId, questionId: selectedQuestionId });
    useHandleQuestionUpdated({ refetchQuestionData });
    let placeholder = statusToPlaceholder(requestStatus);
    if (!placeholder && !question) {
        placeholder = commonPlaceholders.questionDataUnavailable;
    }
    if (placeholder) {
        return React.createElement(QuestionPlaceholder, null, placeholder);
    }
    return (React.createElement(Container, null,
        React.createElement(Question, { question: question, hideOpenQuestionBox: true, commentBox: commentBoxInfos, createUploadInstance: createUploadInstance })));
};
export default ReadOnlyQuestion;
