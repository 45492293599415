import React, { useEffect } from 'react';
const PrintablePdf = ({ toPrintPdf = false, printPDF = () => { } }) => {
    useEffect(() => {
        if (toPrintPdf) {
            printPDF(true);
        }
    }, [toPrintPdf, printPDF]);
    return React.createElement(React.Fragment, null);
};
export default PrintablePdf;
