/* eslint-disable unicorn/no-thenable */
import joi from 'joi';
export const questionValidationSchema = joi
    .object({
    items: joi.array().min(1).required().messages({
        'array.min': 'Pelo menos um item deve ser informado na questão'
    }),
    answers: joi
        .when('questionType', {
        is: joi.string().valid('Unique', 'Multiple'),
        then: joi
            .array()
            .required()
            .min(2)
            .custom((answers, helpers) => {
            return answers.some((a) => a.correct)
                ? true
                : helpers.error('answers.correct');
        }, 'correct'),
        otherwise: joi.disallow()
    })
        .messages({
        'array.min': 'Pelo menos duas respostas devem ser informadas na questão',
        'answers.correct': 'Pelo menos uma resposta correta deve ser informada na questão.'
    })
})
    .options({ abortEarly: false, allowUnknown: true });
