export default class AreaSkillManagement {
    areaSkill;
    classroomCount;
    code;
    isOriginal;
    weight;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new AreaSkillManagement();
        instance.areaSkill = json.AreaSkill;
        instance.classroomCount = json.ClassroomCount;
        instance.code = json.Code;
        instance.isOriginal = json.IsOriginal;
        instance.weight = json.Weight;
        return instance;
    }
}
