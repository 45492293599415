import MessageContents from './MessageContents';
class MessageContent {
    headerMessage;
    footerMessage;
    contents;
    static fromJson(data) {
        const messageContent = new MessageContent();
        messageContent.headerMessage = data.HeaderMessage;
        messageContent.footerMessage = data.FooterMessage;
        messageContent.contents = data.Contents?.map((item) => MessageContents.fromJson(item));
        return messageContent;
    }
}
export default MessageContent;
