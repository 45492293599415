import React from 'react';
import TextAreaMention from './components/TextAreaMention';
import { Comment } from './components';
import { Container, NoComments, Area } from './styles';
const Comments = ({ comments, pedagogicalPlanningId }) => {
    return (React.createElement(Area, null,
        React.createElement(Container, null, comments?.length > 0 ? (comments.map((c) => React.createElement(Comment, { key: c.id, comment: c }))) : (React.createElement(NoComments, null, "Nenhum coment\u00E1rio"))),
        React.createElement(TextAreaMention, { pedagogicalPlanningId: pedagogicalPlanningId })));
};
export default Comments;
