import { format } from 'date-fns';
import locale from 'date-fns/locale/pt-BR';
import LiteraryWorkVideoItemUser from './LiteraryWorkVideoItemUser';
function formatDate(dataString) {
    return format(new Date(dataString), `dd \'de' MMMM \'de' yyyy`, {
        locale: locale
    });
}
export default class LiteraryWorkVideoItem {
    id = '';
    url = '';
    title = '';
    order = 0;
    publishDate = '';
    isProcessed = false;
    user = new LiteraryWorkVideoItemUser();
    static fromJson(json) {
        const instance = new LiteraryWorkVideoItem();
        instance.id = json.Id;
        instance.url = json.Url;
        instance.title = json.Title;
        instance.order = json.Order;
        instance.publishDate = formatDate(json.PublishDate);
        instance.isProcessed = json.IsProcessed;
        instance.user = LiteraryWorkVideoItemUser.fromJson(json.User);
        return instance;
    }
}
