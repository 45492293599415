import React, { useEffect, useRef, useState } from 'react';
import { useSkillManagementContext } from '../../../../context';
import { Selector } from './styles';
export const PageSelector = () => {
    const { events } = useSkillManagementContext();
    const [pageIndex, setPageIndex] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [isLoadingContent, setIsLoadingContent] = useState(false);
    const previousPageIndex = useRef(null);
    function onChange(newIndex) {
        if (newIndex === pageIndex)
            return;
        previousPageIndex.current = pageIndex;
        setPageIndex(newIndex);
        setIsLoadingContent(true);
        events.table.pageIndexChanged.emit({ newIndex });
    }
    useEffect(() => {
        return events.table.dataFetched.subscribe((payload) => {
            setIsLoadingContent(false);
            setNumberOfPages(payload.numberOfPages ?? 1);
        });
    }, [events.table.dataFetched]);
    useEffect(() => {
        return events.table.dataFetchFailed.subscribe((payload) => {
            setIsLoadingContent(false);
            if (pageIndex === payload.pageIndex) {
                setPageIndex(previousPageIndex.current);
            }
        });
    }, [events.table.dataFetchFailed, pageIndex]);
    useEffect(() => {
        return events.filters.filtersChanged.subscribe(() => {
            setPageIndex(0);
        });
    }, [events.filters.filtersChanged]);
    return numberOfPages <= 1 ? null : (React.createElement(Selector, { "$loading": isLoadingContent, pageIndex: pageIndex, setPageIndex: onChange, numberOfPages: numberOfPages, hidePreviousAndNextButtonsIfDisabled: true }));
};
