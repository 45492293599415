import format from 'date-fns/format';
import locale from 'date-fns/locale/pt-BR';
class StudentRateList {
    stats;
    daysCount;
    static fromJson(data) {
        const studentRateList = new StudentRateList();
        studentRateList.stats = data.map((student) => ({
            date: format(new Date(student.Date.slice(0, 19)), 'dd/MM'),
            studentLetter: format(new Date(student.Date.slice(0, 19)), 'EEEEE', {
                locale
            }).toUpperCase(),
            rate: student.Rate
        }));
        studentRateList.daysCount = data.length;
        return studentRateList;
    }
}
export default StudentRateList;
