import React from 'react';
import { RequestStatus } from '@campus/commons';
import { useWeeklyPlanningPageContext } from '../../../../../../../../../../../context';
import { Container, Item, Avatar, Name, AllUsersIcon, IconContainer, ScrollableArea, UserIcon } from './styles';
const placeholders = {
    [RequestStatus.Loading]: 'Carregando usuários...',
    [RequestStatus.Failed]: 'Falha ao carregar usuários'
};
const UserMenu = ({ show, partOfTheUsername = '', onOptionClick }) => {
    const commentUsers = useWeeklyPlanningPageContext((store) => store.commonData.commentUsers);
    const placeholder = Object.prototype.hasOwnProperty.call(placeholders, commentUsers.status)
        ? placeholders[commentUsers.status]
        : commentUsers.data?.length > 0
            ? undefined
            : 'Nenhum usuário disponível';
    if (placeholder) {
        return (React.createElement(Container, { "$show": show },
            React.createElement(Name, null, placeholder)));
    }
    function onClick(event) {
        if (!(event.target instanceof Element))
            return;
        const item = event.target.closest('[data-item-value]');
        if (!(item instanceof HTMLDivElement))
            return;
        const value = item.dataset.itemValue;
        if (value === undefined)
            return;
        const user = value ? commentUsers.data.find((u) => u.id === value) : null;
        onOptionClick(user);
    }
    return (React.createElement(Container, { "$show": show },
        React.createElement(ScrollableArea, { onClick: onClick },
            React.createElement(Item, { key: "allUsers", "data-item-value": "" },
                React.createElement(IconContainer, null,
                    React.createElement(AllUsersIcon, null)),
                React.createElement(Name, null, "Todos")),
            (partOfTheUsername
                ? commentUsers.data.filter((u) => {
                    return u.name
                        .toLowerCase()
                        .includes(partOfTheUsername.toLowerCase());
                })
                : commentUsers.data).map((user) => {
                return (React.createElement(Item, { key: user.id, "data-item-value": user.id },
                    user?.imageUrl ? (React.createElement(Avatar, { "$img": user?.imageUrl })) : (React.createElement(IconContainer, null,
                        React.createElement(UserIcon, null))),
                    React.createElement(Name, null, user.name)));
            }))));
};
export default UserMenu;
