import React, { useState } from 'react';
import { List } from '@styled-icons/bootstrap/List';
import Avatar from '../Avatar';
import logoImg from '../../assets/logo.svg';
import { Root, Logo, MenuButton, Drawer } from './styles';
const NavBar = ({ userName, userImage, children }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(Root, null,
            React.createElement(MenuButton, { "aria-label": "Abrir menu", onClick: () => setMenuOpen(!menuOpen) },
                React.createElement(List, { size: 40, color: "white" })),
            React.createElement(Logo, { alt: "Campus", src: logoImg }),
            React.createElement(Avatar, { name: userName, image: userImage })),
        React.createElement(Drawer, { "$open": menuOpen }, children)));
};
export default NavBar;
