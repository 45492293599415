import React from 'react';
import { Classification } from '../../models';
import { Box, DisciplineName, ParentDescription, Parents } from './styles';
import ClassificationSubItem from './components/ClassificationSubItem';
const ClassificationItemTree = ({ onAdd, onRemove, addedItem, classification, forRemoving = false, disabled = false }) => {
    const handleAdd = (subItemId) => {
        const added = { ...classification };
        const itemToAdd = classification.items.find((i) => i.id === subItemId);
        added.items = [...(addedItem?.items ?? []), itemToAdd];
        onAdd(Object.assign(new Classification(), added));
    };
    const handleRemove = (subItemId) => {
        const removed = { ...addedItem };
        removed.items = removed.items.filter((i) => i.id !== subItemId);
        onRemove(Object.assign(new Classification(), removed));
    };
    const isAdded = (subItemId) => {
        return addedItem?.items?.some((item) => item.id === subItemId);
    };
    return (React.createElement(Box, { key: classification.id, role: "listitem" },
        classification.items?.map((i) => (React.createElement(ClassificationSubItem, { key: i.id, subItem: i, disabled: disabled, forRemoving: forRemoving, isAdded: isAdded(i.id), onAdd: handleAdd, onRemove: handleRemove }))),
        React.createElement(Parents, null,
            React.createElement("span", { className: "circle" }),
            React.createElement("div", null,
                React.createElement(DisciplineName, null,
                    classification.discipline.name,
                    " "),
                classification.parents.map((item) => (React.createElement(ParentDescription, { key: item.id },
                    ' ',
                    "\u2192 ",
                    item.description)))))));
};
export default ClassificationItemTree;
