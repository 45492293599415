import ClassUserInfo from './ClassUserInfo';
class UserInfo {
    id;
    name;
    imageUrl;
    class;
    static fromJson(data) {
        const userInfo = new UserInfo();
        userInfo.id = data?.Id;
        userInfo.name = data?.Name;
        userInfo.imageUrl = data?.ImageUrl;
        userInfo.class = ClassUserInfo.fromJson(data?.Class);
        return userInfo;
    }
}
export default UserInfo;
