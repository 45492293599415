const apiDidNotSentTheData = 'a API não retornou os dados necessários';
export const errorMessages = {
    addIsMissingData: `Falha ao adicionar item: ${apiDidNotSentTheData}`,
    addFailed: 'Falha ao adicionar item por uma razão desconhecida',
    addWithInvalidType: 'O item que está tentando ser adicionado é inválido e não será adicionado',
    removeFailed: 'Falha ao remover o item por uma razão desconhecida',
    reorderFailed: 'Falha ao mover o item por uma razão desconhecida',
    updateFailed: 'Falha ao atualizar o item por uma razão desconhecida',
    updateIsMissingData: `Falha ao atualizar o item: ${apiDidNotSentTheData}`
};
