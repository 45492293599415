import React from 'react';
import { ContextType, useAuthority } from '@campus/auth';
import { CampusIcon, OverlayTrigger, Popover, useSideMenu } from '@campus/components';
import { Avatar, HeaderBackground, HeaderContainer, HeaderMobileContainer, VerticalDivider, HeaderMobileContent, Subtitle, Title } from './styles';
import UserPopover from '../UserPopover';
const HeaderMobile = ({ className, title, style, color, background, subtitle, isExpandedHeader, backgroundStyle }) => {
    const { currentContext } = useAuthority();
    const userImage = currentContext?.userImage;
    const isStudent = currentContext?.person?.contextType === ContextType.STUDENT;
    const { setHiden } = useSideMenu();
    return (React.createElement(HeaderMobileContainer, { className: className, style: style },
        React.createElement(HeaderBackground, { "$isStudent": isStudent, "$color": color, "$background": background, "$isExpandedHeader": isExpandedHeader, style: backgroundStyle }),
        React.createElement(HeaderContainer, { "$isStudent": isStudent, "$isExpandedHeader": isExpandedHeader },
            React.createElement("div", { role: "button", onClick: () => setHiden(false) },
                React.createElement(CampusIcon, { name: "menu", color: "#FFFFFF", size: 24 })),
            React.createElement(VerticalDivider, null),
            React.createElement(HeaderMobileContent, null,
                React.createElement("div", null,
                    React.createElement(Title, null, title),
                    subtitle ? React.createElement(Subtitle, null, subtitle) : React.createElement(React.Fragment, null))),
            React.createElement(OverlayTrigger, { trigger: "click", placement: "left", rootClose: true, overlay: React.createElement(Popover, null,
                    React.createElement(UserPopover, null)) },
                React.createElement(Avatar, { url: userImage })))));
};
export default HeaderMobile;
