import { useState, useCallback, useEffect } from 'react';
const useCarousel = (items, itemsPerPage) => {
    const [hasPrevious, setHasPrevious] = useState(false);
    const [hasNext, setHasNext] = useState(true);
    const [itemsPagination, setItemsPagination] = useState([]);
    useEffect(() => {
        if (items) {
            setItemsPagination(items.slice(0, itemsPerPage));
            setHasNext(items.length > itemsPerPage);
        }
    }, [items]);
    useEffect(() => {
        if (itemsPagination.length > 0) {
            setHasPrevious(itemsPagination[0]?.id !== items[0]?.id);
            setHasNext(itemsPagination[itemsPagination.length - 1]?.id !==
                items[items.length - 1]?.id);
        }
    }, [itemsPagination, items]);
    const goBack = useCallback(() => {
        const index = items.indexOf(itemsPagination[0]);
        const hasPrevious = index - itemsPerPage >= 0;
        const startIndex = hasPrevious ? index - itemsPerPage : 0;
        setItemsPagination(items.slice(startIndex, index));
    }, [items, itemsPagination, itemsPerPage]);
    const goNext = useCallback(() => {
        const index = items.indexOf(itemsPagination[itemsPagination.length - 1]);
        const endIndex = Math.min(index + itemsPerPage, items.length);
        setItemsPagination(items.slice(index + 1, endIndex + 1));
    }, [items, itemsPagination, itemsPerPage]);
    return {
        goBack,
        goNext,
        hasPrevious,
        hasNext,
        itemsPagination
    };
};
export default useCarousel;
