import React from 'react';
import { CampusIcon, OverlayTrigger } from '@campus/components';
import { readIconState } from '../../models/ContentState';
import { CustomTooltipWrapper, DivTitle, DivStateDate, Line, StatusDate, StatusName, StatusTitle } from './styles';
const StatusIcon = ({ status, statusName, statusTitle, stateDate, attention }) => {
    const stateIcon = readIconState(status);
    const renderOverlay = (props) => (React.createElement(CustomTooltipWrapper, { id: "tooltip-state", ...props },
        React.createElement(DivTitle, null,
            renderIcon(),
            React.createElement(StatusTitle, null, statusTitle)),
        statusTitle !== statusName ? (React.createElement(StatusName, null, statusName)) : (React.createElement(React.Fragment, null)),
        !!stateDate && (React.createElement(React.Fragment, null,
            React.createElement(Line, null),
            React.createElement(DivStateDate, null,
                React.createElement(StatusName, null, "Enviada em:"),
                React.createElement(StatusDate, null, stateDate))))));
    const renderIcon = () => (React.createElement(CampusIcon, { size: 20, color: stateIcon.color, name: stateIcon.name, attention: attention }));
    return !!statusName || !!statusTitle ? (React.createElement(OverlayTrigger, { placement: "top", overlay: renderOverlay },
        React.createElement("div", { id: "tooltip-state" }, renderIcon()))) : (renderIcon());
};
export default StatusIcon;
