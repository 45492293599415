import { Api } from '@campus/auth';
import { Response } from '../models';
export const signalUseOfTheSystem = async (classId) => {
    try {
        const response = await Api.INSTANCE.getApi().put(`/time/${classId}`);
        return Response.fromData(null, response.status);
    }
    catch (error) {
        return Response.error(error);
    }
};
