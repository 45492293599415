import styled from 'styled-components';
import { Modal as BaseModal } from '@campus/components';
export const Modal = styled(BaseModal) `
  .modal-dialog {
    top: 168px;

    margin-block: 0;
  }

  .modal-content {
    padding: 14px;

    margin: 0 auto;

    width: fit-content;

    display: flex;
    flex-direction: column;
  }
`;
export const Title = styled.h1 `
  margin-block: 0;

  font-size: 18px;
  font-weight: 700;
  line-height: 130%;
  color: ${({ theme }) => theme.colors.gray1};
`;
export const ActionDescription = styled.p `
  margin-block: 16px 0;

  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
  color: ${({ theme }) => theme.colors.gray3};
`;
export const Buttons = styled.div `
  margin-block: 22px 0;

  display: flex;
  row-gap: 10px;
  flex-direction: column;

  > button {
    border: none;

    height: 32px;

    padding: 10px 12px;

    border-radius: 6px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.white};

    &:hover {
      opacity: 0.9;
    }
  }

  > button:nth-child(1) {
    background: ${({ theme }) => theme.colors.error};
  }

  > button:nth-child(2) {
    background: ${({ theme }) => theme.colors.yellow1};
  }

  > button:nth-child(3) {
    background: transparent;
    color: ${({ theme }) => theme.colors.gray500};
    border: 1px solid ${({ theme }) => theme.colors.gray200};
  }
`;
