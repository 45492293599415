import React from 'react';
import { RequestStatus } from '@campus/commons';
import { useSkillManagementContext } from '../../../../context';
import { NoTableContent as NoContent } from '../../../NoTableContent';
import { NoCurriculumComponentsSelectedIcon, NoSkillAddedIcon } from './styles';
export const NoTableContent = ({ requestStatus }) => {
    let text = null;
    let icon = null;
    const { getFilters } = useSkillManagementContext();
    const filters = getFilters();
    if (!filters.segmentId ||
        !filters.gradeId ||
        !filters.disciplineId
    // || !filters.classId
    ) {
        icon = React.createElement(NoCurriculumComponentsSelectedIcon, null);
        text = 'Nenhum componente curricular selecionado';
        // eslint-disable-next-line unicorn/prefer-switch
    }
    else if (requestStatus === RequestStatus.Loading) {
        text = 'Carregando conteúdo...';
    }
    else if (requestStatus === RequestStatus.Failed) {
        text = 'Falha ao carregar conteúdo';
    }
    else if (requestStatus === RequestStatus.Succeeded) {
        text = 'Sem habilidades adicionadas';
        icon = React.createElement(NoSkillAddedIcon, null);
    }
    if (!text)
        return null;
    return React.createElement(NoContent, { text: text, icon: icon });
};
