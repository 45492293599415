import React, { useEffect, useRef, useState } from 'react';
import { toast } from '@campus/components';
import { RequestStatus } from '@campus/commons';
import PedagogicalPlanningGroup from '../../../../../../../../../../../../../models/PedagogicalPlanningGroup';
import { createComment } from '../../../../../../../../../../../../../services/pedagogicalPlanning';
import { useGroupContext } from '../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/context';
import { useWeeklyPlanningPageContext } from '../../../../../../../../../../../context';
import UserMenu from '../UserMenu';
import { StyledWrapper, StyledTextArea, StyledButton, Container } from './styles';
import { times } from '../../../../../../../../../../../../common/constants';
const buttonContent = {
    [RequestStatus.NotStarted]: 'Enviar',
    [RequestStatus.Loading]: 'Enviando...',
    [RequestStatus.Failed]: 'Falha ao enviar',
    [RequestStatus.Succeeded]: 'Enviado!'
};
const TextAreaMention = ({ pedagogicalPlanningId }) => {
    const [value, setValue] = useState('');
    const [userName, setUserName] = useState('');
    const [showMenu, setShowMenu] = useState(false);
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    const atIndexRef = useRef(-1);
    const mentionedUsers = useRef(new Set());
    const textareaRef = useRef(null);
    const selectedSegment = useWeeklyPlanningPageContext((store) => store.curriculumComponents.selectedSegment);
    const selectedGrade = useWeeklyPlanningPageContext((store) => store.curriculumComponents.selectedGrade);
    const selectedDisciplines = useWeeklyPlanningPageContext((store) => store.curriculumComponents.selectedDisciplines);
    const updateGroup = useWeeklyPlanningPageContext((store) => store.mainTable.advancedViewMode.operations.updateGroup);
    const group = useGroupContext((store) => store._groupFromNonFilteredContent);
    useEffect(() => {
        textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
    }, [value]);
    function getIndexOfSpaceAfterAt() {
        let indexOfSpaceAfterAt = -1;
        if (atIndexRef.current !== -1) {
            const textarea = textareaRef.current;
            indexOfSpaceAfterAt = textarea.value.indexOf(' ', atIndexRef.current + 1);
        }
        return indexOfSpaceAfterAt;
    }
    function fillUserName() {
        const textarea = textareaRef.current;
        const atIndex = atIndexRef.current;
        const spaceIndex = textarea.value.indexOf(' ', atIndex + 1);
        const end = spaceIndex === -1 ? undefined : spaceIndex;
        setUserName(textarea.value.slice(atIndex + 1, end));
    }
    function handleCursorBounds() {
        const textarea = textareaRef.current;
        let indexOfAtBeforeCursor = -1;
        for (let i = textarea.selectionStart - 1; i >= 0; --i) {
            if (textarea.value[i] === '@') {
                indexOfAtBeforeCursor = i;
                break;
            }
            else if (textarea.value[i] === ' ') {
                break;
            }
        }
        if (indexOfAtBeforeCursor === -1) {
            setShowMenu(false);
        }
        else {
            atIndexRef.current = indexOfAtBeforeCursor;
            setShowMenu(true);
            fillUserName();
        }
    }
    function onKeyUp(event) {
        const textarea = textareaRef.current;
        const cursorIndex = textarea.selectionStart;
        const indexOfSpaceAfterAt = getIndexOfSpaceAfterAt();
        if (!textarea.value) {
            setShowMenu(false);
        }
        else if (cursorIndex > 0 && textarea.value[cursorIndex - 1] === '@') {
            atIndexRef.current = cursorIndex - 1;
            setShowMenu(true);
            fillUserName();
        }
        else if (showMenu && event.key === ' ') {
            setShowMenu(false);
        }
        else if (showMenu &&
            atIndexRef.current !== -1 &&
            cursorIndex > atIndexRef.current &&
            (indexOfSpaceAfterAt === -1 || cursorIndex < indexOfSpaceAfterAt)) {
            fillUserName();
        }
        else {
            handleCursorBounds();
        }
    }
    return (React.createElement(Container, null,
        React.createElement(UserMenu, { show: showMenu, partOfTheUsername: userName, onOptionClick: (user) => {
                mentionedUsers.current.add(user ?? null);
                setValue((currentValue) => {
                    const replacement = user ? user.name : 'Todos';
                    const beforeAt = currentValue.slice(0, atIndexRef.current);
                    const indexOfSpaceAfterAt = getIndexOfSpaceAfterAt();
                    let newValue = beforeAt + '@' + replacement;
                    if (indexOfSpaceAfterAt !== -1) {
                        const afterSpace = currentValue.slice(indexOfSpaceAfterAt, currentValue.length);
                        newValue += ' ' + afterSpace;
                    }
                    return newValue + ' ';
                });
                textareaRef.current.focus();
                setShowMenu(false);
            } }),
        React.createElement(StyledWrapper, null,
            React.createElement(StyledTextArea, { ref: textareaRef, disabled: selectedDisciplines.length > 1, value: value ?? '', placeholder: selectedDisciplines.length > 1
                    ? 'No momento só é possível adicionar comentários para uma única disciplina'
                    : 'Adicione um comentário...', onKeyUp: onKeyUp, onClick: handleCursorBounds, onChange: (event) => {
                    setValue(event.target.value);
                    for (const user of mentionedUsers.current) {
                        if (!user)
                            continue;
                        if (!textareaRef.current.value.includes(user.name)) {
                            mentionedUsers.current.delete(user);
                        }
                    }
                    if (mentionedUsers.current.has(null) &&
                        !event.target.value.includes('@Todos')) {
                        mentionedUsers.current.delete(null);
                    }
                } }),
            React.createElement(StyledButton, { "$status": requestStatus, disabled: requestStatus !== RequestStatus.NotStarted ||
                    selectedDisciplines.length > 1, onClick: async () => {
                    setRequestStatus(RequestStatus.Loading);
                    let allUsersHaveBeenTagged = false;
                    let result = value.replaceAll('@Todos', '@{all}');
                    for (const user of mentionedUsers.current) {
                        if (user) {
                            result = result.replaceAll(`@${user.name}`, `@{${user.id}}`);
                        }
                        else {
                            allUsersHaveBeenTagged = true;
                        }
                    }
                    result = result.trim();
                    const mentionedUserIds = Array.from(mentionedUsers.current)
                        .filter((u) => !!u)
                        .map((u) => u.id);
                    const response = await createComment({
                        comment: result,
                        segmentId: selectedSegment.id,
                        gradeId: selectedGrade.id,
                        disciplineIds: selectedDisciplines?.map((d) => d.id) ?? [],
                        pedagogicalPlanningId: pedagogicalPlanningId,
                        mentionedUserIds,
                        allUsersHaveBeenTagged
                    });
                    if (response.data) {
                        setValue('');
                        textareaRef.current.style.height = '36px';
                        const newGroup = new PedagogicalPlanningGroup();
                        newGroup.comments = group.comments;
                        newGroup.didacticSequence = group.didacticSequence;
                        newGroup.number = group.number;
                        newGroup.plannings = group.plannings;
                        newGroup.comments.push(response.data);
                        updateGroup(newGroup);
                        setRequestStatus(RequestStatus.Succeeded);
                    }
                    else {
                        toast.error('Falha ao criar comentário');
                        setRequestStatus(RequestStatus.Failed);
                    }
                    setTimeout(() => {
                        setRequestStatus(RequestStatus.NotStarted);
                    }, times.toResetARequestStatus);
                } }, buttonContent[requestStatus]))));
};
export default TextAreaMention;
