import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
import { commonStyles } from '../TableCommons';
export const Column = styled.td `
  ${commonStyles}
`;
export const Content = styled.div `
  padding: 10px;
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';

    opacity: 0.2;

    display: block;

    position: absolute;
    inset: 0;

    background-color: ${({ theme, $isActive }) => $isActive ? theme.colors.green3 : theme.colors.yellow1};
  }
`;
export const ShieldCheckIcon = styled(CampusIcon).attrs({
    name: 'shieldCheck'
}) `
  display: block;
  width: 20px;
  height: 20px;
  color: ${(props) => props.theme.colors.green3};
`;
export const ShieldQuestionMarkIcon = styled(CampusIcon).attrs({
    name: 'shieldQuestionMark'
}) `
  display: block;
  width: 20px;
  height: 20px;
  color: ${(props) => props.theme.colors.yellow1};
`;
