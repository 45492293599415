import { Api } from '@campus/auth';
import { Response, UploadResult } from '@campus/commons';
import { endpointBasePath } from './common';
const apiEndpoints = {
    answerQuestion({ literaryWorkId, studentId }) {
        return `${endpointBasePath}/${literaryWorkId}/answer/${studentId}`;
    },
    upload(params) {
        const { literaryWorkId, questionId, studentId } = params;
        return `${endpointBasePath}/${literaryWorkId}/question/${questionId}/answer/upload/${studentId}`;
    }
};
export async function answerQuestion(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = apiEndpoints.answerQuestion(params);
        const response = await axiosInstance.post(endpoint, {
            QuestionId: params.questionId,
            Answer: params.questionData.convertToAnswer().toJson()
        });
        return Response.fromData(true, response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
export async function uploadForQuestionAnswer(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = apiEndpoints.upload(params);
        const response = await axiosInstance.put(endpoint, {
            Provider: params.provider,
            FileName: params.fileName,
            FileSize: params.fileSize
        });
        return Response.fromData(UploadResult.fromJson(response.data, params.provider), response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
