import React from 'react';
import { NoTableContent } from './components/NoTableContent';
import { TableContent } from './components/TableContent';
import { PageSelector } from './components/PageSelector';
import { Container, TableAndNoContent } from './styles';
import { useFetchTableContent } from './hooks';
export const Table = () => {
    const { data, requestStatus } = useFetchTableContent();
    return (React.createElement(Container, null,
        React.createElement(TableAndNoContent, null, data?.length > 0 ? (React.createElement(React.Fragment, null,
            React.createElement(TableContent, { content: data }),
            React.createElement(PageSelector, null))) : (React.createElement(NoTableContent, { requestStatus: requestStatus })))));
};
