import React from 'react';
import { useWeeklyPlanningPageContext } from '../../../../../../../../../../../../../context';
import { useGroupContext } from '../../../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/context';
import { useControlDeletionModal } from './hooks/useControlDeletionModal';
import { useDisable, useDuplicateActivity, useMoveActivity } from './hooks';
import { StyledPopup } from './styles';
const OptionsPopup = (props) => {
    const { duplicateActivity } = useDuplicateActivity();
    const { moveActivityDown, moveActivityUp } = useMoveActivity();
    const updateActivities = useGroupContext((store) => store.updateActivities);
    const updateActivity = useGroupContext((store) => store.sequenceOperations.update);
    const { createModal } = useControlDeletionModal();
    const availableClassrooms = useWeeklyPlanningPageContext((store) => store.commonData.availableClassrooms);
    const selectedDisciplines = useWeeklyPlanningPageContext((store) => store.curriculumComponents.selectedDisciplines);
    const refetchContent = useWeeklyPlanningPageContext((store) => store.mainTable.advancedViewMode.refetchContent);
    const disable = useDisable({
        planning: props.planning,
        sequence: props.sequence
    });
    function onRemoveOptionClick() {
        return new Promise((resolve, reject) => {
            if (disable.remove) {
                reject();
                return;
            }
            createModal(props.sequence, props.planning);
            resolve();
        });
    }
    function onDuplicateOptionClick() {
        return new Promise((resolve, reject) => {
            if (disable.duplicate) {
                reject();
                return;
            }
            duplicateActivity(props.sequence, props.sequenceIndex, props.planning)
                .then(resolve)
                .catch(reject);
        });
    }
    function onMoveUpOptionClick() {
        return new Promise((resolve, reject) => {
            if (disable.move) {
                reject();
                return;
            }
            moveActivityUp(props.sequence).then(resolve).catch(reject);
        });
    }
    function onMoveDownOptionClick() {
        return new Promise((resolve, reject) => {
            if (disable.move) {
                reject();
                return;
            }
            moveActivityDown(props.sequence).then(resolve).catch(reject);
        });
    }
    return (React.createElement(StyledPopup, { canBeDisplayed: props.canBeDisplayed, planning: props.planning, sequence: props.sequence, sequenceIndex: props.sequenceIndex, handleExternalClick: props.handleExternalClick, disciplines: selectedDisciplines, classroomGroups: availableClassrooms, handleRemoveOptionClick: onRemoveOptionClick, handleMoveUpOptionClick: onMoveUpOptionClick, handleMoveDownOptionClick: onMoveDownOptionClick, handleDuplicateOptionClick: onDuplicateOptionClick, disableRemove: disable.remove, disableEdit: disable.edit, disableMoveUp: disable.move, disableMoveDown: disable.move, disableDuplicate: disable.duplicate, handleActivityUpdated: (params) => {
            if (params.otherActivitiesWereUpdated) {
                refetchContent?.();
            }
            else if (params.updatedGroupActivities) {
                updateActivities(params.updatedGroupActivities);
            }
            else {
                updateActivity(params.updatedActivity);
            }
        } }));
};
export default OptionsPopup;
