import React, { useState } from 'react';
import { RequestStatus } from '@campus/commons';
import { useWeeklyPlanningPageContext } from '../../../../../../../../../../../../../context';
import { ClassroomActionsModal } from '../../../../../../../../../../../../../../common/components/Modals/ClassroomActionsModal';
import { useActions } from './hooks/useActions';
import { useOptionsManager } from './hooks/useOptionsManager';
import { useStatusAndInfos } from './hooks/useStatusAndInfos';
import { useSelectedClassroom } from './hooks/useSelectedClassroom';
import { getFormattedClassroomDate, getText, shouldBoxBeDisabled } from './helpers';
import { StyledWrapper, StyledMenu, StyledBox, StyledText, StyledBoxAndClassDateWrapper, StyledClassDate, StyledArrowIcon } from './styles';
const ClassBox = (props) => {
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    const [showMenu, setShowMenu] = useState(false);
    const [classroomActionsModal, setClassroomActionsModal] = useState({ show: false });
    const availableClassrooms = useWeeklyPlanningPageContext((store) => store.commonData.availableClassrooms.data);
    const userPermissions = useWeeklyPlanningPageContext((store) => store.commonData.userPermissions.data);
    const hideMenu = () => setShowMenu(false);
    function hideClassroomActionsModal() {
        setClassroomActionsModal({ show: false });
    }
    const { getInfos } = useStatusAndInfos();
    const actions = useActions({
        hideMenu,
        setRequestStatus,
        activity: props.sequence
    });
    const selectedClassroom = useSelectedClassroom({
        sequence: props.sequence
    });
    const { selectedOption, options } = useOptionsManager({
        selectedClassroom,
        classroomGroups: availableClassrooms
    });
    const boxInfos = getInfos(requestStatus);
    const text = getText(boxInfos, selectedOption);
    const date = getFormattedClassroomDate(boxInfos, selectedClassroom);
    const disabled = shouldBoxBeDisabled(boxInfos, props.planning, userPermissions);
    async function onMenuItemClick(option) {
        const newClassroomNumber = Number(option.value);
        const showModal = await actions.isThereAnyClassroomLinkedToOtherActivities({
            newClassroomNumber,
            activity: props.sequence,
            pedagogicalPlanningId: props.planning.id
        });
        if (showModal) {
            setClassroomActionsModal({ show: true, newClassroomNumber });
        }
        else {
            await actions.justUpdateClassrooms(newClassroomNumber);
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledWrapper, null,
            React.createElement(StyledBoxAndClassDateWrapper, null,
                React.createElement(StyledBox, { "$color": boxInfos?.color, "$disabled": disabled, onClick: disabled ? null : () => setShowMenu(true) },
                    React.createElement(StyledText, { "$color": boxInfos?.color }, text),
                    React.createElement(StyledArrowIcon, { "$color": boxInfos?.color })),
                date ? React.createElement(StyledClassDate, null, date) : null),
            React.createElement(StyledMenu, { options: options, canBeDisplayed: showMenu, selectedOption: selectedOption, handleExternalClick: hideMenu, handleOptionClicked: onMenuItemClick })),
        React.createElement(ClassroomActionsModal, { updatingActivityVersion: true, show: classroomActionsModal.show, hide: hideClassroomActionsModal, onCancel: hideClassroomActionsModal, onJustAddActivity: () => {
                const newNumber = classroomActionsModal.newClassroomNumber;
                return actions.justUpdateClassrooms(newNumber);
            }, onAddActivityAndUpdateOtherOnes: () => {
                const newNumber = classroomActionsModal.newClassroomNumber;
                return actions.updateClassroomsAndSubsequentActivities(newNumber);
            }, sequenceId: props.sequence.id })));
};
export default ClassBox;
