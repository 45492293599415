import { generateColor } from '@campus/commons';
export class User {
    id;
    name;
    color;
    static generateColor(id) {
        const now = new Date();
        const seed = `${id}${now.getDate()}${now.getMonth()}`;
        return generateColor(seed);
    }
    static fromJson(json) {
        if (!json)
            return null;
        const user = new User();
        user.id = json['Id'];
        user.name = json['Name'];
        user.color = User.generateColor(user.id);
        return user;
    }
}
export default class Comment {
    id;
    content;
    user;
    createdAt;
    static fromJson(json) {
        if (!json)
            return null;
        const comment = new Comment();
        comment.id = json['Id'];
        comment.content = json['Content'];
        comment.user = json['User'] ? User.fromJson(json['User']) : null;
        comment.createdAt = json['CreatedAt'] ? new Date(json['CreatedAt']) : null;
        return comment;
    }
}
