import styled from 'styled-components';
export const Container = styled.div `
  margin-top: 35px;

  padding-bottom: 26px;

  border-bottom: 1px solid ${(props) => props.theme.colors.gray5};
`;
export const Title = styled.h2 `
  margin-bottom: 0;

  font-size: 24px;
  font-style: normal;
  font-weight: 700;

  line-height: 113.2%;

  color: ${(props) => props.theme.colors.gray2};
`;
export const Description = styled.p `
  margin-top: 7px;
  margin-bottom: 0;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;

  line-height: 120%;

  color: ${(props) => props.theme.colors.gray600};
`;
