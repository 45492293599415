import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;
export const VestibularBox = styled.div `
  padding: 2px 4px;

  border-radius: 2px;

  background: ${(props) => props.theme.colors.CSA.blue}1A;
`;
export const VestibularName = styled.p `
  margin-bottom: 0;

  font-size: 10px;
  font-weight: 800;

  color: ${(props) => props.theme.colors.CSA.blue};
`;
