import { Api } from '@campus/auth';
import { stringify } from 'qs';
import { Response } from '@campus/commons';
import ClassroomGroup from '../models/ClassroomGroup';
import ClassroomReportRelatedInformation from '../models/ClassroomReportRelatedInformation';
import Teacher from '../models/Teacher';
import { handleApiCallError } from './common';
import ClassroomAlert from '../models/ClassroomAlert';
const baseEndpointsPath = '/classroom';
const endpoints = {
    get: {
        classrooms: baseEndpointsPath + '/available',
        reportInformation: baseEndpointsPath + '/classroominfo',
        classroomAlert: baseEndpointsPath + '/alert'
    }
};
export async function getAvailableClassrooms(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(endpoints.get.classrooms, {
            signal: params?.abortSignal,
            params: {
                MaxResultCount: 1000,
                GradeId: params.gradeId,
                TeacherId: params.teacherId,
                DisciplineId: params.disciplineId
            }
        });
        const classrooms = response.data?.map((item) => ClassroomGroup.fromJson(item)) ??
            [];
        return Response.fromData(classrooms, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function getReportRelatedInformation(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(endpoints.get.reportInformation, {
            signal: params?.abortSignal,
            params: {
                GradeId: params.gradeId,
                SegmentId: params.segmentId,
                DisciplineId: params.disciplineId
            }
        });
        const model = ClassroomReportRelatedInformation.fromJson(response.data);
        return Response.fromData(model, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function getTeachersByClassInClassroom(classId, disciplineId) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(`${baseEndpointsPath}/class/${classId}/teachers`, {
            params: { disciplineId }
        });
        const teachers = response.data?.map(Teacher.fromJson) ?? [];
        return Response.fromData(teachers, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function getClasroomAlert(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(`${baseEndpointsPath}/alert`, {
            params: {
                disciplineIds: params.disciplines.map((value) => value.id),
                segmentId: params.segment.id,
                gradeId: params.grade.id
            },
            paramsSerializer: (p) => stringify(p, { arrayFormat: 'repeat' })
        });
        const classroomAlert = ClassroomAlert.fromJson(response.data);
        return Response.fromData(classroomAlert, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
