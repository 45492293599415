import React from 'react';
import { Line, StyledCancelButton, StyledDeleteButton, StyledModal } from './styles';
const ConfirmationModal = ({ title, description, show, onHide, onSubmit, variant = 'danger', labelCancel = 'Cancelar', labelConfirm = 'Deletar', doNotHideModalOnSubmit, disableConfirmButton }) => (React.createElement(StyledModal, { show: show, onHide: onHide },
    React.createElement(StyledModal.Header, { closeButton: true },
        React.createElement(StyledModal.Title, null, title)),
    React.createElement(Line, null),
    React.createElement(StyledModal.Body, null, description),
    React.createElement(Line, null),
    React.createElement(StyledModal.Footer, null,
        React.createElement(StyledCancelButton, { variant: "light", onClick: onHide }, labelCancel),
        React.createElement(StyledDeleteButton, { variant: variant, disabled: disableConfirmButton, onClick: () => {
                onSubmit();
                if (!doNotHideModalOnSubmit) {
                    onHide();
                }
            } }, labelConfirm))));
export default ConfirmationModal;
