import React from 'react';
import { VideoWrite } from '@campus/content-items';
import { toast } from '@campus/components';
import { useLiteraryWorkPageContext } from '../../../../context';
import { useUploadForLiteraryWork } from '../../../../hooks';
import { VideoItem } from './types';
import { ContainerUpload } from './styles';
const videoItem = new VideoItem();
const VideoUpload = ({ addVideoToList }) => {
    const { literaryWorkId } = useLiteraryWorkPageContext();
    const { createUploadInstance } = useUploadForLiteraryWork({
        literaryWorkId
    });
    async function onSave(contentItem) {
        if (!contentItem.data) {
            toast.error('Não foi possível obter os dados do vídeo');
            return;
        }
        const url = contentItem.data.url;
        if (!url) {
            toast.error('Não foi possível obter a URL do vídeo');
            return;
        }
        const title = contentItem.data.fileName ?? 'Sem Título';
        const titleFormated = title.replace(/\.[^.]*$/, '');
        await addVideoToList(url, titleFormated);
    }
    return (React.createElement(ContainerUpload, null,
        React.createElement(VideoWrite, { contentItem: videoItem, createUploadInstance: createUploadInstance, onSave: onSave, resetProgressIfUploadSucceed: true })));
};
export default VideoUpload;
