import React from 'react';
import { useWeeklyPlanningPageContext } from '../../../../../../../../../../../context';
import { useGroupContext } from '../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/context';
import BaseButton from '../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/DidacticSequenceRelated/components/AddActivityButton';
const AddActivityButton = (props) => {
    const updateActivities = useGroupContext((store) => store.updateActivities);
    const addActivity = useGroupContext((store) => store.sequenceOperations.add);
    const { availableClassrooms, selectedDisciplines, userPermissions, refetchContent } = useWeeklyPlanningPageContext((store) => ({
        userPermissions: store.commonData.userPermissions.data,
        availableClassrooms: store.commonData.availableClassrooms,
        selectedDisciplines: store.curriculumComponents.selectedDisciplines,
        refetchContent: store.mainTable.advancedViewMode.refetchContent
    }));
    const disabled = !userPermissions?.userCan.updateTheTeacherColumns();
    return (React.createElement(BaseButton, { disabled: disabled, planning: props.planning, newActivityIsUnforeseen: false, disciplines: selectedDisciplines, classroomGroups: availableClassrooms, handleActivityAdded: (params) => {
            if (params.otherActivitiesWereUpdated) {
                refetchContent?.();
            }
            else if (params.updatedGroupActivities) {
                updateActivities(params.updatedGroupActivities);
            }
            else {
                addActivity(params.newActivity);
            }
        } }));
};
export default AddActivityButton;
