import React, { useEffect, useState } from 'react';
import { useStudentId } from '@campus/commons';
import { ContentItem } from '@campus/content-items';
import { useLiteraryWorkPageContext } from '../../../../context';
import { useQuestionsTabContext } from '../../context';
import { useFetchQuestionItemData, useHandleQuestionUpdated, useUploadForQuestionAnswer } from '../../hooks';
import { commentBoxInfos } from '../../commons/commentBox';
import AnswerQuestionButton from '../AnswerQuestionButton';
import QuestionPlaceholder, { commonPlaceholders, statusToPlaceholder } from '../QuestionPlaceholder';
import { Container, Question, OpenQuestionTitle, AnswerQuestionButtonContainer } from './styles';
const QuestionThatCanBeAnswered = () => {
    const [questionWasAnswered, setQuestionWasAnswered] = useState(false);
    const [contentItem, setContentItem] = useState();
    const studentId = useStudentId();
    const { literaryWorkId } = useLiteraryWorkPageContext();
    const { selectedQuestionId } = useQuestionsTabContext();
    const { createUploadInstance } = useUploadForQuestionAnswer({
        literaryWorkId,
        studentId,
        questionId: selectedQuestionId ?? ''
    });
    const { data, requestStatus, refetchData } = useFetchQuestionItemData({
        literaryWorkId,
        studentId,
        questionId: selectedQuestionId
    });
    useHandleQuestionUpdated({ refetchQuestionData: refetchData });
    useEffect(() => {
        setContentItem(data?.contentItem);
        setQuestionWasAnswered(data?.questionWasAnswered ?? false);
    }, [data?.contentItem, data?.questionWasAnswered]);
    let placeholder = statusToPlaceholder(requestStatus);
    if (!placeholder && (!data || !data.contentItem)) {
        placeholder = commonPlaceholders.questionDataUnavailable;
    }
    if (placeholder) {
        return React.createElement(QuestionPlaceholder, null, placeholder);
    }
    return (React.createElement(Container, null,
        React.createElement(Question, { className: questionWasAnswered ? 'answered' : '', answerHerePlaceholder: "Escreva aqui sua resposta", hideScore: true, contentItem: contentItem, answered: questionWasAnswered, readOnly: questionWasAnswered, commentBox: commentBoxInfos, createUploadInstance: createUploadInstance, openQuestionTitle: React.createElement(OpenQuestionTitle, null, "Sua Resposta"), onChange: (updatedItem) => {
                setContentItem(() => {
                    const newItem = new ContentItem();
                    return Object.assign(newItem, updatedItem);
                });
            } }),
        questionWasAnswered ? null : (React.createElement(AnswerQuestionButtonContainer, null,
            React.createElement(AnswerQuestionButton, { contentItem: contentItem, handleQuestionAnsweredSuccessfully: () => {
                    setQuestionWasAnswered(true);
                    refetchData();
                } })))));
};
export default QuestionThatCanBeAnswered;
