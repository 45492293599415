import React from 'react';
import Col from './components/Column';
import { useControlLastColumnRightBorder } from './hooks/useControlLastColumnRightBorder';
import { StyledDivWrapper, StyledTrWrapper } from './styles';
const Row = ({ asTr, allColumns, columnGroup, getContainerElement }) => {
    const { removeBorder } = useControlLastColumnRightBorder({
        getContainerElement
    });
    const rowProps = columnGroup.getHeaderGroupProps();
    if (asTr) {
        rowProps.style.display = 'table-row';
    }
    function renderColumns() {
        const columns = columnGroup.headers;
        return columns.map((column, index) => {
            const isTheLastColumn = index === columns.length - 1;
            return (React.createElement(Col, { key: column.id, asTh: asTr, columnInfos: column, allColumns: allColumns, title: allColumns[index]?.title ?? null, removeRightBorder: isTheLastColumn && removeBorder }));
        });
    }
    return asTr ? (React.createElement(StyledTrWrapper, { ...rowProps }, renderColumns())) : (React.createElement(StyledDivWrapper, { ...rowProps }, renderColumns()));
};
export default Row;
