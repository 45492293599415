import { Api } from '@campus/auth';
import { Response } from '@campus/commons';
import { LiteraryWorkPresentationItem } from '../models';
import { endpointBasePath } from './common';
const endpointResourceName = 'presentationItem';
const apiEndpoints = {
    get: {
        byId({ literaryWorkId, presentationItemId }) {
            return `${endpointBasePath}/${literaryWorkId}/${endpointResourceName}/${presentationItemId}`;
        },
        all({ literaryWorkId }) {
            return `${endpointBasePath}/${literaryWorkId}/${endpointResourceName}`;
        }
    },
    add({ literaryWorkId }) {
        return `${endpointBasePath}/${literaryWorkId}/${endpointResourceName}`;
    },
    delete({ literaryWorkId, presentationItemId }) {
        return `${endpointBasePath}/${literaryWorkId}/${endpointResourceName}/${presentationItemId}`;
    },
    update: {
        item({ literaryWorkId, presentationItemId }) {
            return `${endpointBasePath}/${literaryWorkId}/${endpointResourceName}/${presentationItemId}`;
        },
        index(params) {
            const { literaryWorkId, presentationItemId } = params;
            return `${endpointBasePath}/${literaryWorkId}/${endpointResourceName}/${presentationItemId}/index`;
        }
    }
};
export async function getPresentationItems(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = apiEndpoints.get.all(params);
        const response = await axiosInstance.get(endpoint);
        const items = Array.isArray(response.data)
            ? response.data.map((item) => LiteraryWorkPresentationItem.fromJson(item))
            : [];
        return Response.fromData(items, response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
export async function getPresentationItem(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = apiEndpoints.get.byId(params);
        const response = await axiosInstance.get(endpoint);
        const item = LiteraryWorkPresentationItem.fromJson(response.data);
        return Response.fromData(item, response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
export async function addPresentationItem(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = apiEndpoints.add(params);
        const response = await axiosInstance.post(endpoint, {
            Type: params.presentationItemType
        });
        const item = LiteraryWorkPresentationItem.fromJson(response.data);
        return Response.fromData(item, response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
export async function deletePresentationItem(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = apiEndpoints.delete(params);
        const response = await axiosInstance.delete(endpoint);
        return Response.fromData(true, response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
export async function updatePresentationItem(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = apiEndpoints.update.item(params);
        const response = await axiosInstance.put(endpoint, {
            Content: params.newContent?.toJson()
        });
        const item = LiteraryWorkPresentationItem.fromJson(response.data);
        return Response.fromData(item, response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
export async function updatePresentationItemIndex(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = apiEndpoints.update.index(params);
        const response = await axiosInstance.put(endpoint, {
            Index: params.newIndex
        });
        return Response.fromData(true, response.status);
    }
    catch (error) {
        throw Response.error(error);
    }
}
