import format from 'date-fns/format';
import Segment from './Segment';
import User from './User';
class StudentDetail {
    age;
    segment;
    user;
    birthday;
    imageUrl;
    id;
    birthdayFormated;
    static fromJson(data) {
        const studentClassDetail = new StudentDetail();
        studentClassDetail.age = data.Age;
        studentClassDetail.segment = Segment.fromJson(data.Segment);
        studentClassDetail.user = User.fromJson(data.User);
        studentClassDetail.birthday = data.Birthday;
        studentClassDetail.imageUrl = data.ImageUrl;
        studentClassDetail.id = data.Id;
        studentClassDetail.birthdayFormated = format(new Date(data.Birthday), 'dd/MM/yyyy');
        return studentClassDetail;
    }
    static toJson(value) {
        return JSON.stringify(value);
    }
}
export default StudentDetail;
