import { useEffect } from 'react';
function isClickInsideRect(event, rect) {
    return (event.x >= rect.x &&
        event.x <= rect.x + rect.width &&
        event.y >= rect.y &&
        event.y <= rect.y + rect.height);
}
export function useExternalClick(elementRef, handleClick) {
    useEffect(() => {
        function handler(event) {
            if (!elementRef || !handleClick)
                return;
            const element = elementRef.current;
            if (!element)
                return;
            const target = event.target;
            if (target && target === element)
                return;
            if (target && element.contains(target))
                return;
            const rect = element.getBoundingClientRect();
            if (!isClickInsideRect(event, rect)) {
                handleClick(event);
            }
        }
        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, [elementRef, handleClick]);
}
