import React, { useEffect, useState } from 'react';
import { CampusIcon, Card } from '@campus/components';
import Title from '../Title';
import { PlayerButton, Root } from './styles';
const AudioRead = ({ link, name, onChangeTitle, readOnly }) => {
    const [audio, setAudio] = useState();
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    useEffect(() => {
        const audio = new Audio(link);
        audio.ontimeupdate = () => {
            setCurrentTime(audio.currentTime);
            setDuration(audio.duration);
            if (audio.currentTime === audio.duration) {
                setTimeout(() => {
                    audio.currentTime = 0;
                    setCurrentTime(0);
                    setIsPlaying(false);
                }, 2000);
            }
        };
        audio.onloadedmetadata = () => {
            if (audio.duration === Infinity) {
                audio.currentTime = 1e101;
            }
            else {
                setDuration(audio.duration);
            }
        };
        setAudio(audio);
    }, [link]);
    const handlePlayPause = () => {
        if (isPlaying) {
            audio.pause();
        }
        else {
            audio.play();
        }
        setIsPlaying(!isPlaying);
    };
    if (!audio) {
        return null;
    }
    const progress = (currentTime / audio.duration) * 100;
    return (React.createElement(Card, { overflowHidden: true, fullHeight: true },
        React.createElement(Root, null,
            React.createElement(PlayerButton, { "$playing": isPlaying, onClick: handlePlayPause, "aria-label": isPlaying
                    ? 'Parar reprodução do aúdio'
                    : 'Iniciar reprodução do aúdio' },
                React.createElement(CampusIcon, { size: 12, name: isPlaying ? 'pause' : 'play' })),
            React.createElement(Title, { value: name, onChange: onChangeTitle, disabled: readOnly }))));
};
export default AudioRead;
