import PedagogicalPlanning from '../../../../../../models/PedagogicalPlanning';
import PedagogicalPlanningGroup from '../../../../../../models/PedagogicalPlanningGroup';
import { compareDatesWithoutTime } from '../../../../utils/dates';
import { isStageInAreaSkillStages } from '../../../../utils/stages';
export function applyStageFilter(stageId, content) {
    if (!stageId)
        return content;
    const newContent = [];
    for (const group of content) {
        const skipGroup = !group.plannings.some((p) => {
            return isStageInAreaSkillStages(stageId, p.areaSkill);
        });
        if (skipGroup)
            continue;
        const newGroup = Object.assign(new PedagogicalPlanningGroup(), group);
        newGroup.plannings = group.plannings.filter((p) => {
            return isStageInAreaSkillStages(stageId, p.areaSkill);
        });
        newContent.push(newGroup);
    }
    return newContent;
}
export function applyJustStartedSkillsFilter(justStartedSkills, content) {
    if (!justStartedSkills)
        return content;
    return content.filter((group) => {
        return group.didacticSequence.some((a) => {
            return a.classrooms?.some((c) => c.wasExecuted);
        });
    });
}
export function applyJustUsedObjectivesFilter(justUsedObjectives, content) {
    if (!justUsedObjectives)
        return content;
    for (const [groupIndex, group] of content.entries()) {
        const newGroup = Object.assign(new PedagogicalPlanningGroup(), group);
        newGroup.plannings = Array.from({
            length: group.plannings.length
        });
        for (const [planningIndex, planning] of group.plannings.entries()) {
            const newPlanning = Object.assign(new PedagogicalPlanning(), planning);
            newPlanning.objectives = planning.objectives.filter((o) => {
                return o.wasProgrammed;
            });
            newGroup.plannings[planningIndex] = newPlanning;
        }
        content[groupIndex] = newGroup;
    }
    return content;
}
function activityMatchesDateFilter(activity, classroomDatesShouldBe, date) {
    return activity.classrooms.some(({ classroom }) => {
        const result = compareDatesWithoutTime(classroom.date, date);
        return classroomDatesShouldBe === 'lessThan' ? result <= 0 : result >= 0;
    });
}
function applyClassroomDateFilter(content, classroomDatesShouldBe, date) {
    if (!date)
        return content;
    const newContent = [];
    for (const group of content) {
        const newDidacticSequence = group.didacticSequence.filter((activity) => {
            return activityMatchesDateFilter(activity, classroomDatesShouldBe, date);
        });
        const newGroup = Object.assign(new PedagogicalPlanningGroup(), group);
        newGroup.didacticSequence = newDidacticSequence;
        newContent.push(newGroup);
    }
    return newContent;
}
export function applyMinimumClassroomDateFilter(date, content) {
    return applyClassroomDateFilter(content, 'greaterThan', date);
}
export function applyMaximumClassroomDateFilter(date, content) {
    return applyClassroomDateFilter(content, 'lessThan', date);
}
export function applyFilters(mainTableContent, filters) {
    let newContent = applyStageFilter(filters.stage?.id, mainTableContent);
    newContent = applyJustStartedSkillsFilter(filters.justStartedSkills, newContent);
    newContent = applyJustUsedObjectivesFilter(filters.justUsedObjectives, newContent);
    newContent = applyMinimumClassroomDateFilter(filters.minimumClassroomsDate, newContent);
    newContent = applyMaximumClassroomDateFilter(filters.maximumClassroomsDate, newContent);
    return newContent;
}
