import React from 'react';
import { Percentage } from './Columns/Percentage';
import { Scheduled } from './Columns/Scheduled';
import { Skill } from './Columns/Skill';
import { ClassroomCount } from './Columns/ClassroomCount';
import { Body, Container, Header } from './styles';
export const TableContent = ({ content }) => {
    return (React.createElement(Container, null,
        React.createElement(Header, null,
            React.createElement("tr", null,
                React.createElement("th", null, "%"),
                React.createElement("th", null, "Prog."),
                React.createElement("th", null, "Habilidade"),
                React.createElement("th", null, "Aulas"))),
        React.createElement(Body, null, content?.map((item, index) => (React.createElement("tr", { key: `${item.isOriginal}-${item.weight}-${item.code}-${item.classroomCount}-${index}` },
            React.createElement(Percentage, { percentage: item.weight }),
            React.createElement(Scheduled, { scheduled: item.isOriginal }),
            React.createElement(Skill, { code: item.code, skill: item.areaSkill, isActive: item.isOriginal }),
            React.createElement(ClassroomCount, { classroomCount: item.classroomCount })))))));
};
