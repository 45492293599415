const storageKeyName = '@campus:classroomActions:activityId';
export function getLastModifiedActivityId() {
    return sessionStorage.getItem(storageKeyName);
}
export function setLastModifiedActivityId(id) {
    return sessionStorage.setItem(storageKeyName, id);
}
export function removeLastModifiedActivityId() {
    return sessionStorage.removeItem(storageKeyName);
}
