import { Api } from '@campus/auth';
import { ClassificationType, ClassificationTypeInsights, Response } from '../models';
export const listClassificationType = async () => {
    try {
        const response = await Api.INSTANCE.getApi().get('/classificationtype');
        return Response.fromData(response.data.Items.map((item) => ClassificationType.fromJson(item)), response.status);
    }
    catch (e) {
        return Response.error(e);
    }
};
export const listClassificationTypeInsights = async ({ classificationType, disciplineId, classId, studentId, stageId }) => {
    try {
        const response = await Api.INSTANCE.getApi().get(`/classificationtype/${classificationType}/insights`, {
            params: {
                disciplineId: disciplineId,
                classId: classId,
                studentId: studentId,
                stageId: stageId
            }
        });
        return Response.fromData(ClassificationTypeInsights.fromJson(response.data), response.status);
    }
    catch (e) {
        return Response.error(e);
    }
};
