import { QuestionType } from '@campus/commons';
import LiteraryWorkQuestionKind, { typeToKind } from './LiteraryWorkQuestionKind';
class LiteraryWorkQuestionSummary {
    id = '';
    order = 1;
    type = QuestionType.UNIQUE;
    kind = LiteraryWorkQuestionKind.Objective;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromJson(json) {
        const instance = new LiteraryWorkQuestionSummary();
        instance.id = json.Id;
        instance.order = json.Order;
        instance.type = json.QuestionType;
        instance.kind = typeToKind(instance.type);
        return instance;
    }
}
export default LiteraryWorkQuestionSummary;
