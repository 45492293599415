import styled from 'styled-components';
export const Container = styled.button `
  padding: 0 12px;

  min-height: 32px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 6px;

  background: ${(props) => props.theme.colors.blue1};

  &:focus-visible {
    outline: 2px solid blue;
  }
`;
export const Text = styled.p `
  margin: 0;

  text-align: center;

  font-size: 14px;
  font-weight: 600;

  line-height: 20px;

  color: white;
`;
