import React from 'react';
import { AccessCard } from './components/AccessCard';
import { RhythmCard } from './components/RhythmCard';
import { FlowCard } from './components/FlowCard';
import { DashboardRow, DashboardSectionStyled, DivColumnMobile } from './styles';
const DashboardSection = ({ studentId }) => {
    return (React.createElement(DashboardSectionStyled, null,
        React.createElement(DashboardRow, { className: "gy-3", xs: 1, md: 2, lg: 3 },
            React.createElement(RhythmCard, { studentId: studentId }),
            React.createElement(AccessCard, { studentId: studentId }),
            React.createElement(FlowCard, null),
            React.createElement(DivColumnMobile, null))));
};
export default DashboardSection;
