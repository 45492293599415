import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
import { Box } from '../commons/styles/basics';
export const Container = styled(Box) `
  padding: 14px;

  min-height: 201px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 5px;
`;
export const Icon = styled(CampusIcon).attrs({
    name: 'checksAndLines'
}) `
  display: block;

  color: ${(props) => props.theme.colors.blue1};
`;
export const Text = styled.p `
  margin: 0;

  text-align: center;

  font-size: 20px;
  font-weight: 600;

  line-height: 90%;

  color: ${(props) => props.theme.colors.gray700};
`;
