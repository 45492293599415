import React, { useEffect, useState } from 'react';
import { toast } from '../index';
import { NumberInput } from './styles';
const ScoreInput = ({ name, placeholder, value, onChange, onBlur, onEnter, onTab, currentQuestionIndex, currentStudentIndex, closedQuestionsPreviousCount, inputRef, maxScore, disabled, ...props }) => {
    const [localValue, setLocalValue] = useState(value);
    const [score, setScore] = useState(localValue?.toString().replace('.', ','));
    const [toastDelay, setToastDelay] = useState(false);
    useEffect(() => {
        setLocalValue(value);
        setScore((s) => s !== value?.toString() ? value?.toString().replace('.', ',') : s);
    }, [value]);
    const handleChange = (e) => {
        const value = e.target.value.replace(',', '.');
        const number = parseFloat(value);
        const newValueNumber = !isNaN(number) ? Number(number.toFixed(2)) : null;
        const newValueString = value ? value.replace('.', ',') : null;
        if (maxScore >= newValueNumber || !maxScore || !newValueNumber) {
            setScore(newValueString);
            setLocalValue(newValueNumber);
            onChange?.(newValueNumber);
        }
        else if (!toastDelay) {
            toast.warning(`A nota desse campo deve ser maior ou igual a ${maxScore}`);
            setToastDelay(true);
            setTimeout(() => {
                setToastDelay(false);
            }, 3000);
        }
    };
    const handleBlur = (e) => {
        const scoreNumber = parseFloat(score?.replace(',', '.'));
        onBlur?.(!isNaN(scoreNumber) ? scoreNumber : null);
    };
    const handleEnter = (shiftKey, event) => {
        onEnter?.(currentStudentIndex, shiftKey, event, currentQuestionIndex - closedQuestionsPreviousCount);
    };
    const handleTab = (shiftKey, event) => {
        onTab?.(currentStudentIndex, shiftKey, event, closedQuestionsPreviousCount, currentQuestionIndex);
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleEnter(e.shiftKey, e);
        }
        if (e.key === 'Tab') {
            e.preventDefault();
            handleTab(e.shiftKey, e);
        }
    };
    return (React.createElement(NumberInput, { ...props, name: name, placeholder: placeholder ?? '-', value: score ?? '', onChange: handleChange, onBlur: handleBlur, onKeyDown: handleKeyDown, onWheel: (e) => e.currentTarget.blur(), ref: inputRef, type: "text", disabled: disabled }));
};
export default ScoreInput;
