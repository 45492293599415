import styled from 'styled-components';
import { commonStyles } from '../TableCommons';
export const Column = styled.td `
  ${commonStyles}

  text-align: center;
  font-weight: 700;
  line-height: 130%;
  padding: 14px 12px 16px 10px;
`;
export const StyledCode = styled.span `
  color: ${(props) => props.$isActive ? props.theme.colors.CSA.purple : props.theme.colors.blue1};
  font-weight: 700;
  line-height: 130%;
`;
