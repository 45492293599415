import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CampusIcon, Skeleton } from '@campus/components';
import { useStudentPerformace } from './hooks/useStudentPerformace';
import PerformaceTableMobile from './components/PerformaceTableMobile';
import { DivTable, DivScroll, DownloadMdcButton, ImportantInfo, ImportantInfoTile, TableContainer, SmallTitle, SmallDescription } from './styles';
import Variant from '../../models/Variant';
import { useContextManager, ClassDisciplinesProvider } from '../../providers';
const handlePrintPdf = () => {
    window.open('/performace?toPrintPdf=true', '_blank');
};
const StudentPerformanceMobile = ({ studentId }) => {
    const navigate = useNavigate();
    const { period } = useContextManager();
    if (!!period && period.active !== true) {
        navigate(-1);
    }
    const { loading, discipline, evaluations } = useStudentPerformace(studentId);
    if (loading) {
        return (React.createElement(TableContainer, null,
            React.createElement(Skeleton, { height: "500px", width: "100%" })));
    }
    return (React.createElement(ClassDisciplinesProvider, { variant: Variant.STUDENT },
        React.createElement(TableContainer, null,
            React.createElement(SmallDescription, null, "Acompanhe suas notas detalhadas em tempo real."),
            React.createElement(DivScroll, null,
                React.createElement(DivTable, null,
                    React.createElement(PerformaceTableMobile, { discipline: discipline }))),
            React.createElement(DownloadMdcButton, { onClick: handlePrintPdf },
                React.createElement(CampusIcon, { name: "document", size: 18 }),
                "Baixar Boletim (.pdf)",
                ' ')),
        React.createElement(ImportantInfo, null,
            React.createElement(SmallTitle, null, "Informa\u00E7\u00F5es Importantes"),
            React.createElement(ImportantInfoTile, { key: "mdc", "$color": "#003C71" },
                React.createElement("b", null, "Meu Desempenho Campus (MDC):"),
                "\u00A0 \u00C9 a nota consolidada do estudante at\u00E9 o momento. Ela \u00E9 atualizada em tempo real a partir da somat\u00F3ria dos percentuais de Atividades Processuais e Atividades S\u00EDnteses."),
            evaluations.map((e, i) => (React.createElement(ImportantInfoTile, { key: `${i}${e.title}`, "$color": e.color },
                React.createElement("b", null,
                    e.title,
                    ":"),
                "\u00A0",
                e.description))))));
};
export default StudentPerformanceMobile;
