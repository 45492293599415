import { toast } from '@campus/components';
import { LiteraryWorkQuestionKind, questionTypeToQuestionKind } from '../../../../../models';
import { useQuestionsTabContext } from '../context';
import { kindsAndInfos } from '../commons/types';
export function useQuestionActionFeedbacks() {
    const { questionSummaries } = useQuestionsTabContext();
    function getSummaryIndices(questionId) {
        let objectiveQuestionIndex = 0;
        let discursiveQuestionIndex = 0;
        for (const [index, summary] of questionSummaries.entries()) {
            const questionIsObjective = summary.kind === LiteraryWorkQuestionKind.Objective;
            if (questionId === summary.id) {
                const perKind = questionIsObjective
                    ? objectiveQuestionIndex
                    : discursiveQuestionIndex;
                return { real: index, perKind };
            }
            else if (questionIsObjective) {
                objectiveQuestionIndex += 1;
            }
            else {
                discursiveQuestionIndex += 1;
            }
        }
        return { real: -1, perKind: -1 };
    }
    function getSummaryInfos(questionId) {
        const indices = getSummaryIndices(questionId);
        const summary = questionSummaries[indices.real];
        const kind = kindsAndInfos[summary.kind].inSingular;
        const number = indices.perKind + 1;
        return { kind, number };
    }
    function questionWasCreated(questionType) {
        const questionKind = questionTypeToQuestionKind(questionType);
        const count = questionSummaries
            .filter((s) => s.kind === questionKind)
            .reduce((acc) => acc + 1, 0);
        const kind = kindsAndInfos[questionKind].inSingular;
        toast.info(`Questão ${kind} ${count + 1} criada com sucesso!`);
    }
    function questionWasUpdated(questionId) {
        const { kind, number } = getSummaryInfos(questionId);
        toast.info(`Questão ${kind} ${number} atualizada com sucesso!`);
    }
    function questionWasDeleted(questionId) {
        const { kind, number } = getSummaryInfos(questionId);
        toast.info(`A antiga Questão ${kind} ${number} foi apagada com sucesso!`);
    }
    return { questionWasCreated, questionWasUpdated, questionWasDeleted };
}
