import { QuestionType } from '@campus/commons';
var LiteraryWorkQuestionKind;
(function (LiteraryWorkQuestionKind) {
    LiteraryWorkQuestionKind[LiteraryWorkQuestionKind["Objective"] = 0] = "Objective";
    LiteraryWorkQuestionKind[LiteraryWorkQuestionKind["Discursive"] = 1] = "Discursive";
})(LiteraryWorkQuestionKind || (LiteraryWorkQuestionKind = {}));
export default LiteraryWorkQuestionKind;
export function typeToKind(questionType) {
    return questionType === QuestionType.UNIQUE ||
        questionType === QuestionType.MULTIPLE
        ? LiteraryWorkQuestionKind.Objective
        : LiteraryWorkQuestionKind.Discursive;
}
