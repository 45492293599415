import React from 'react';
import { useNavigate } from 'react-router-dom';
import routes from '../../../../../../routes';
import { StyledSelect } from './styles';
const options = [
    { label: 'Cronograma Anual', value: routes.annualTimeline },
    { label: 'Planejamento', value: routes.weeklyPlanning }
    // { label: 'Acompanhamento', value: routes.followUp }
];
const PageSelector = () => {
    const navigateTo = useNavigate();
    const defaultValue = options.find((o) => o.value === window.location.pathname);
    function onChange(option) {
        navigateTo(option.value);
    }
    return (React.createElement(StyledSelect, { options: options, defaultValue: defaultValue, onChange: onChange }));
};
export default PageSelector;
