import styled from 'styled-components';
import { Box, Line as BaseLine } from '../commons/styles/basics';
export const Container = styled(Box) `
  & *:focus-visible {
    outline: 1px solid ${(props) => props.theme.colors.gray2};
  }
`;
export const Header = styled.header `
  padding: 12px 14px 11px 12px;
`;
export const Title = styled.h4 `
  margin: 0;

  font-size: 16px;
  font-weight: 700;

  line-height: 110%;

  color: ${(props) => props.$questionIsDiscursive
    ? props.theme.colors.green3
    : props.theme.colors.CSA.blue};
`;
export const NumberOfQuestions = styled.p `
  margin: 0;

  opacity: 0.5;

  font-size: 12px;
  font-weight: 700;

  line-height: 110%;

  color: ${(props) => props.theme.colors.gray3};
`;
export const ItemsContainer = styled.ul `
  margin: 0;
  padding: 0;

  list-style: none;

  & > :last-child.items-separator {
    display: none;
  }
`;
export const Line = styled(BaseLine).attrs((props) => ({
    className: props.$separator ? 'items-separator' : ''
})) `
  &.items-separator {
    margin: 0 11px 0 10px;
  }
`;
