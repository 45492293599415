import styled from 'styled-components';
export const Container = styled.div `
  height: 214.425px;

  background: white;

  box-shadow: 0px 2.062px 5.154px -2.062px rgba(0, 0, 0, 0.1);

  border-radius: 4.124px;
  border: 1.031px solid ${(props) => props.theme.colors.gray200};
`;
