import React, { useState } from 'react';
import ResizeIcon from '@campus/components/assets/icons/resize.svg';
import FlowModal from '../FlowModal';
import { ContainerRight, Header, Title, TimeSeries } from './styles';
const FlowTitleContainer = ({ title, timeSeries }) => {
    const [openFlowModal, setOpenFlowModal] = useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, null,
            React.createElement("div", null,
                React.createElement(Title, null, title),
                React.createElement(TimeSeries, null, timeSeries ? `Últimos ${timeSeries} dias` : 'Todo o período')),
            React.createElement(ContainerRight, { role: "button", onClick: () => setOpenFlowModal(true) },
                React.createElement("span", null, "Ampliar Gr\u00E1fico"),
                React.createElement("img", { src: ResizeIcon, alt: "" }))),
        React.createElement(FlowModal, { open: openFlowModal, handleClose: () => setOpenFlowModal(false) })));
};
export default FlowTitleContainer;
