import { dateToString } from '../utils';
import Classification from './Classification';
import ContentActivity from './ContentActivity';
import ContentAudience from './ContentAudience';
import ContentType from './ContentType';
import EvaluationType from './EvaluationType';
import Stage from './Stage';
class Content {
    id;
    automaticZero;
    contentType;
    headerImageUrl;
    iconImageUrl;
    estimatedDuration;
    maxDuration;
    missingClassificationAcceptance;
    name;
    displayTime;
    endTime;
    publishTime;
    startTime;
    studyResources;
    stage;
    stageId;
    topicId;
    totalWeight;
    tags;
    audiences;
    evaluationType;
    activities;
    classifications;
    isRecovery;
    ignoreMDC;
    state;
    constructor(topicId, defaultDate) {
        this.tags = [];
        this.classifications = [];
        this.name = 'Sem título';
        this.topicId = topicId;
        this.publishTime = defaultDate;
        this.displayTime = defaultDate;
        this.endTime = null;
        this.startTime = null;
    }
    static fromJson(data) {
        const content = new Content();
        content.id = data?.Id;
        content.automaticZero = data?.AutomaticZero;
        content.contentType = data?.ContentType;
        content.headerImageUrl = data?.HeaderImageUrl;
        content.iconImageUrl = data?.IconImageUrl;
        content.estimatedDuration = data?.EstimatedDuration;
        content.maxDuration = data?.MaxDuration;
        content.missingClassificationAcceptance =
            data?.MissingClassificationAcceptance;
        content.name = data?.Name;
        content.displayTime = Content.parseDate(data?.DisplayTime);
        content.endTime = Content.parseDate(data?.EndTime);
        content.publishTime = Content.parseDate(data?.PublishTime);
        content.startTime = Content.parseDate(data?.StartTime);
        content.studyResources = data?.StudyResources;
        content.stageId = data?.StageId;
        content.stage = Stage.fromJson(data?.Stage);
        content.topicId = data?.TopicId;
        content.totalWeight = data?.TotalWeight;
        content.tags = data?.Tags || [];
        content.isRecovery = data?.IsRecovery;
        content.ignoreMDC = data?.IgnoreMDC;
        content.state = data?.State;
        content.audiences =
            data?.Audiences?.map((item) => ContentAudience.fromJson(item)) ?? [];
        const evaluationType = EvaluationType.fromJson(data?.EvaluationType);
        content.evaluationType = evaluationType;
        content.activities =
            data?.Activities?.map((item) => ContentActivity.fromJson(item)) ?? [];
        content.classifications =
            data?.Classifications?.map((item) => Classification.fromJson(item)) ?? [];
        return content;
    }
    static parseDate(date) {
        if (date == null) {
            return;
        }
        return new Date(date);
    }
    getDisplayTimeString() {
        return dateToString(this.displayTime);
    }
    getPublishTimeString() {
        return dateToString(this.publishTime);
    }
    getEndTimeString() {
        return dateToString(this.endTime);
    }
    getStartTimeString() {
        return dateToString(this.startTime);
    }
    get toSelectOption() {
        return {
            value: this,
            label: this.name
        };
    }
    clone() {
        const content = new Content();
        content.id = this.id;
        content.automaticZero = this.automaticZero;
        content.contentType = this.contentType;
        content.headerImageUrl = this.headerImageUrl;
        content.iconImageUrl = this.iconImageUrl;
        content.estimatedDuration = this.estimatedDuration;
        content.maxDuration = this.maxDuration;
        content.missingClassificationAcceptance =
            this.missingClassificationAcceptance;
        content.name = this.name;
        content.displayTime = this.displayTime;
        content.endTime = this.endTime;
        content.publishTime = this.publishTime;
        content.startTime = this.startTime;
        content.studyResources = this.studyResources;
        content.stageId = this.stageId;
        content.stage = this.stage;
        content.topicId = this.topicId;
        content.totalWeight = this.totalWeight;
        content.tags = this.tags;
        content.isRecovery = this.isRecovery;
        content.ignoreMDC = this.ignoreMDC;
        content.audiences = this.audiences;
        content.evaluationType = this.evaluationType;
        content.activities = this.activities;
        content.classifications = this.classifications;
        return content;
    }
    toJson() {
        return {
            AutomaticZero: this.automaticZero,
            ContentType: this.contentType ?? ContentType.Regular,
            EvaluationTypeId: this.evaluationType?.id,
            HeaderImageUrl: this.headerImageUrl,
            IconImageUrl: this.iconImageUrl,
            MaxDuration: this.maxDuration,
            EstimatedDuration: this.estimatedDuration,
            IsRecovery: this.isRecovery,
            IgnoreMDC: this.ignoreMDC,
            Name: this.name,
            DisplayTime: dateToString(this.displayTime),
            EndTime: dateToString(this.endTime),
            PublishTime: dateToString(this.publishTime),
            StartTime: dateToString(this.startTime),
            StageId: this.stageId,
            TopicId: this.topicId,
            Audiences: this.audiences?.map((item) => item.toJson()),
            Classifications: this.classifications
                .map((item) => item.items.map((i) => i.id))
                .flat(),
            Tags: this.tags,
            MissingClassificationAcceptance: this.missingClassificationAcceptance
        };
    }
}
export default Content;
