import { QuestionType } from '@campus/commons';
import { ContentItem, Question, QuestionAnswer } from '@campus/content-items';
import LiteraryWorkQuestionKind, { typeToKind } from './LiteraryWorkQuestionKind';
class LiteraryWorkQuestion extends Question {
    kind = LiteraryWorkQuestionKind.Objective;
    constructor() {
        super();
        this.kind = typeToKind(this.questionType);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromJson(json) {
        const instance = new LiteraryWorkQuestion();
        instance.id = json.Id;
        instance.comment = json.Comment;
        instance.questionType = json.QuestionType;
        instance.kind = typeToKind(instance.questionType ?? QuestionType.UNIQUE);
        instance.items = Array.isArray(json.Items)
            ? json.Items.map((item) => ContentItem.fromJson(item))
            : [];
        instance.answers = Array.isArray(json.Answers)
            ? json.Answers.map((answer) => QuestionAnswer.fromJson(answer))
            : [];
        return instance;
    }
}
export default LiteraryWorkQuestion;
