import React from 'react';
import { ContainerAndLine, Group, ShieldCheckIcon, ShieldQuestionMarkIcon, Text } from './styles';
export const MenuAndSeparator = () => {
    return (React.createElement(ContainerAndLine, null,
        React.createElement(Group, null,
            React.createElement(ShieldCheckIcon, null),
            React.createElement(Text, null, "Habilidade do programa")),
        React.createElement(Group, null,
            React.createElement(ShieldQuestionMarkIcon, null),
            React.createElement(Text, null, "Habilidade de outro componente ou s\u00E9rie"))));
};
