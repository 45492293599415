import React, { useState } from 'react';
import { Card } from '@campus/components';
import { Container, IFrame } from './styles';
import Title from '../Title';
import { ViewModal } from '../';
const Video = ({ link, name, onChangeTitle, readOnly }) => {
    const [openModal, setOpenModal] = useState(false);
    const toggleModal = () => setOpenModal((prev) => !prev);
    const linkVideo = `${link}?autopause=0`;
    return (React.createElement(React.Fragment, null,
        React.createElement(Card, { overflowHidden: true, fullHeight: true },
            React.createElement(Container, { onClick: toggleModal },
                React.createElement("video", { muted: true, playsInline: true, src: `${link}#t=1s` }),
                React.createElement(Title, { value: name, onChange: onChangeTitle, disabled: readOnly }))),
        React.createElement(ViewModal, { handleClose: toggleModal, open: openModal },
            React.createElement(IFrame, { src: linkVideo.toString(), title: name, allowFullScreen: true, frameBorder: "0" }))));
};
export default Video;
