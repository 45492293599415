import React from 'react';
import { useStudentPerformace } from './hooks/useStudentPerformace';
import PerformaceTable from './components/PerformaceTable';
import { ImportantInfo, ImportantInfoTile, TableContainer } from './styles';
import { Skeleton } from '@campus/components';
const StudentPerformance = () => {
    const { loading, discipline, evaluations } = useStudentPerformace();
    if (loading) {
        return (React.createElement(TableContainer, null,
            React.createElement(Skeleton, { height: "500px", width: "100%" })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(TableContainer, null,
            React.createElement(PerformaceTable, { discipline: discipline, evaluations: evaluations })),
        React.createElement(ImportantInfo, null,
            React.createElement("h2", null, "Informa\u00E7\u00F5es Importantes"),
            evaluations.map((e, i) => (React.createElement(ImportantInfoTile, { key: `${i}${e.title}`, "$color": e.color },
                React.createElement("b", null,
                    e.title,
                    ":"),
                "\u00A0",
                e.description))),
            "O percentual de Engajamento em atividades opcionais \u00E9 gerado a partir da somat\u00F3ria de atividades opcionais realizadas pela somat\u00F3ria de todas as atividades opcionais postadas.")));
};
export default StudentPerformance;
