import React from 'react';
import Card from '../Card';
import { Header, HeaderContent, Title } from './styles';
const ToolCard = ({ name, icon }) => {
    return (React.createElement(Card, { fullHeight: true },
        React.createElement(Header, null,
            React.createElement(HeaderContent, null,
                icon(),
                React.createElement(Title, null, name)))));
};
export default ToolCard;
