import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from '@campus/components';
import { RequestStatus } from '@campus/commons';
import { getDataAreaSkillManagement } from '../../../../../services/pedagogicalPlanning';
import { useSkillManagementContext } from '../../../context';
const pageSize = 10;
export function useFetchTableContent() {
    const [data, setData] = useState(null);
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    const pageIndex = useRef(0);
    const { events, getFilters } = useSkillManagementContext();
    const fetchContent = useCallback(async () => {
        const filters = getFilters();
        if (!filters.segmentId ||
            !filters.gradeId ||
            !filters.disciplineId
        // || !filters.classId
        ) {
            setData([]);
            return;
        }
        setRequestStatus(RequestStatus.Loading);
        const response = await getDataAreaSkillManagement({
            ...filters,
            pageSize,
            pageIndex: pageIndex.current
        });
        if (response.data) {
            setData(response.data.items);
            setRequestStatus(RequestStatus.Succeeded);
            events.table.dataFetched.emit({
                numberOfPages: response.data.numberOfPages
            });
        }
        else if (!response.isCancellation()) {
            setRequestStatus(RequestStatus.Failed);
            toast.error('Falha ao buscar conteúdo');
            events.table.dataFetchFailed.emit({ pageIndex: pageIndex.current });
        }
    }, [getFilters, events.table.dataFetched, events.table.dataFetchFailed]);
    useEffect(() => {
        fetchContent();
    }, [fetchContent]);
    useEffect(() => {
        return events.table.pageIndexChanged.subscribe((payload) => {
            if (!payload)
                return;
            pageIndex.current = payload.newIndex;
            fetchContent();
        });
    }, [events.table.pageIndexChanged, fetchContent]);
    useEffect(() => {
        return events.filters.filtersChanged.subscribe(() => {
            pageIndex.current = 0;
            fetchContent();
        });
    }, [events.filters.filtersChanged, fetchContent]);
    return { data, requestStatus };
}
