import styled from 'styled-components';
import { CampusIcon, OptionListDialog } from '@campus/components';
export const ContainerStyled = styled.div `
  grid-column: full-width;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  background: rgba(0, 114, 206, 0.1);
`;
export const List = styled(OptionListDialog) `
  position: absolute;
  top: 80%;
  left: 0;
  min-width: 158px;
`;
export const OpenBookIcon = styled(CampusIcon).attrs({
    name: 'clapperboard'
}) `
  display: block;

  fill: none;

  stroke-width: 2px;

  color: ${(props) => props.theme.colors.blue1};
`;
export const ContainerMessage = styled.div `
  margin-top: 60px;
  grid-column: content;
  min-height: 200px;

  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
`;
export const Message = styled.p `
  margin-bottom: 0;

  font-size: 20px;
  font-weight: 600;

  text-align: center;

  line-height: 90%;

  color: ${(props) => props.theme.colors.gray700};
`;
