import ContentAudience from './ContentAudience';
class Classroom {
    id;
    name;
    gradeId;
    static fromJson(data) {
        const classroom = new Classroom();
        classroom.id = data?.Id;
        classroom.name = data?.Name;
        classroom.gradeId = data?.GradeId;
        return classroom;
    }
    get audience() {
        return ContentAudience.fromClassroom(this);
    }
    get toSelectOption() {
        return {
            value: this,
            label: this.name
        };
    }
    clone() {
        const classroom = new Classroom();
        classroom.id = this.id;
        classroom.name = this.name;
        classroom.gradeId = this.gradeId;
        return classroom;
    }
    static empty() {
        const classroom = new Classroom();
        classroom.id = '';
        classroom.name = 'Todas as turmas';
        return classroom;
    }
}
export default Classroom;
