export default class ClassroomNotFound {
    classId;
    className;
    classroom;
    prevClassroomId;
    prevDidacticSequenceId;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new ClassroomNotFound();
        instance.classId = json.ClassId;
        instance.className = json.ClassName ?? null;
        instance.classroom = json.Classroom;
        instance.prevClassroomId = json.PrevClassroomId ?? null;
        instance.prevDidacticSequenceId = json.PrevDidacticSequenceId ?? null;
        return instance;
    }
}
