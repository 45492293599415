import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
export const Container = styled.button `
  padding: 12.5px 10px 14.5px 10px;

  width: 100%;

  display: flex;
  column-gap: 6px;
  align-items: center;
  justify-content: center;

  border: none;
  background: none;

  &:hover {
    background: ${(props) => props.theme.colors.gray4}0f;
  }
`;
export const Text = styled.p `
  margin: 0;

  text-align: center;

  font-size: 12px;
  font-weight: 700;

  color: ${(props) => props.theme.colors.gray4};
`;
export const Icon = styled(CampusIcon).attrs({
    name: 'squarePlus'
}) `
  display: block;
`;
