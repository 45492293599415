import styled from 'styled-components';
import { StyledColumnWrapper } from '../common/styles';
export const StyledWrapper = styled(StyledColumnWrapper) `
  padding: 12px 11px;
  background-color: rgba(242, 242, 242);
  border-radius: inherit;
  display: flex;
  justify-content: center;
`;
export const StyledText = styled.span `
  font-size: 14px;
  font-weight: 700;
  line-height: 100%;
  writing-mode: vertical-rl;
  rotate: -180deg;
  word-break: break-all;
  align-self: start;

  color: ${(props) => props.theme.colors.gray1};
`;
