class ContentActivity {
    name;
    startTime;
    score;
    performance;
    performanceStatus;
    answerState;
    state;
    stateText;
    answerReceipt;
    static fromJson(data) {
        const contentActivity = new ContentActivity();
        contentActivity.name = data?.Name;
        contentActivity.startTime = new Date(data?.StartTime);
        contentActivity.score = data?.Score;
        contentActivity.performance = data?.Performance;
        contentActivity.performanceStatus = data?.PerformanceStatus;
        contentActivity.answerState = data?.AnswerState;
        contentActivity.state = data?.State;
        contentActivity.stateText = data?.StateText;
        contentActivity.answerReceipt = data?.AnswerReceipt;
        return contentActivity;
    }
}
export default ContentActivity;
