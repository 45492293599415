import TopicContent from './TopicContent';
class TopicWithContents {
    disciplineId;
    topicName;
    topicId;
    totalEstimatedDurationFormat;
    contents;
    constructor() {
        this.contents = [];
        this.totalEstimatedDurationFormat = '';
    }
    static fromJson(data) {
        const topic = new TopicWithContents();
        topic.disciplineId = data.DisciplineId;
        topic.topicName = data.TopicName;
        topic.topicId = data.TopicId;
        topic.contents =
            data.Contents?.map((item) => TopicContent.fromJson(item)) || [];
        topic.setTotalEstimatedDurationFormat();
        return topic;
    }
    setTotalEstimatedDurationFormat() {
        const estimatedDuration = this.contents.reduce((totalEstimatedDuration, topicContent) => {
            return totalEstimatedDuration + topicContent.estimatedDuration;
        }, 0);
        const hours = estimatedDuration / 60;
        const minutes = estimatedDuration % 60;
        if (hours > 0) {
            this.totalEstimatedDurationFormat = `${Math.round(hours)}h`;
        }
        if (minutes > 0) {
            if (hours > 0) {
                this.totalEstimatedDurationFormat += ` e `;
            }
            this.totalEstimatedDurationFormat += `${minutes}min`;
        }
    }
}
export default TopicWithContents;
