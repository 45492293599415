import React, { useState, useCallback, forwardRef } from 'react';
import { ConfirmationModal, toast } from '@campus/components';
import MenuOptionsDialog from '../MenuOptionsDialog';
import { useDialog } from '../../../commons/hooks';
import { HamburgerIcon } from '../../../commons/styles';
import { Container } from './styles';
import ModalLiteraryWork from '../../../../pages/commons/components/ModalLiteraryWork';
import { useLiteraryWorksPageContext } from '../../../../pages/LiteraryWorks/context';
import { getLiteraryWorkById } from '../../../../services';
import { deleteLiteraryWorkById } from '../../../../services/literaryWork';
import DescriptionDeleteLiteraryWorks from './components/DescriptionDeleteLiteraryWorks';
const WorkMenu = forwardRef(({ className, literaryWork }, ref) => {
    const [showEditModal, setShowEditModal] = useState(false);
    const [literaryWorkEdit, setLiteraryWorkEdit] = useState();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { refetchLiteraryWorks } = useLiteraryWorksPageContext();
    const { showDialog, setShowDialog } = useDialog(ref);
    function onContainerClick(event) {
        event.stopPropagation();
        setShowDialog((currentValue) => !currentValue);
    }
    const fetchLiteraryWorkById = useCallback(async (id) => {
        try {
            const response = await getLiteraryWorkById({ id });
            return response?.data;
        }
        catch (errorData) {
            toast.error(errorData?.error?.message ?? 'Erro ao buscar obra literária');
            return;
        }
    }, []);
    const handleEditLiteraryWork = useCallback(() => {
        fetchLiteraryWorkById(literaryWork.id).then((data) => {
            setShowEditModal(true);
            setLiteraryWorkEdit(data);
        });
    }, [fetchLiteraryWorkById, literaryWork.id]);
    const handleDeleteLiteraryWork = useCallback(() => {
        setShowDeleteModal(true);
    }, []);
    const handleSubmitDelete = async () => {
        try {
            await deleteLiteraryWorkById(literaryWork.id);
            refetchLiteraryWorks();
            toast.success('Obra literária deletada com sucesso!');
        }
        catch (error) {
            const asResponse = error;
            toast.error(asResponse?.error?.message ?? 'Erro ao excluir obra literária');
        }
    };
    return (React.createElement(Container, { className: className, onClick: onContainerClick },
        React.createElement(HamburgerIcon, null),
        showDialog ? (React.createElement(MenuOptionsDialog, { onClickEdit: handleEditLiteraryWork, onClickDelete: handleDeleteLiteraryWork })) : null,
        showEditModal && literaryWorkEdit?.id && (React.createElement(ModalLiteraryWork, { show: showEditModal, onHide: () => setShowEditModal(false), onSaved: refetchLiteraryWorks, literaryWork: literaryWorkEdit })),
        showDeleteModal && (React.createElement(ConfirmationModal, { title: "Apagar obra liter\u00E1ria", description: React.createElement(DescriptionDeleteLiteraryWorks, null), show: showDeleteModal, onHide: () => {
                setShowDeleteModal(false);
            }, onSubmit: handleSubmitDelete }))));
});
WorkMenu.displayName = 'WorkMenu';
export default WorkMenu;
