import React, { useState } from 'react';
import { useLaunchReportContext } from '../../../context';
import { DatePicker } from './DatePicker';
import { Label } from './commonStyles';
export const EndDate = () => {
    const { events, getFilters } = useLaunchReportContext();
    const [value, setValue] = useState(getFilters().endDate);
    function onChange(newDate) {
        setValue(newDate);
        events.filters.endDateChanged.emit({ newDate });
    }
    return (React.createElement("div", null,
        React.createElement(Label, null, "Data Fim"),
        React.createElement(DatePicker, { value: value, onChange: onChange })));
};
