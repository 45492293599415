import { createBaseCurriculumComponentsSlice } from '../../../common/stores/slices/curriculumComponents';
export function createCurriculumComponentsSlice(events) {
    return (set, get) => {
        return createBaseCurriculumComponentsSlice({
            commonDataEvents: events,
            getAllSegments: () => get().commonData.segments.data,
            getAllGrades: () => get().commonData.grades.data,
            getAllDisciplines: () => get().commonData.disciplines.data,
            updateSlice(updater) {
                set(({ curriculumComponents }) => ({
                    curriculumComponents: {
                        ...curriculumComponents,
                        ...updater(curriculumComponents)
                    }
                }));
            }
        });
    };
}
