import React from 'react';
import { CampusIcon } from '@campus/components';
import { ContentDeleteLiteraryWorksBody, LabelDeleteLiteraryWorks, SubLabelDeleteLiteraryWorks } from './styles';
const DescriptionDeleteLiteraryWorks = () => (React.createElement(ContentDeleteLiteraryWorksBody, null,
    React.createElement(CampusIcon, { name: "teacherIcon" }),
    React.createElement(LabelDeleteLiteraryWorks, null,
        "Voc\u00EA tem certeza que deseja apagar ",
        React.createElement("b", null, "o v\u00EDdeo"),
        "?"),
    React.createElement(SubLabelDeleteLiteraryWorks, null,
        "Lembre-se que esta a\u00E7\u00E3o \u00E9 ",
        React.createElement("b", null, "irrevers\u00EDvel"),
        ".")));
export default DescriptionDeleteLiteraryWorks;
