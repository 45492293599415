import { Discipline } from '@campus/commons';

import Grade from './Grade';
import LibraryObjectChapter from './LibraryObjectChapter';
import LibraryObjectContentType from './LibraryObjectContentType';

class LibraryObject {
  id?: string;

  name?: string;

  contentType?: LibraryObjectContentType;

  contentUrl?: string;

  imageUrl?: string;

  publishDate?: Date;

  discipline?: Discipline;

  grade?: Grade;

  chapters?: LibraryObjectChapter[];

  static fromJson(data: any): LibraryObject {
    const libraryObject = new LibraryObject();

    libraryObject.id = data?.Id;

    libraryObject.name = data?.Name;

    libraryObject.contentType = data?.ContentType;

    libraryObject.contentUrl = data?.ContentUrl;

    libraryObject.imageUrl = data?.ImageUrl;

    libraryObject.publishDate = data?.PublishDate;

    libraryObject.discipline = data?.Discipline
      ? Discipline.fromJson(data?.Discipline)
      : undefined;

    libraryObject.grade = data?.Grade
      ? Discipline.fromJson(data?.Grade)
      : undefined;

    libraryObject.chapters =
      data.Chapters?.map((s) => LibraryObjectChapter.fromJson(s)) ?? [];

    return libraryObject;
  }
}

export default LibraryObject;
