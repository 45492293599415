import React from 'react';
import { Select, Skeleton } from '..';
import { ClassFilterContainer } from './styles';
const ClassFilter = ({ loading, onChange, options, selectedOption, customLabel = '', customPlaceholder = '', disabled = false }) => {
    const selectStyle = {
        menu: (base) => ({
            ...base,
            marginTop: 3,
            paddingTop: 4,
            paddingBottom: 4
        }),
        control: () => ({
            display: 'flex',
            border: '1px solid #cccccc',
            borderRadius: '6px',
            height: '32px',
            alignItems: 'center',
            padding: 1
        }),
        dropdownIndicator: () => ({
            color: '#4D4D4D',
            width: 25,
            height: 27
        }),
        indicatorContainer: (base) => ({
            ...base,
            width: 23,
            height: 25
        }),
        option: (base, state) => ({
            ...base,
            '&:hover': {
                background: '#e9ecef'
            },
            color: '#212529',
            fontFamily: 'Gilroy',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '110%',
            backgroundColor: state.isSelected ? 'rgba(255, 255, 255, 0.4)' : 'white'
        }),
        placeholder: (base) => ({
            ...base,
            fontSize: 14,
            color: '#4D4D4D',
            fontFamily: 'Gilroy'
        }),
        input: (base) => ({
            ...base,
            caretColor: 'transparent'
        }),
        singleValue: (base) => ({
            ...base,
            color: '#4D4D4D',
            fontFamily: 'Gilroy',
            fontStyle: 'normal',
            fontSize: '14px',
            lineHeight: '110%'
        })
    };
    return (React.createElement(ClassFilterContainer, null,
        React.createElement("p", null,
            customLabel ?? 'Filtrar por',
            ":"),
        loading ? (React.createElement(Skeleton, { height: "38px", width: "140px" })) : (React.createElement(Select, { value: selectedOption, placeholder: customPlaceholder ?? 'TODAS', options: options, onChange: onChange, styles: selectStyle, isDisabled: disabled }))));
};
export default ClassFilter;
