import React, { useState } from 'react';
import Check from '../Check';
import CornerDownRight from '../../assets/icons/corner-down-right.svg';
import SquarePlus from '../../assets/icons/square-plus.svg';
import SquareMinus from '../../assets/icons/square-minus.svg';
import { Root, Button, LabelOne, LabelTwo, LabelThree, ListItem, Corner } from './styles';
const labelMap = { 1: LabelOne, 2: LabelTwo, 3: LabelThree };
const TreeItem = ({ name, label, checked, onChange, onLoad, onClick, level, children, defaultExpanded, enabledExpandItems, selected }) => {
    const [expanded, setExpanded] = useState(defaultExpanded);
    const Label = labelMap[level];
    const handleClick = () => {
        setExpanded(!expanded);
        if (!expanded)
            onLoad?.();
    };
    return (React.createElement(ListItem, { "$level": level },
        level !== 1 && (React.createElement(Corner, { alt: "\u00EDcone seta", "aria-hidden": true, src: CornerDownRight })),
        React.createElement(Root, { "$selected": selected },
            checked !== undefined && (React.createElement(Check, { id: name, name: name, type: "checkbox", onChange: onChange, checked: checked })),
            (level === 2 || enabledExpandItems) && (React.createElement(Button, { onClick: handleClick, "aria-label": "Expandir e esconder op\u00E7\u00F5es da \u00E1rvore" },
                React.createElement("img", { src: expanded ? SquareMinus : SquarePlus, alt: "", "aria-hidden": true }))),
            React.createElement(Label, { role: !!onClick ? 'button' : 'none', onClick: () => {
                    onClick ? onClick() : '';
                }, "$isClickable": !!onClick, "$selected": selected, htmlFor: name }, label)),
        expanded && children));
};
export default TreeItem;
