import { Api } from '@campus/auth';
import { toast } from '@campus/components';
import qs from 'qs';
import { Response } from '../models';
export const dowloadFile = async (fileName, url, params) => {
    try {
        const api = Api.INSTANCE.getApi();
        const response = await api.get(url, {
            params,
            responseType: 'blob',
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
        });
        if (response.data) {
            const responseUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = responseUrl;
            link.setAttribute('download', `${fileName}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    }
    catch (e) {
        const res = Response.error(e);
        toast.error(res.error.message);
    }
};
