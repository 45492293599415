import { getGroupActivities, reorderGroupActivities } from '../../../../../../services/pedagogicalPlanning';
import { checkIfClassroomsAreLinkedToOtherActivities, updateClassroomsForSubsequentActivities } from '../../../../../../services/didaticSequence';
export async function getUpdatedGroupActivities(params) {
    const response = await getGroupActivities(params);
    return response.data?.length >= 0 ? response.data : null;
}
export async function reorderActivities(params) {
    const response = await reorderGroupActivities(params);
    return !response.error;
}
export async function updateSubsequentActivities(params) {
    const classrooms = params.getAllSelectedClassrooms();
    const response = await updateClassroomsForSubsequentActivities({
        abortSignal: params.abortSignal,
        didacticSequenceId: params.newActivity.id,
        classIds: classrooms.map((c) => c.class.id)
    });
    return !response.error;
}
export async function isThereAnyClassroomLinkedToOtherActivities(params) {
    const classrooms = params.getAllSelectedClassrooms();
    const response = await checkIfClassroomsAreLinkedToOtherActivities({
        abortSignal: params.abortSignal,
        classroomIds: classrooms.map((c) => c.id),
        didacticSequenceId: params.didacticSequenceId,
        pedagogicalPlanningId: params.pedagogicalPlanningId
    });
    if (!response.data || response.error) {
        params.onError();
        return false;
    }
    for (const classId in response.data) {
        if (response.data[classId]) {
            return true;
        }
    }
    return false;
}
