class ClassUserInfo {
    id;
    name;
    static fromJson(data) {
        const classUserInfo = new ClassUserInfo();
        classUserInfo.id = data?.Id;
        classUserInfo.name = data?.Name;
        return classUserInfo;
    }
}
export default ClassUserInfo;
