export const allStatuses = [
    {
        text: 'Enviada com sucesso',
        icon: 'check',
        color: 'green3',
        identifier: 'Answered'
    },
    {
        text: 'Enviada por tempo esgotado',
        icon: 'check',
        color: 'orange1',
        identifier: 'PartialAnswered'
    },
    {
        text: 'Zero por não envio no prazo',
        icon: 'zero',
        color: 'error',
        identifier: 'Zero'
    },
    {
        text: 'Entregue após o prazo',
        icon: 'closeRadius',
        color: 'error',
        identifier: 'NotAnswered'
    },
    {
        text: 'Nota lançada pelo professor',
        icon: 'userCheck',
        color: 'blue1',
        identifier: 'Corrected'
    },
    {
        text: 'Múltiplos envios',
        icon: 'check',
        color: 'blue1',
        identifier: 'Answer'
    },
    {
        text: 'Sem status',
        identifier: 'None'
    }
];
