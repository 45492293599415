import React, { useRef, useCallback, useState } from 'react';
import { useExternalClick } from '@campus/commons';
import { LiteraryWorkQuestionKind } from '../../../../../../models';
import { useLiteraryWorkPageContext } from '../../../../context';
import { useQuestionsTabContext } from '../../context';
import { kindsAndInfos } from '../../commons/types';
import QuestionItemMenu from '../QuestionItemMenu';
import { EditQuestionModal } from '../QuestionModals';
import ConfirmQuestionDeletionModal from '../ConfirmQuestionDeletionModal';
import { Container, Description } from './styles';
const QuestionItem = ({ index, questionId, questionKind }) => {
    const [showEditQuestionModal, setShowEditQuestionModal] = useState(false);
    const [showDeleteQuestionModal, setShowDeleteQuestionModal] = useState(false);
    const { questionsCanBe } = useLiteraryWorkPageContext();
    const { selectedQuestionId, setSelectedQuestionId } = useQuestionsTabContext();
    const menuRef = useRef(null);
    const containerRef = useRef(null);
    const showMenu = questionsCanBe === 'managed';
    const isSelected = selectedQuestionId === questionId;
    const kindInSingular = kindsAndInfos[questionKind].inSingular;
    const questionIsDiscursive = questionKind === LiteraryWorkQuestionKind.Discursive;
    function hideMenuOrCallOrSetQuestionId() {
        if (menuRef.current?.isDialogBeingShown()) {
            menuRef.current.hideDialog();
        }
        else if (!isSelected) {
            setSelectedQuestionId(questionId);
        }
    }
    function onContainerClick(event) {
        event?.stopPropagation();
        hideMenuOrCallOrSetQuestionId();
    }
    function onKeyDown(event) {
        if (event.altKey)
            return;
        if (event.ctrlKey)
            return;
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            event.stopPropagation();
            hideMenuOrCallOrSetQuestionId();
        }
    }
    const handleExternalClick = useCallback(() => {
        if (menuRef.current?.isDialogBeingShown()) {
            menuRef.current.hideDialog();
        }
    }, []);
    useExternalClick(containerRef, handleExternalClick);
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { tabIndex: 0, ref: containerRef, onKeyDown: onKeyDown, onClick: onContainerClick, "$isSelected": isSelected },
            React.createElement(Description, { "$isSelected": isSelected, "$questionIsDiscursive": questionIsDiscursive },
                "Quest\u00E3o ",
                kindInSingular,
                " ",
                index + 1),
            showMenu ? (React.createElement(QuestionItemMenu, { ref: menuRef, onEditOptionClick: () => setShowEditQuestionModal(true), onDeleteOptionClick: () => setShowDeleteQuestionModal(true) })) : null),
        showEditQuestionModal ? (React.createElement(EditQuestionModal, { questionId: questionId, hideModal: () => setShowEditQuestionModal(false) })) : null,
        showDeleteQuestionModal ? (React.createElement(ConfirmQuestionDeletionModal, { questionId: questionId, hideModal: () => setShowDeleteQuestionModal(false) })) : null));
};
export default QuestionItem;
