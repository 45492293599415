export function extractTextContentFromHTML(html) {
    if (!html)
        return null;
    const wrapper = document.createElement('div');
    try {
        wrapper.innerHTML = html;
        return wrapper.textContent;
    }
    catch {
        return null;
    }
}
