export default class PedagogicalPlanningCommentUsers {
    id;
    name;
    imageUrl;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new PedagogicalPlanningCommentUsers();
        instance.id = json.Id;
        instance.name = json.Name;
        instance.imageUrl = json.ImageUrl ?? null;
        return instance;
    }
}
