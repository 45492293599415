import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import { RequestStatus } from '@campus/commons';
import { useLaunchReportContext } from '../../../../context';
import { useFetchDisciplines } from '../../hooks';
import { SelectDropdownIndicator } from '../SelectDropdownIndicator';
import { MultiValueSelectOption } from '../MultiValueSelectOption';
import { MultiValueSelectValue } from '../MultiValueSelectValue';
import { matchGradeId } from './utils';
import { Label } from '../commonStyles';
import { styles } from './styles';
export const CurriculumComponents = () => {
    const theme = useTheme();
    const { events } = useLaunchReportContext();
    const { data, requestStatus } = useFetchDisciplines();
    const [value, setValue] = useState([]);
    const [selectedGradeId, setSelectedGradeId] = useState(null);
    useEffect(() => {
        const newIds = value.map((d) => d.id);
        events.filters.disciplineIdsChanged.emit({ newIds });
    }, [value, events.filters.disciplineIdsChanged]);
    useEffect(() => {
        return events.filters.gradeIdChanged.subscribe((payload) => {
            setSelectedGradeId(payload?.newId);
        });
    }, [events.filters.gradeIdChanged]);
    useEffect(() => {
        setValue((currentValue) => {
            if (!selectedGradeId)
                return currentValue;
            return currentValue.filter((discipline) => {
                return matchGradeId(discipline, selectedGradeId);
            });
        });
    }, [selectedGradeId]);
    return (React.createElement("div", null,
        React.createElement(Label, null, "Componentes Curriculares"),
        React.createElement(Select, { options: data ?? [], value: value, isMulti: true, isClearable: false, isSearchable: false, closeMenuOnSelect: false, hideSelectedOptions: false, styles: styles(theme), onChange: (newValue) => setValue(newValue), placeholder: "Todos os Componentes Curriculares", noOptionsMessage: () => 'Nenhuma opção disponível', loadingMessage: () => 'Carregando componentes curriculares...', components: {
                Option: MultiValueSelectOption,
                MultiValue: MultiValueSelectValue,
                DropdownIndicator: SelectDropdownIndicator
            }, isLoading: requestStatus === RequestStatus.Loading, getOptionValue: ({ id }) => id, getOptionLabel: ({ name }) => name, filterOption: ({ data: discipline }) => !selectedGradeId || matchGradeId(discipline, selectedGradeId) })));
};
