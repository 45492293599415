import styled from 'styled-components';
import { CampusIcon, OptionListDialog } from '@campus/components';
export const HamburgerIcon = styled(CampusIcon).attrs({
    name: 'hamburger'
}) `
  display: block;

  width: 18px;
  height: 18px;
  color: ${(props) => props.theme.colors.gray1};
`;
export const Container = styled.button `
  align-self: flex-start;
  border: none;
  margin-right: 7px;
  position: relative;
  padding: 0px;
  background: white;
  margin-top: ${(props) => props.$margin}px;
`;
export const List = styled(OptionListDialog) `
  z-index: 1;
  position: absolute;
  top: calc(100% + 4px);
  min-width: 158px;
`;
