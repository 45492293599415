export default class LiteraryWorkVideoItemUser {
    id = '';
    name = '';
    imageUrl = '';
    static fromJson(json) {
        const instance = new LiteraryWorkVideoItemUser();
        instance.id = json.Id;
        instance.name = json.Name;
        instance.imageUrl = json.ImageUrl;
        return instance;
    }
}
