import Discipline from './Discipline';
import TopicWithContents from './TopicWithContents';
import Teacher from './Teacher';
class DisciplineWithContent {
    contentCount;
    contentDuration;
    contentProgress;
    topics;
    discipline;
    teachers;
    isTeacherDiscipline;
    disciplineMatrixId;
    uniqueStatuses;
    constructor() {
        this.topics = [];
        this.teachers = [];
    }
    static fromJson(data) {
        const disciplineWithContent = new DisciplineWithContent();
        disciplineWithContent.contentCount = data.ContentCount;
        disciplineWithContent.contentDuration = data.ContentDuration;
        disciplineWithContent.contentProgress = data.ContentProgress;
        disciplineWithContent.isTeacherDiscipline = data.IsTeacherDiscipline;
        disciplineWithContent.topics =
            data.Topics?.map((item) => TopicWithContents.fromJson(item)) || [];
        disciplineWithContent.discipline = Discipline.fromJson(data.Discipline);
        disciplineWithContent.teachers =
            data.Teachers?.map((item) => Teacher.fromJson(item)) || [];
        disciplineWithContent.disciplineMatrixId = data.DisciplineMatrixId;
        const contents = disciplineWithContent.topics.flatMap((value) => value.contents);
        const statuses = contents.map((value) => value.state);
        disciplineWithContent.uniqueStatuses = [...new Set(statuses)];
        return disciplineWithContent;
    }
    get contentDurationFormat() {
        const hours = this.contentDuration / 60;
        const minutes = this.contentDuration % 60;
        let duration = '';
        if (hours > 0) {
            duration += `${Math.round(hours)}h`;
        }
        if (minutes > 0) {
            if (hours > 0) {
                duration += ` e `;
            }
            duration += `${minutes}min`;
        }
        return duration;
    }
}
export default DisciplineWithContent;
