import styled from 'styled-components';
function getCssForBorderWidth(borderOn, removeRightBorder = false) {
    switch (borderOn) {
        case 'bothSides':
            return `
        border-left-width: 1px;
        border-right-width: 1px;
        transform: translateX(-1px);
        width: calc(100% + 1px) !important;
      `;
        case 'left':
            return 'border-left-width: 1px;';
        case 'right':
            return removeRightBorder ? '' : 'border-right-width: 1px;';
        default:
            return '';
    }
}
export const StyledWrapper = styled.div `
  z-index: 0;

  background-color: ${(props) => props.$backgroundColor
    ? props.$backgroundColor
    : props.theme.colors.gray50};
`;
export const StyledContentWrapper = styled.div `
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.$centered ? 'center' : 'flex-start')};

  ${(props) => (props.$centered ? '' : 'padding-left: 20px;')}

  ${(props) => {
    if (!props.$borderOn)
        return '';
    return `
      border-width: 0;
      border-style: solid;
      border-color: ${props.theme.colors.gray300};

      ${getCssForBorderWidth(props.$borderOn, props.$removeRightBorder)}
    `;
}}
`;
export const StyledLabel = styled.span `
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  white-space: nowrap;

  color: ${(props) => props.theme.colors.gray600};
`;
