import React, { useState } from 'react';
import { useWeeklyPlanningPageContext } from '../../../../../../../context';
import { useActions } from './hooks/useActions';
import { MenuButton, MenuActionsPopupStyled, FiltersAreMissing } from './styles';
const MenuActions = (props) => {
    const [canShowPopup, setCanShowPopup] = useState(false);
    const selectedSegment = useWeeklyPlanningPageContext((store) => store.curriculumComponents.selectedSegment);
    const selectedGrade = useWeeklyPlanningPageContext((store) => store.curriculumComponents.selectedGrade);
    const selectedDisciplines = useWeeklyPlanningPageContext((store) => store.curriculumComponents.selectedDisciplines);
    const mainTableContent = useWeeklyPlanningPageContext((store) => store.mainTable.advancedViewMode.filteredContent);
    const selectedClass = useWeeklyPlanningPageContext((store) => store.mainTable.advancedViewMode.filters.selectedClass);
    const minimumClassroomsDate = useWeeklyPlanningPageContext((store) => store.mainTable.advancedViewMode.filters.minimumClassroomsDate);
    const maximumClassroomsDate = useWeeklyPlanningPageContext((store) => store.mainTable.advancedViewMode.filters.maximumClassroomsDate);
    const onlyEvaluativeAreaSkills = useWeeklyPlanningPageContext((store) => store.mainTable.advancedViewMode.filters.onlyEvaluativeSkills);
    const filtersAreOk = selectedSegment &&
        selectedGrade &&
        selectedDisciplines?.length > 0 &&
        selectedClass;
    const actions = useActions({
        selectedSegment,
        selectedGrade,
        selectedDisciplines,
        selectedClass,
        mainTableContent,
        minimumClassroomsDate,
        maximumClassroomsDate,
        onlyEvaluativeAreaSkills
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(MenuButton, { onClick: () => setCanShowPopup(true) }, "Exportar"),
        React.createElement(MenuActionsPopupStyled, { showClassroomAlertIcon: props.showClassroomAlertIcon, canBeDisplayed: canShowPopup, hidePopup: () => setCanShowPopup(false), generateClassReport: actions.generateClassReport, generateClassDiaryReport: actions.generateClassDiaryReport }, filtersAreOk ? null : (React.createElement(FiltersAreMissing, null,
            React.createElement("span", null,
                "\u00C9 necess\u00E1rio selecionar ao menos um componente curricular ",
                React.createElement("br", null),
                " e uma turma para poder exportar o di\u00E1rio de classe e o relat\u00F3rio de aulas"))))));
};
export default MenuActions;
