import React, { useState } from 'react';
import { RequestStatus } from '@campus/commons';
import { times } from '../../../../../constants';
import { StyledWrapper } from './styles';
import { StyledColumnItem, StyledColumnItemWrapper } from '../commonStyles';
const buttonTexts = {
    diary: {
        [RequestStatus.NotStarted]: 'Exportar Diário (.pdf)',
        [RequestStatus.Failed]: 'Falha ao gerar Diário',
        [RequestStatus.Loading]: 'Gerando Diário...'
    },
    'class-report': {
        [RequestStatus.NotStarted]: 'Exportar Relatório de Aulas (.docx)',
        [RequestStatus.Failed]: 'Falha ao gerar Relatório de Aulas',
        [RequestStatus.Loading]: 'Gerando Relatório de Aulas...'
    }
};
const DocumentsColumn = (props) => {
    const [docBeingGenerated, setDocBeingGenerated] = useState(null);
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    function disableButton(doc) {
        return (!!docBeingGenerated ||
            (docBeingGenerated === doc && requestStatus !== RequestStatus.NotStarted));
    }
    function createClickHandler(doc, generateFn) {
        return async () => {
            try {
                setDocBeingGenerated(doc);
                setRequestStatus(RequestStatus.Loading);
                await generateFn();
                setRequestStatus(RequestStatus.NotStarted);
                setDocBeingGenerated(null);
            }
            catch {
                setRequestStatus(RequestStatus.Failed);
                setTimeout(() => {
                    setRequestStatus(RequestStatus.NotStarted);
                    setDocBeingGenerated(null);
                }, times.toResetARequestStatus);
            }
        };
    }
    return (React.createElement(StyledWrapper, null,
        React.createElement(StyledColumnItemWrapper, { disabled: disableButton('diary'), onClick: createClickHandler('diary', props.onGenerateClassDiaryReport) },
            React.createElement(StyledColumnItem, null, docBeingGenerated === 'diary'
                ? buttonTexts['diary'][requestStatus]
                : buttonTexts['diary'][RequestStatus.NotStarted])),
        React.createElement(StyledColumnItemWrapper, { disabled: disableButton('class-report'), onClick: createClickHandler('class-report', props.onGenerateClassReport) },
            React.createElement(StyledColumnItem, null, docBeingGenerated === 'class-report'
                ? buttonTexts['class-report'][requestStatus]
                : buttonTexts['class-report'][RequestStatus.NotStarted]))));
};
export default DocumentsColumn;
