import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ContainerListFiles, HoverItem, ContentHover } from './styles';
import { CloseButton } from '@campus/components';
import { EFileType } from '../../models';
import { AudioRead, Document, Video, ImageRead } from './components';
const getItemByType = (file, readOnly, onChangeNameFile) => {
    switch (file.fileType) {
        case EFileType.audio:
            return (React.createElement(AudioRead, { link: file.url, name: file.fileName, onChangeTitle: (newName) => onChangeNameFile(newName, file), readOnly: readOnly }));
        case EFileType.document:
            return (React.createElement(Document, { link: file.url, name: file.fileName, onChangeTitle: (newName) => onChangeNameFile(newName, file), readOnly: readOnly }));
        case EFileType.video:
            return (React.createElement(Video, { link: file.url, name: file.fileName, onChangeTitle: (newName) => onChangeNameFile(newName, file), readOnly: readOnly }));
        case EFileType.image:
            return (React.createElement(ImageRead, { link: file.url, name: file.fileName, onChangeTitle: (newName) => onChangeNameFile(newName, file), readOnly: readOnly }));
        default:
            return (React.createElement(Document, { link: file.url, name: file.fileName, onChangeTitle: (newName) => onChangeNameFile(newName, file), readOnly: readOnly }));
    }
};
const ListFileUploaded = ({ files, handleDeleteFile, readOnly, onChangeNameFile }) => {
    return (React.createElement(ContainerListFiles, null, files.map((file) => (React.createElement(ContentHover, { key: uuidv4() },
        getItemByType(file, readOnly, onChangeNameFile),
        !readOnly && (React.createElement(HoverItem, null,
            React.createElement(CloseButton, { onClick: () => handleDeleteFile(file) }))))))));
};
export default ListFileUploaded;
