import React, { Fragment } from 'react';
import { LiteraryWorkQuestionKind } from '../../../../../../models';
import { useLiteraryWorkPageContext } from '../../../../context';
import { useQuestionsTabContext } from '../../context';
import { kindsAndInfos } from '../../commons/types';
import QuestionItem from '../QuestionItem';
import AddQuestionButton from '../AddQuestionButton';
import { Container, Header, Title, NumberOfQuestions, ItemsContainer, Line } from './styles';
const QuestionList = ({ questionKind, handleAddQuestionButtonClicked }) => {
    const { questionsCanBe } = useLiteraryWorkPageContext();
    const { questionSummaries } = useQuestionsTabContext();
    const infos = kindsAndInfos[questionKind];
    const isReadOnly = questionsCanBe !== 'managed';
    const questionIsDiscursive = questionKind === LiteraryWorkQuestionKind.Discursive;
    const items = (questionSummaries ?? [])
        .filter((summary) => summary.kind === questionKind)
        .map((summary, index) => (React.createElement(Fragment, { key: summary.id },
        React.createElement(QuestionItem, { index: index, questionId: summary.id, questionKind: questionKind }),
        React.createElement(Line, { "$separator": true }))));
    const showAddButton = !isReadOnly && items.length < infos.maximumQuantity;
    return (React.createElement(Container, null,
        React.createElement(Header, null,
            React.createElement(Title, { "$questionIsDiscursive": questionIsDiscursive },
                "Quest\u00F5es ",
                infos.inPlural),
            React.createElement(NumberOfQuestions, null,
                items.length,
                " ",
                items.length === 1 ? 'questão' : 'questões')),
        items.length > 0 ? (React.createElement(React.Fragment, null,
            React.createElement(Line, null),
            React.createElement(ItemsContainer, null, items))) : null,
        showAddButton ? (React.createElement(React.Fragment, null,
            React.createElement(Line, null),
            React.createElement(AddQuestionButton, { onClick: handleAddQuestionButtonClicked }))) : null));
};
export default QuestionList;
