import { useState } from 'react';
import { useAnnualTimelineReport, useAnnualTimelineDocxReport } from '../../../../../../../../../../../../../pages/common/components/Reports/hooks/annualTimeline';
import { useStudyUnitReport } from '../../../../../../../../../../../../../pages/common/components/Reports/hooks/studyUnit';
export function useHandlers(props) {
    const [stageBeingUsed, setStageBeingUsed] = useState();
    const [reportBeingGenerated, setReportBeingGenerated] = useState();
    const annualTimeline = useAnnualTimelineReport({
        skills: props.skills,
        selectedGrade: props.selectedGrade,
        selectedSegment: props.selectedSegment,
        selectedDisciplines: props.selectedDisciplines
    });
    const annualTimelineDocx = useAnnualTimelineDocxReport({
        skills: props.skills,
        selectedGrade: props.selectedGrade,
        selectedSegment: props.selectedSegment,
        selectedDisciplines: props.selectedDisciplines
    });
    const studyUnit = useStudyUnitReport({
        skills: props.skills,
        selectedGrade: props.selectedGrade,
        selectedSegment: props.selectedSegment,
        selectedDisciplines: props.selectedDisciplines,
        type: 'pdf'
    });
    const studyUnitDocx = useStudyUnitReport({
        skills: props.skills,
        selectedGrade: props.selectedGrade,
        selectedSegment: props.selectedSegment,
        selectedDisciplines: props.selectedDisciplines,
        type: 'docx'
    });
    function resetReportAndStage() {
        setStageBeingUsed(null);
        setReportBeingGenerated(null);
    }
    async function generateAnnualTimelineReport() {
        if (!!reportBeingGenerated)
            return;
        setReportBeingGenerated('annualTimeline');
        annualTimeline.download().finally(resetReportAndStage);
    }
    async function generateAnnualTimelineDocxReport() {
        if (!!reportBeingGenerated)
            return;
        setReportBeingGenerated('annualTimelineDocx');
        annualTimelineDocx.download().finally(resetReportAndStage);
    }
    async function generateStudyUnitReport(version, stage) {
        if (!!reportBeingGenerated)
            return;
        const shortVersion = version === 'short';
        const report = shortVersion ? 'shortStudyUnit' : 'completeStudyUnit';
        setStageBeingUsed(stage);
        setReportBeingGenerated(report);
        studyUnit.download({ shortVersion, stage }).finally(resetReportAndStage);
    }
    async function generateStudyUnitReportDocx(version, stage) {
        if (!!reportBeingGenerated)
            return;
        const shortVersion = version === 'short';
        const report = shortVersion ? 'shortStudyUnit' : 'completeStudyUnit';
        setStageBeingUsed(stage);
        setReportBeingGenerated(report);
        studyUnitDocx
            .download({ shortVersion, stage })
            .finally(resetReportAndStage);
    }
    return {
        generateAnnualTimelineReport,
        generateAnnualTimelineDocxReport,
        generateStudyUnitReport,
        generateStudyUnitReportDocx,
        stageBeingUsed,
        reportBeingGenerated
    };
}
