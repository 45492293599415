import React, { useState } from 'react';
import { RedCloseButton, GreenCloseButton, OrangeCloseButton, BlueCloseButton, StyledAlert } from './styles';
const CardBanner = ({ title, subTitle, variant = 'info', dismissible = false, id }) => {
    const [show, setShow] = useState(true);
    const variantStyles = {
        success: {
            borderColor: '#27AE60',
            backgroundColor: '#6FCF970D',
            textColor: '#27AE60',
            btnCloseColor: '#27AE60'
        },
        danger: {
            borderColor: '#EB5757',
            backgroundColor: '#EB57570D',
            textColor: '#EB5757',
            btnCloseColor: '#EB5757'
        },
        warning: {
            borderColor: '#F2994A',
            backgroundColor: '#F2C94C0D',
            textColor: '#F2994A',
            btnCloseColor: '#F2994A'
        },
        info: {
            borderColor: '#0072CE',
            backgroundColor: '#2F80ED0D',
            textColor: '#0072CE',
            btnCloseColor: '#0072CE'
        }
    };
    return (React.createElement(StyledAlert, { show: show, variant: variant, dismissible: dismissible, onClose: () => setShow(false), "$styles": variantStyles[variant], "$variant": variant, key: id },
        title && React.createElement("h4", null, title),
        subTitle && React.createElement("p", null, subTitle),
        variant === 'danger' && (React.createElement(RedCloseButton, { role: "button", onClick: () => {
                setShow(false);
            } })),
        variant === 'success' && (React.createElement(GreenCloseButton, { role: "button", onClick: () => {
                setShow(false);
            } })),
        variant === 'warning' && (React.createElement(OrangeCloseButton, { role: "button", onClick: () => {
                setShow(false);
            } })),
        variant === 'info' && (React.createElement(BlueCloseButton, { role: "button", onClick: () => {
                setShow(false);
            } }))));
};
export default CardBanner;
