import React from 'react';
import { Tabs } from '../../../commons/types';
import { Container, TabContainer, TabText } from './styles';
const TabSelector = (props) => {
    const { selectedTab, onTabChange } = props;
    function tabClickHandler(tab) {
        return () => {
            onTabChange(tab);
        };
    }
    return (React.createElement(Container, null,
        React.createElement(TabContainer, { "$isSelected": selectedTab === Tabs.Presentation, onClick: tabClickHandler(Tabs.Presentation) },
            React.createElement(TabText, null, "APRESENTA\u00C7\u00C3O")),
        React.createElement(TabContainer, { "$isSelected": selectedTab === Tabs.VideoAnalysis, onClick: tabClickHandler(Tabs.VideoAnalysis) },
            React.createElement(TabText, null, "AN\u00C1LISE EM V\u00CDDEO")),
        React.createElement(TabContainer, { "$isSelected": selectedTab === Tabs.Questions, onClick: tabClickHandler(Tabs.Questions) },
            React.createElement(TabText, null, "QUEST\u00D5ES"))));
};
export default TabSelector;
