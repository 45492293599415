import React from 'react';
import { Table } from '@campus/components';
import PercentageContainer from './Percentage';
import { DisciplineColor, DisciplineTitle, TableHead, TableSubHead, TablePercentage, TablePanel, TableHeadTitle, TableTd, DisciplineTitleContainer } from '../styles';
const PerformaceTable = ({ discipline, evaluations }) => {
    const disciplineFilter = discipline?.filter((d) => {
        return d.showPerformance;
    }) ?? [];
    return (React.createElement(TablePanel, null,
        React.createElement(Table, { "$hasScoreBorder": false },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement(TableHeadTitle, { rowSpan: 2 },
                        "Meus ",
                        React.createElement("br", null),
                        "Componentes Curriculares"),
                    evaluations.map((e, i) => (React.createElement(TableHead, { key: `${i}-${e.title}`, "$color": e.color, colSpan: 2, width: 120 }, e.title))),
                    React.createElement(TableHead, { colSpan: 2, width: 120 }, "MDC")),
                React.createElement("tr", null,
                    evaluations.map((e, i) => (React.createElement(React.Fragment, { key: `${i}-${e.title}` },
                        React.createElement(TableSubHead, null, e.isPerformace ? 'Desempenho' : 'Engajamento'),
                        React.createElement(TableSubHead, null, "Peso")))),
                    React.createElement(TableSubHead, { width: 140 }, "Desempenho Final"))),
            React.createElement("tbody", null, disciplineFilter.map((d, i) => (React.createElement("tr", { key: `${i}-${d.id}` },
                React.createElement(DisciplineTitle, null,
                    React.createElement(DisciplineTitleContainer, null,
                        React.createElement(DisciplineColor, { "$color": d.color }),
                        "\u00A0",
                        d.name)),
                d.evaluations.map((e, index) => (React.createElement(React.Fragment, { key: `${index}-${e.id}` },
                    React.createElement(TableTd, null,
                        React.createElement(PercentageContainer, { percentages: [
                                { color: e.color, percentage: e.percentage }
                            ], total: e.percentage })),
                    React.createElement(TablePercentage, null,
                        e.evaluationTypeWeight,
                        "%")))),
                React.createElement(TableTd, { total: d.currentWeight, showTotal: d.currentCount > 0 && d.currentWeight > 0 },
                    React.createElement(PercentageContainer, { percentages: d.evaluations.map((e) => ({
                            color: e.color,
                            percentage: e.weight
                        })), total: d.currentWeight, showTotal: d.currentCount > 0 && d.currentWeight > 0 })))))))));
};
export default PerformaceTable;
