import format from 'date-fns/format';
import locale from 'date-fns/locale/pt-BR';
function calculateAverage(array) {
    var total = 0;
    var count = 0;
    array.forEach(function (item, index) {
        total += item;
        count++;
    });
    return total / count;
}
const getColor = (maxValue, value) => {
    if (value > Math.ceil(maxValue * (2 / 3))) {
        return '111, 207, 151';
    }
    else if (value > Math.ceil(maxValue * (1 / 3))) {
        return '242, 201, 76';
    }
    else {
        return '255, 65, 112';
    }
};
class StudentTimeList {
    stats;
    averageTime;
    static fromJson(data) {
        const studentTimeList = new StudentTimeList();
        const maxStudentTime = Math.max(...data.map((t) => t.StudentTime));
        studentTimeList.stats = data.map((student) => ({
            date: format(new Date(student.Date.slice(0, 19)), 'dd/MM'),
            studentLetter: format(new Date(student.Date.slice(0, 19)), 'EEEEE', {
                locale
            }).toUpperCase(),
            studentTime: student.StudentTime,
            classTime: student.ClassTime,
            color: getColor(maxStudentTime, student.StudentTime)
        }));
        studentTimeList.averageTime = Math.round(calculateAverage(data.map((student) => student.StudentTime)));
        return studentTimeList;
    }
}
export default StudentTimeList;
