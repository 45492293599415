export const capitalizeFirstLetter = (string) => {
    const stringSplitted = string.toLowerCase().trim().split(' ');
    let newString = '';
    for (let index = 0; index < stringSplitted.length; index++) {
        const word = stringSplitted[index];
        if (word.length > 1) {
            newString += `${word.charAt(0).toUpperCase() + word.slice(1)} `;
        }
        else {
            newString += `${word} `;
        }
    }
    return newString;
};
