import { LiteraryWorkQuestionKind } from '../../../../../models';
export const kindsAndInfos = {
    [LiteraryWorkQuestionKind.Objective]: {
        inPlural: 'Objetivas',
        inSingular: 'Objetiva',
        maximumQuantity: 10
    },
    [LiteraryWorkQuestionKind.Discursive]: {
        inPlural: 'Discursivas',
        inSingular: 'Discursiva',
        maximumQuantity: 3
    }
};
