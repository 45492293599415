import React from 'react';
import { Filters } from './components/Filters';
import { PageHeader } from './components/PageHeader';
import { MenuAndSeparator } from './components/MenuAndSeparator';
import { Table } from './components/Table';
import { Page, PageContent } from './styles';
import { UseSkillManagementContextProvider } from './context';
const SkillManagement = () => {
    return (React.createElement(UseSkillManagementContextProvider, null,
        React.createElement(Page, null,
            React.createElement(PageHeader, null),
            React.createElement(PageContent, null,
                React.createElement(Filters, null),
                React.createElement(MenuAndSeparator, null),
                React.createElement(Table, null)))));
};
export default SkillManagement;
