import { ContentItem, ContentItemState } from '@campus/content-items';
export default class LiteraryWorkPresentationItem extends ContentItem {
    static fromJson(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    json) {
        const contentItem = ContentItem.fromJson(json);
        const presentationItem = new LiteraryWorkPresentationItem();
        presentationItem.id = contentItem.id ?? '';
        presentationItem.data = contentItem.data;
        presentationItem.itemType = contentItem.itemType;
        presentationItem.saved = contentItem.saved ?? false;
        presentationItem.state = contentItem.state ?? ContentItemState.Read;
        if (contentItem.placeholder) {
            presentationItem.placeholder = contentItem.placeholder;
        }
        return presentationItem;
    }
    afterCreation() {
        this.saved = false;
        this.state = ContentItemState.Creating;
    }
}
