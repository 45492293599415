import { useRef, useEffect, useCallback } from 'react';
function initialStartDate() {
    const date = new Date();
    date.setMonth(0, 1);
    return date;
}
function initialEndDate() {
    return new Date();
}
export function useFilters(events) {
    const filters = useRef({
        gradeId: null,
        disciplineIds: null,
        classIds: null,
        startDate: initialStartDate(),
        endDate: initialEndDate()
    });
    useEffect(() => {
        return events.filters.gradeIdChanged.subscribe((payload) => {
            filters.current.gradeId = payload?.newId ?? null;
            events.filters.someFilterChanged.emit();
        });
    }, [events.filters.gradeIdChanged, events.filters.someFilterChanged]);
    useEffect(() => {
        return events.filters.disciplineIdsChanged.subscribe((payload) => {
            filters.current.disciplineIds =
                payload?.newIds?.length > 0 ? payload.newIds : null;
            events.filters.someFilterChanged.emit();
        });
    }, [events.filters.disciplineIdsChanged, events.filters.someFilterChanged]);
    useEffect(() => {
        return events.filters.classIdsChanged.subscribe((payload) => {
            filters.current.classIds =
                payload?.newIds?.length > 0 ? payload.newIds : null;
            events.filters.someFilterChanged.emit();
        });
    }, [events.filters.classIdsChanged, events.filters.someFilterChanged]);
    useEffect(() => {
        return events.filters.startDateChanged.subscribe((payload) => {
            filters.current.startDate = payload?.newDate;
            events.filters.someFilterChanged.emit();
        });
    }, [events.filters.someFilterChanged, events.filters.startDateChanged]);
    useEffect(() => {
        return events.filters.endDateChanged.subscribe((payload) => {
            filters.current.endDate = payload?.newDate;
            events.filters.someFilterChanged.emit();
        });
    }, [events.filters.endDateChanged, events.filters.someFilterChanged]);
    return useCallback(() => filters.current, []);
}
