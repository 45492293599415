import format from 'date-fns/format';
import { generateColor } from '../utils';
class TopicContent {
    id;
    name;
    startTime;
    endTime;
    evaluationTypeName;
    totalWeight;
    disciplineName;
    evaluationTypeColor;
    state;
    stateText;
    statusTitle;
    stateDate;
    score;
    contentType;
    finished;
    published;
    estimatedDuration;
    ignoreMDC;
    pendingCorrection;
    static fromJson(data) {
        const content = new TopicContent();
        content.id = data.Id;
        content.name = data.Name;
        content.startTime = new Date(data.StartTime);
        content.endTime = data.EndTime ? new Date(data.EndTime) : null;
        content.evaluationTypeName = data.EvaluationTypeName;
        content.totalWeight = data.TotalWeight;
        content.state = data?.State;
        content.stateText = data?.StateText;
        content.statusTitle = data.StateTitle;
        const formattedDate = data.StateDate
            ? format(new Date(data.StateDate), 'dd/MM/yyyy HH:mm:ss')
            : null;
        content.stateDate = formattedDate;
        content.score = data?.Score;
        content.contentType = data?.ContentType;
        content.finished = data?.Finished;
        content.published = data?.Published;
        content.estimatedDuration = data?.EstimatedDuration;
        content.ignoreMDC = data?.IgnoreMDC;
        content.pendingCorrection = data?.PendingCorrection;
        content.evaluationTypeColor = data?.EvaluationTypeColor;
        content.setColor();
        return content;
    }
    setColor() {
        this.ignoreMDC === true
            ? (this.evaluationTypeColor = 'hsl(0, 0%, 74%)')
            : this.evaluationTypeColor != null
                ? this.evaluationTypeColor
                : (this.evaluationTypeColor = generateColor(this.evaluationTypeName));
    }
    getDisponibilityDateFormat() {
        let value = `Publicado em ${format(this.startTime, 'dd/MM HH:mm')}`;
        if (this.endTime) {
            value += ` até ${format(this.endTime, 'dd/MM HH:mm')}`;
        }
        return value;
    }
}
export default TopicContent;
