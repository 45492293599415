import React, { useState } from 'react';
import HowItWorksIcon from '@campus/components/assets/icons/howItWorks.svg';
import Video from '../Video';
import { Header, Title, TimeSeries, HowItWorksContainer, HowItWorks } from './styles';
const TitleContainer = ({ title, timeSeries, videoURL }) => {
    const [openModal, setOpenModal] = useState(false);
    const videoOpen = !(!videoURL || /^\s*$/.test(videoURL));
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, null,
            React.createElement("div", null,
                React.createElement(Title, null, title),
                React.createElement(TimeSeries, null, timeSeries ? `Últimos ${timeSeries} dias` : 'Todo o período')),
            React.createElement(HowItWorksContainer, null,
                React.createElement(HowItWorks, { videoOpen: videoOpen, onClick: () => setOpenModal(videoOpen) },
                    "Entenda",
                    React.createElement("br", null),
                    "como funciona"),
                React.createElement("img", { src: HowItWorksIcon, alt: "" }))),
        React.createElement(Video, { title: title, open: openModal, handleClose: () => setOpenModal(false), videoURL: videoURL })));
};
export default TitleContainer;
