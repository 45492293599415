import { Api } from '@campus/auth';
import { Response } from '@campus/commons';
import DidaticSequence from '../models/DidaticSequence';
import { handleApiCallError } from './common';
const baseEndpointsPath = '/didacticsequence';
const endpoints = {
    update: {
        sequence: baseEndpointsPath,
        classroomsForSubsequentActivities({ didacticSequenceId }) {
            return `${baseEndpointsPath}/${didacticSequenceId}/update-classrooms-for-subsequent-activities`;
        }
    },
    add: { sequence: baseEndpointsPath },
    delete: { sequence: baseEndpointsPath },
    get: { sequence: baseEndpointsPath + '/byid' },
    checkIfClassroomsAreLinkedToOtherActivities: baseEndpointsPath + '/check-if-classrooms-are-linked-to-other-activities'
};
export async function getDidaticSequenceById(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(endpoints.get.sequence, {
            signal: params?.abortSignal,
            params: { id: params.id }
        });
        const content = DidaticSequence.fromJson(response.data);
        return Response.fromData(content, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function deleteDidaticSequence(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpointWithId = `${endpoints.delete.sequence}/${params.id}`;
        const response = await axiosInstance.delete(endpointWithId, {
            signal: params.abortSignal
        });
        return Response.fromData(true, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function updateDidaticSequence(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpointWithId = `${endpoints.update.sequence}/${params.id}`;
        const response = await axiosInstance.put(endpointWithId, {
            Order: params.newIndex,
            Deleted: params.isDeleted,
            Description: params.description,
            ImpactTheMDC: params.impactTheMDC,
            EvaluationTypeId: params.evaluationTypeId
        }, { signal: params.abortSignal });
        return Response.fromData(null, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function addDidacticSequence(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.post(endpoints.add.sequence, {
            Description: params.description,
            ImpactTheMDC: params.impactTheMDC,
            PedagogicalPlanningId: params.planningId,
            EvaluationTypeId: params.evaluationTypeId
        }, { signal: params?.abortSignal });
        const data = DidaticSequence.fromJson(response.data);
        return Response.fromData(data, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function swapOrder(params) {
    try {
        const updateFirstPromise = updateDidaticSequence({
            id: params.firstOne.sequence.id,
            newIndex: params.firstOne.newIndex,
            isDeleted: params.firstOne.sequence.isDeleted,
            description: params.firstOne.sequence.description,
            impactTheMDC: params.firstOne.sequence.impactTheMDC,
            evaluationTypeId: params.firstOne.sequence.evaluationType?.id
        });
        const updateSecondPromise = updateDidaticSequence({
            id: params.secondOne.sequence.id,
            newIndex: params.secondOne.newIndex,
            isDeleted: params.secondOne.sequence.isDeleted,
            description: params.secondOne.sequence.description,
            impactTheMDC: params.secondOne.sequence.impactTheMDC,
            evaluationTypeId: params.secondOne.sequence.evaluationType?.id
        });
        await Promise.all([updateFirstPromise, updateSecondPromise]);
        return Response.fromData(true, 200);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function checkIfClassroomsAreLinkedToOtherActivities(params) {
    try {
        const endpoint = endpoints.checkIfClassroomsAreLinkedToOtherActivities;
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.post(endpoint, {
            ClassroomIds: params.classroomIds,
            PedagogicalPlanningId: params.pedagogicalPlanningId,
            DidacticSequenceId: params.didacticSequenceId ?? null
        }, { signal: params.abortSignal });
        return Response.fromData(response.data, response.status);
    }
    catch (error) {
        return Response.fromError(error);
    }
}
export async function updateClassroomsForSubsequentActivities(params) {
    try {
        const endpoint = endpoints.update.classroomsForSubsequentActivities(params);
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.post(endpoint, { ClassIds: params.classIds }, { signal: params.abortSignal });
        return Response.fromData(response.data, response.status);
    }
    catch (error) {
        return Response.fromError(error);
    }
}
