import React from 'react';
import CloseRadius from '@campus/components/assets/icons/close-radius.svg';
import { StyledModal, Container, CloseImg, IFrame } from './styles';
const Video = ({ title, open, handleClose, videoURL }) => {
    return (React.createElement(StyledModal, { show: open, onHide: handleClose, centered: true, fullscreen: true },
        React.createElement(StyledModal.Header, null,
            React.createElement(CloseImg, { src: CloseRadius, alt: "Fechar V\u00EDdeo", onClick: handleClose })),
        React.createElement(StyledModal.Body, null,
            React.createElement(Container, null,
                React.createElement(IFrame, { src: `${videoURL}?autoplay=true`, title: title, allowFullScreen: true, frameBorder: "0" })))));
};
export default Video;
