class Segment {
    id;
    educationLevelId;
    name;
    suffix;
    static fromJson(data) {
        const segment = new Segment();
        segment.id = data.Id;
        segment.educationLevelId = data.EducationLevelId;
        segment.name = data.Name;
        segment.suffix = data.Suffix;
        return segment;
    }
}
export default Segment;
