import styled from 'styled-components';
export const WorkDetailsAndTabsContainer = styled.div `
  padding-top: 24px;

  grid-column: full-width;

  display: grid;
  row-gap: 27.58px;
  grid-template-columns: inherit;

  background: linear-gradient(180deg, #f8f8f8 69.15%, #eee 100%);
`;
