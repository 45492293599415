export const elementClassNames = {
    holeEditor: '.ck-editor',
    header: '.ck-editor__top',
    body: '.ck-editor__main',
    headerContentWrapper: '.ck-sticky-panel',
    headerContent: '.ck-sticky-panel__content',
    toolbar: '.ck-toolbar',
    toolbarItems: '.ck-toolbar__items',
    editableArea: '.ck-content',
    contentPlaceholder: '.ck-placeholder',
    widget: '.ck-widget'
};
export const customProperties = {
    borderRadius: '--ck-border-radius',
    defaultZIndex: '--ck-z-default',
    modalZIndex: '--ck-z-modal'
};
