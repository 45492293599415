class ValidatorErrors {
    fields;
    constructor(error) {
        this.fields = error.details.reduce((acc, item) => ({ ...acc, [item.path[0]]: item.message }), {});
    }
    withError(field) {
        return !!this.fields[field];
    }
    message(field) {
        return this.fields[field];
    }
}
export default ValidatorErrors;
