class Class {
    id;
    name;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new Class();
        instance.id = json['Id'];
        instance.name = json['Name'];
        return instance;
    }
}
export class SimplifiedViewModeTableClassroom {
    id;
    date;
    number;
    class;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new SimplifiedViewModeTableClassroom();
        instance.id = json['Id'];
        instance.number = json['Number'];
        instance.class = Class.fromJson(json['Class']);
        instance.date = json['Date'] ? new Date(json['Date']) : null;
        return instance;
    }
}
export default class SimplifiedViewModeTableActivity {
    id;
    content;
    classrooms;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new SimplifiedViewModeTableActivity();
        instance.id = json['Id'];
        instance.content = json['Content'];
        instance.classrooms = Array.isArray(json['Classrooms'])
            ? json['Classrooms'].map((item) => SimplifiedViewModeTableClassroom.fromJson(item))
            : [];
        return instance;
    }
}
