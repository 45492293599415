import React, { useRef, useCallback } from 'react';
import { useExternalClick } from '@campus/commons';
import Iframe from '../../components/Iframe';
import { Title, ContainerTitle, ContainerVideo, Container, DatePublish, UserPhoto, ContainerData, ContainerInfo, UserName } from './styles';
const MainVideoReadOnly = ({ video }) => {
    const menuRef = useRef(null);
    const containerRef = useRef(null);
    const handleExternalClick = useCallback(() => {
        menuRef.current?.hideDialog();
    }, []);
    useExternalClick(containerRef, handleExternalClick);
    return (React.createElement(Container, { ref: containerRef },
        React.createElement(ContainerVideo, null,
            React.createElement(Iframe, { url: video.url, title: video.title, controls: true })),
        React.createElement(ContainerTitle, null,
            React.createElement(Title, { value: video.title, disabled: true })),
        React.createElement(ContainerData, null,
            React.createElement(UserPhoto, { src: video.user.imageUrl }),
            React.createElement(ContainerInfo, null,
                React.createElement(UserName, null, video.user.name),
                React.createElement(DatePublish, null, video.publishDate)))));
};
export default MainVideoReadOnly;
