import React from 'react';
import { CampusChartContainer } from './styles';
import { Chart, registerables } from 'chart.js';
const AccessTime = ({ stats }) => {
    const accessTimeRef = React.useRef();
    React.useLayoutEffect(() => {
        Chart.register(...registerables);
        const canvas = accessTimeRef.current.getContext('2d');
        var gradientGridLine = canvas.createLinearGradient(0, 140, 0, 0);
        gradientGridLine.addColorStop(0, 'rgba(0, 0, 0, 1)');
        gradientGridLine.addColorStop(0, 'rgba(0, 0, 0, 0.2)');
        gradientGridLine.addColorStop(1, 'rgba(0, 0, 0, 0)');
        const data = {
            datasets: [
                {
                    type: 'bar',
                    label: 'Aluno',
                    data: stats.map((s) => s.studentTime),
                    backgroundColor: "rgb(138 216 170)",
                    barThickness: 14,
                    borderRadius: 4
                }
            ],
            labels: stats.map((s) => s.studentLetter)
        };
        const chart = new Chart(accessTimeRef.current, {
            data,
            type: 'line',
            options: {
                maintainAspectRatio: true,
                plugins: {
                    legend: {
                        display: false,
                        labels: {
                            font: {
                                family: 'Gilroy'
                            }
                        }
                    },
                    tooltip: {
                        callbacks: {
                            label: (tooltipItem) => ` ${tooltipItem.raw} min`,
                            title: (tooltipItem) => tooltipItem[0].dataset.label,
                            footer: (tooltipItem) => stats[tooltipItem[0].dataIndex].date
                        },
                        titleFont: {
                            size: 16
                        },
                        bodyFont: {
                            size: 14
                        },
                        footerMarginTop: 8
                    }
                },
                scales: {
                    xAxes: {
                        display: true,
                        grid: {
                            drawBorder: true,
                            borderColor: 'rgba(189, 189, 189, 0.5)',
                            color: gradientGridLine
                        },
                        ticks: {
                            padding: 0,
                            font: {
                                family: 'Gilroy',
                                size: 11,
                                weight: '400'
                            },
                            color: '#BDBDBD'
                        }
                    },
                    yAxes: {
                        display: false
                    }
                }
            }
        });
        return () => {
            Chart.unregister();
            chart.destroy();
        };
    }, []);
    return (React.createElement("div", { className: "w-100" },
        React.createElement(CampusChartContainer, { ref: accessTimeRef })));
};
export default AccessTime;
