import React from 'react';
import { Container, VestibularBox, VestibularName } from './styles';
const WorkRelatedVestibulars = ({ literaryWork }) => {
    const { relatedVestibulars } = literaryWork;
    if (!relatedVestibulars)
        return null;
    if (relatedVestibulars.length === 0)
        return null;
    return (React.createElement(Container, null, relatedVestibulars.map((vestibular, index) => (React.createElement(VestibularBox, { key: vestibular + index },
        React.createElement(VestibularName, null, vestibular.toUpperCase()))))));
};
export default WorkRelatedVestibulars;
