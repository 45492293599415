import { toast } from '@campus/components';
import { RequestStatus } from '@campus/commons';
import { updateDidacticSequenceAndItsClassrooms } from '../../../../../../services/agregators';
import * as actions from '../../common/addOrEditActivity/actions';
export function useActions({ store, activity, abortSignal, pedagogicalPlanningId, setRequestStatus, handleActivityUpdated, hideModalAfterDelay }) {
    function getUpdatedGroupActivities() {
        return actions.getUpdatedGroupActivities({
            abortSignal,
            pedagogicalPlanningId
        });
    }
    function reorderActivities() {
        return actions.reorderActivities({
            abortSignal,
            pedagogicalPlanningId
        });
    }
    function updateSubsequentActivities(newActivity) {
        return actions.updateSubsequentActivities({
            newActivity,
            abortSignal,
            pedagogicalPlanningId,
            getAllSelectedClassrooms() {
                return store.getState().getAllSelectedClassrooms();
            }
        });
    }
    async function isThereAnyClassroomLinkedToOtherActivities() {
        setRequestStatus(RequestStatus.Loading);
        const result = actions.isThereAnyClassroomLinkedToOtherActivities({
            abortSignal,
            pedagogicalPlanningId,
            didacticSequenceId: activity.id,
            getAllSelectedClassrooms() {
                return store.getState().getAllSelectedClassrooms();
            },
            onError() {
                toast.error('Falha ao verificar se já existem atividades vinculadas às aulas da nova atividade');
            }
        });
        setRequestStatus(RequestStatus.NotStarted);
        return result;
    }
    async function updateActivity() {
        const state = store.getState();
        const response = await updateDidacticSequenceAndItsClassrooms({
            abortSignal,
            id: activity.id,
            newIndex: activity.order,
            impactTheMDC: state.impactTheMDC,
            description: state.sequenceDescription,
            isDeleted: activity.isDeleted,
            oldClassrooms: activity.classrooms,
            newClassrooms: state.getAllSelectedClassrooms(),
            evaluationTypeId: state.selectedEvaluationType?.id
        });
        if (response.data) {
            return response.data;
        }
        return null;
    }
    async function getUpdatedActivitiesFailed(updatedActivity) {
        setRequestStatus(RequestStatus.Succeeded);
        toast.error('A atividade foi atualizada e as atividades do bloco foram reordenadas porém não foi possível recuperar as atividades do bloco atualizadas');
        handleActivityUpdated({
            updatedActivity,
            updatedGroupActivities: null,
            otherActivitiesWereUpdated: false
        });
        hideModalAfterDelay();
    }
    function reorderFailed(updatedActivity) {
        setRequestStatus(RequestStatus.Succeeded);
        toast.error('A atividade foi atualizada com sucesso porém não foi possível reordenar as atividades do bloco');
        handleActivityUpdated({
            updatedActivity,
            updatedGroupActivities: null,
            otherActivitiesWereUpdated: false
        });
        hideModalAfterDelay();
    }
    function addActivityFailed() {
        setRequestStatus(RequestStatus.Failed);
        toast.error('Falha ao adicionar atividade');
    }
    function updateSubsequentActivitiesFailed(updatedActivity) {
        setRequestStatus(RequestStatus.Succeeded);
        toast.error('A atividade foi atualizada com sucesso porém não foi possível alterar as aulas das atividades subsequentes');
        handleActivityUpdated({
            updatedActivity,
            updatedGroupActivities: null,
            otherActivitiesWereUpdated: false
        });
        hideModalAfterDelay();
    }
    async function justUpdateActivity() {
        setRequestStatus(RequestStatus.Loading);
        const updatedActivity = await updateActivity();
        if (!updatedActivity) {
            addActivityFailed();
            return;
        }
        const reorderSucceeded = await reorderActivities();
        if (!reorderSucceeded) {
            reorderFailed(updatedActivity);
            return;
        }
        const updatedActivities = await getUpdatedGroupActivities();
        if (!updatedActivities) {
            getUpdatedActivitiesFailed(updatedActivity);
            return;
        }
        setRequestStatus(RequestStatus.Succeeded);
        handleActivityUpdated({
            updatedActivity,
            otherActivitiesWereUpdated: false,
            updatedGroupActivities: updatedActivities
        });
        hideModalAfterDelay();
    }
    async function updateActivityAndTheOtherOnes() {
        setRequestStatus(RequestStatus.Loading);
        const updatedActivity = await updateActivity();
        if (!updatedActivity) {
            addActivityFailed();
            return;
        }
        const updateSucceeded = await updateSubsequentActivities(updatedActivity);
        if (!updateSucceeded) {
            updateSubsequentActivitiesFailed(updatedActivity);
            return;
        }
        const reorderSucceeded = await reorderActivities();
        if (!reorderSucceeded) {
            reorderFailed(updatedActivity);
            return;
        }
        setRequestStatus(RequestStatus.Succeeded);
        handleActivityUpdated({
            updatedActivity,
            updatedGroupActivities: null,
            otherActivitiesWereUpdated: true
        });
        hideModalAfterDelay();
    }
    return {
        justUpdateActivity,
        updateActivityAndTheOtherOnes,
        isThereAnyClassroomLinkedToOtherActivities
    };
}
