import { toast } from '@campus/components';
import { getClassReportFileContent, getDataToDiary } from '../../../../../../../../../../services/pedagogicalPlanning';
import { downloadFile } from '../../../../../../../../../common/components/Reports/hooks/common';
import { useClassDiaryReport } from '../../../../../../../../../common/components/Reports/hooks/classDiary';
export function useActions({ selectedSegment, selectedGrade, selectedDisciplines, selectedClass, mainTableContent, minimumClassroomsDate, maximumClassroomsDate, onlyEvaluativeAreaSkills }) {
    const { download: downloadDiary } = useClassDiaryReport();
    async function generateClassDiaryReport() {
        const result = await getDataToDiary({
            segmentId: selectedSegment.id,
            gradeId: selectedGrade.id,
            disciplineId: selectedDisciplines[0].id,
            classId: selectedClass.id
        });
        if (!result.data || result.error) {
            toast.error('Falha ao gerar o Diário');
            throw new Error('unknown-reason');
        }
        await downloadDiary(mainTableContent, result.data, selectedGrade, selectedDisciplines, selectedClass);
    }
    async function generateClassReport() {
        const response = await getClassReportFileContent({
            segmentId: selectedSegment.id,
            gradeId: selectedGrade.id,
            classId: selectedClass.id,
            disciplineIds: selectedDisciplines.map((d) => d.id),
            onlyEvaluativeAreaSkills,
            classroomStartDate: minimumClassroomsDate,
            classroomEndDate: maximumClassroomsDate
        });
        if (!response.data || response.error) {
            toast.error('Falha ao gerar o Relatório de Aulas');
            throw new Error('unknown-reason');
        }
        await downloadFile(response.data, 'Relatório de Aulas.docx');
    }
    return { generateClassDiaryReport, generateClassReport };
}
