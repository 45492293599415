import React from 'react';
import { List } from './styles';
const MenuOptionsDialog = ({ onClickEdit, onClickDelete }) => {
    const items = [
        {
            label: 'Editar Obra',
            onClick: onClickEdit
        },
        {
            label: 'Apagar Obra',
            onClick: onClickDelete
        }
    ];
    return React.createElement(List, { items: items });
};
export default MenuOptionsDialog;
