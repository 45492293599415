export default class ClassroomDuplicated {
    classId;
    className;
    classroom;
    didacticSequenceId;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new ClassroomDuplicated();
        instance.classId = json.ClassId;
        instance.className = json.ClassName ?? null;
        instance.classroom = json.Classroom;
        instance.didacticSequenceId = json.DidacticSequenceId ?? null;
        return instance;
    }
}
