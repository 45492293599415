import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import { RequestStatus } from '@campus/commons';
import { useFetchGrades } from '../../hooks';
import { SelectDropdownIndicator } from '../SelectDropdownIndicator';
import { Label } from '../commonStyles';
import { styles } from './styles';
import { useSkillManagementContext } from '../../../../context';
export const SegmentAndGrade = () => {
    const theme = useTheme();
    const { events } = useSkillManagementContext();
    const { data, requestStatus } = useFetchGrades();
    const [value, setValue] = useState(null);
    const storageSegment = '@campus:skillManagement:segmentAndGrade';
    useEffect(() => {
        const storedSegment = sessionStorage.getItem(storageSegment);
        if (storedSegment && data) {
            const filterSegment = data.find((d) => storedSegment === d.id);
            setValue(filterSegment);
        }
    }, [data]);
    useEffect(() => {
        if (value) {
            events.filters.gradeIdChanged.emit({
                newGradeId: value.id,
                newSegmentId: value.segment?.id ?? null
            });
        }
        else {
            events.filters.gradeIdChanged.emit({
                newGradeId: null,
                newSegmentId: null
            });
        }
    }, [events.filters.gradeIdChanged, value]);
    return (React.createElement("div", null,
        React.createElement(Label, null, "Segmento/ S\u00E9rie"),
        React.createElement(Select, { options: data ?? [], value: value, styles: styles(theme), hideSelectedOptions: true, onChange: (newValue) => {
                sessionStorage.setItem(storageSegment, newValue.id);
                setValue(newValue);
            }, placeholder: "Selecione um segmento/s\u00E9rie", noOptionsMessage: () => 'Nenhuma opção disponível', loadingMessage: () => 'Carregando segmentos e séries...', components: {
                DropdownIndicator: SelectDropdownIndicator
            }, isLoading: requestStatus === RequestStatus.Loading, getOptionValue: (g) => g.id, getOptionLabel: ({ segment, name }) => {
                return segment?.name ? `${segment.name} - ${name}` : name;
            } })));
};
