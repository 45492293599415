import React from 'react';
import { Col } from 'react-bootstrap';
import { breakpointsAndCardsPerRow } from '../utils/cardsPerRow';
import Card from '../Card';
const DisabledCards = ({ listLength, customMinHeight, breakpoint }) => {
    const cardsPerRow = breakpointsAndCardsPerRow[breakpoint];
    const countDisabledCards = listLength === 0
        ? cardsPerRow
        : Math.ceil(listLength / cardsPerRow) * cardsPerRow - listLength;
    return (React.createElement(React.Fragment, null, [...Array(countDisabledCards)].map((_, index) => (React.createElement(Col, { key: index },
        React.createElement(Card, { disabled: true, fullHeight: true, style: { minHeight: customMinHeight ?? 120 } }))))));
};
export default DisabledCards;
