import ClassroomDuplicated from './ClassroomDuplicated';
import ClassroomNotFound from './ClassroomNotFound';
export default class ClassroomAlert {
    classroomDuplicates;
    classroomNotFounds;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new ClassroomAlert();
        instance.classroomDuplicates =
            json.Duplicates?.map((item) => ClassroomDuplicated.fromJson(item)) ?? [];
        instance.classroomNotFounds =
            json.NotFounds?.map((item) => ClassroomNotFound.fromJson(item)) ?? [];
        return instance;
    }
}
