import { css } from 'styled-components';
export const commonStyles = css `
  height: 50px;

  padding: 0;

  font-size: 14px;
  font-weight: 500;

  line-height: 120%;

  color: ${({ theme }) => theme.colors.gray600};

  border-style: solid;
  border-width: 0 1px 1px 0;
  border-color: ${({ theme }) => theme.colors.gray300};
`;
