import React, { useEffect, useMemo, useState } from 'react';
import { RequestStatus } from '@campus/commons';
import { AddOrEditActivityPopupContext } from '../common/addOrEditActivity/context';
import Methodology from '../common/addOrEditActivity/components/Methodology';
import ActivityType from '../common/addOrEditActivity/components/ActivityType';
import ClassLessonAndDateList from '../common/addOrEditActivity/components/ClassLessonAndDateList';
import EditActivityButton from './components/EditActivityButton';
import { times } from '../../../constants';
import { useIsMounted } from '../../../hooks';
import { ClassroomActionsModal } from '../../Modals/ClassroomActionsModal';
import { createAddOrEditActivityPopupStore } from '../common/addOrEditActivity/store';
import { useFetchEvaluationTypes } from '../common/addOrEditActivity/hooks/useFetchEvaluationTypes';
import { StyledTitle, StyledCancelButton } from '../common/styles';
import { StyledButtonsWrapper, StyledContentWrapper, StyledFirstColumnWrapper, StyledPopup, StyledSecondColumnWrapper } from '../common/addOrEditActivity/styles';
import { useActions } from './hooks/useActions';
const EditActivityPopup = (props) => {
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    const [showClassroomActionsModal, setShowClassroomActionsModal] = useState(false);
    const { isMounted } = useIsMounted();
    const abortController = useMemo(() => {
        return new AbortController();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.canBeDisplayed]);
    const store = useMemo(() => {
        if (!props.canBeDisplayed)
            return null;
        return createAddOrEditActivityPopupStore({
            sequence: props.sequence,
            planning: props.planning,
            abortSignal: abortController.signal
        });
    }, [props.planning, props.sequence, props.canBeDisplayed, abortController]);
    useFetchEvaluationTypes(props.disciplines, store, abortController.signal);
    const actions = useActions({
        store,
        activity: props.sequence,
        abortSignal: abortController.signal,
        pedagogicalPlanningId: props.planning.id,
        setRequestStatus,
        handleActivityUpdated: props.handleActivityUpdated,
        hideModalAfterDelay() {
            setTimeout(() => {
                if (isMounted()) {
                    props.hide();
                }
            }, times.toHideAModalOrPopupOnSuccess);
        }
    });
    useEffect(() => {
        setRequestStatus(RequestStatus.NotStarted);
    }, [props.canBeDisplayed]);
    useEffect(() => {
        return () => abortController.abort();
    }, [abortController, props.canBeDisplayed]);
    useEffect(() => {
        const state = store?.getState();
        state?.setClassesAndInfos(props.classroomGroups.data, props.classroomGroups.status);
    }, [store, props.classroomGroups.data, props.classroomGroups.status]);
    async function onEditButtonClick() {
        if (await actions.isThereAnyClassroomLinkedToOtherActivities()) {
            setShowClassroomActionsModal(true);
        }
        else {
            await actions.justUpdateActivity();
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledPopup, { isFullScreen: true, handleExternalClick: props.hide, canBeDisplayed: props.canBeDisplayed && !showClassroomActionsModal },
            React.createElement(AddOrEditActivityPopupContext.Provider, { value: store },
                React.createElement(StyledTitle, null, "Editar Atividade"),
                React.createElement(StyledContentWrapper, null,
                    React.createElement(StyledFirstColumnWrapper, null,
                        React.createElement(ActivityType, null),
                        React.createElement(ClassLessonAndDateList, { sequence: props.sequence })),
                    React.createElement(StyledSecondColumnWrapper, null,
                        React.createElement(Methodology, null))),
                React.createElement(StyledButtonsWrapper, null,
                    React.createElement(StyledCancelButton, { onClick: props.hide }, "Cancelar"),
                    React.createElement(EditActivityButton, { status: requestStatus, activity: props.sequence, onClick: onEditButtonClick })))),
        React.createElement(ClassroomActionsModal, { updatingActivityVersion: true, show: showClassroomActionsModal, hide: () => setShowClassroomActionsModal(false), onJustAddActivity: actions.justUpdateActivity, onAddActivityAndUpdateOtherOnes: actions.updateActivityAndTheOtherOnes, onCancel: () => {
                abortController.abort();
                props.hide();
            }, sequenceId: props.sequence.id })));
};
export default EditActivityPopup;
