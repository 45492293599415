import React, { useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { DropdownContentItem } from '@campus/components';
import { Container, DropdownContainer, DigitalObjectContent, DigitalObjectImg, DigitalObjectImgContent, DigitalObjectVideoContent, DigitalObjectVideoImg, DigitalObjectName, PlayIconStyled, DropdownContentStyled } from './styles';
import BlockedItem from '../BlockedItem';
import PlayIcon from '@campus/components/assets/icons/play-circle.svg';
var LibraryObjectContentType;
(function (LibraryObjectContentType) {
    LibraryObjectContentType["EMBEDDED"] = "Embedded";
    LibraryObjectContentType["VIDEO"] = "Video";
})(LibraryObjectContentType || (LibraryObjectContentType = {}));
const DigitalObject = ({ className, libraryObjectData, onClick, scrollPosition, hasScroll, hasPermission, getBoundingContainer }) => {
    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef(null);
    const imageContainerRef = useRef(null);
    const isVideo = libraryObjectData?.contentType?.toLowerCase() === 'video';
    useLayoutEffect(() => {
        if (!showMenu)
            return;
        const menu = menuRef.current;
        const imageContainer = imageContainerRef.current;
        const imageContainerRect = imageContainer.getBoundingClientRect();
        menu.style.top = imageContainerRect.bottom + window.scrollY + 'px';
        menu.style.left = imageContainerRect.left + window.scrollX + 'px';
        menu.style.right = 'revert';
        if (!getBoundingContainer)
            return;
        const boundingContainer = getBoundingContainer();
        const boundingContainerRect = boundingContainer.getBoundingClientRect();
        let menuRect = menu.getBoundingClientRect();
        if (menuRect.left < boundingContainerRect.left) {
            menu.style.left = boundingContainerRect.left + window.scrollX + 'px';
        }
        menuRect = menu.getBoundingClientRect();
        if (menuRect.right > boundingContainerRect.right) {
            menu.style.right =
                document.body.offsetWidth -
                    boundingContainerRect.right -
                    window.scrollX +
                    'px';
        }
        menuRect = menu.getBoundingClientRect();
        if (menuRect.width < imageContainerRect.width) {
            menu.style.left = boundingContainerRect.left + window.scrollX + 'px';
        }
    }, [getBoundingContainer, showMenu]);
    const renderDropdownContent = () => {
        const menu = (React.createElement(DropdownContentStyled, { ref: menuRef, hidden: !showMenu, className: "dropdown-content digital-object__menu" }, libraryObjectData.chapters.map((value) => {
            return (React.createElement(DropdownContentItem, { key: value.id, className: "dropdown-content-item digital-object__menu-item", onClick: () => {
                    onClick(libraryObjectData.id, value.id);
                } }, value.name));
        })));
        return createPortal(menu, document.body);
    };
    const handleClickLibraryObject = () => {
        if ((libraryObjectData.chapters?.length === 0 || hasScroll) &&
            libraryObjectData.contentUrl) {
            onClick(libraryObjectData.id);
        }
    };
    return (React.createElement(Container, { className: `dropdown ${className}` },
        React.createElement(DigitalObjectContent, { className: "dropdown-label", role: "button", onClick: handleClickLibraryObject },
            React.createElement(DigitalObjectImgContent, { ref: imageContainerRef, className: "digital-object__image-container", onMouseEnter: () => setShowMenu(true), onMouseLeave: () => setShowMenu(false) },
                React.createElement(DigitalObjectImg, { className: "digital-object__image", src: libraryObjectData.imageUrl, alt: libraryObjectData.name, "$isVideo": isVideo }),
                !hasPermission && React.createElement(BlockedItem, null),
                isVideo && (React.createElement(DigitalObjectVideoContent, { className: "digital-object__video-container" },
                    React.createElement(DigitalObjectVideoImg, { className: "digital-object__video", src: libraryObjectData.imageUrl, alt: libraryObjectData.name }),
                    hasPermission && (React.createElement(PlayIconStyled, { className: "digital-object__play", width: 38, src: PlayIcon, alt: `Assista o conteúdo: ${libraryObjectData.name}` })))),
                libraryObjectData.chapters?.length > 0 &&
                    hasScroll &&
                    hasPermission && (React.createElement(DropdownContainer, { "$right": scrollPosition + 217 }, renderDropdownContent())),
                libraryObjectData.chapters?.length > 0 &&
                    hasPermission &&
                    !hasScroll &&
                    renderDropdownContent()),
            React.createElement(DigitalObjectName, { className: "digital-object__name" }, libraryObjectData.name))));
};
export default DigitalObject;
