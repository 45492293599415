import { Response } from '@campus/commons';
export function isIndexValid(index, array) {
    return index >= 0 && index < array.length;
}
export function swapItems(i1, i2, array) {
    if (!isIndexValid(i1, array))
        return;
    if (!isIndexValid(i2, array))
        return;
    const temporary = array[i1];
    array[i1] = array[i2];
    array[i2] = temporary;
}
export function getValidationErrorsFrom(error) {
    return error instanceof Response && error.error
        ? error.error.validationErrors ?? []
        : [];
}
