import styled from 'styled-components';
export const Container = styled.div `
  display: grid;
  row-gap: 5px;
  column-gap: 4px;
  align-items: start;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
`;
export const TeacherName = styled.div.attrs((props) => ({
    className: props.$color ? 'colored' : ''
})) `
  grid-row: 1;
  grid-column: 1;

  min-height: 20px;

  padding: 3px 6px;

  position: relative;

  border-radius: 4px;
  border: 1px solid ${(props) => props.$color ?? props.theme.colors.gray700};

  &.colored::before {
    content: '';

    position: absolute;
    inset: 0;

    opacity: 0.2;

    background: ${(props) => props.$color};
  }

  > span {
    display: block;

    text-align: center;

    position: relative;

    font-size: 12px;
    font-weight: 600;
    line-height: 120%;
    color: ${(props) => props.$color ?? props.theme.colors.gray700};
  }
`;
export const CreationDate = styled.span `
  grid-row: 1;
  grid-column: 2;

  align-self: center;

  font-size: 12px;
  font-weight: 600;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray400};
`;
export const Content = styled.div `
  grid-row: 2;
  grid-column: 1 / -1;

  overflow-wrap: anywhere;

  font-size: 12px;
  font-weight: 600;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray700};

  p {
    margin: 0;
    line-height: inherit;
  }

  span {
    line-height: inherit;
    text-decoration-line: underline;
    color: ${({ theme }) => theme.colors.blue1};
  }
`;
