import React from 'react';
import { useFollowUpPageContext } from '../../../../../../../../../../../context';
import { useGroupContext } from '../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/context';
import BaseButton from '../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/DidacticSequenceRelated/components/AddActivityButton';
const AddActivityButton = (props) => {
    const updateActivities = useGroupContext((store) => store.updateActivities);
    const addActivity = useGroupContext((store) => store.sequenceOperations.add);
    const { availableClassrooms, selectedDisciplines, userPermissions } = useFollowUpPageContext((store) => ({
        userPermissions: store.commonData.userPermissions.data,
        availableClassrooms: store.commonData.availableClassrooms,
        selectedDisciplines: store.curriculumComponents.selectedDisciplines
    }));
    const disabled = !userPermissions?.userCan.updateTheTeacherColumns();
    return (React.createElement(BaseButton, { planning: props.planning, newActivityIsUnforeseen: true, disciplines: selectedDisciplines, classroomGroups: availableClassrooms, disabled: disabled, handleActivityAdded: (params) => {
            if (params.updatedGroupActivities) {
                updateActivities(params.updatedGroupActivities);
            }
            else {
                addActivity(params.newActivity);
            }
        } }));
};
export default AddActivityButton;
