import { useCallback, useEffect, useState } from 'react';
import { useFetch } from '@campus/commons';
import { getAllVideoAnalysisItems, updateVideoProcessing } from '../../../../../services';
export function useFetchVideoAnalysis({ literaryWorkId }) {
    const [videosStatusUpdated, setVideosStatusUpdated] = useState(false);
    const { data, requestStatus, fetchStarted, handleFailure, handleSuccess } = useFetch({
        initialData: []
    });
    const fetchItems = useCallback(async () => {
        fetchStarted({ resetData: true });
        try {
            const response = await getAllVideoAnalysisItems({
                literaryWorkId
            });
            handleSuccess(response?.data?.items);
        }
        catch (error) {
            handleFailure(error);
        }
    }, [fetchStarted, handleFailure, handleSuccess, literaryWorkId]);
    const handleUpdateProcessing = useCallback(async (videosIds) => {
        try {
            const result = await updateVideoProcessing(literaryWorkId, videosIds);
            const videos = data?.map((video) => {
                const isProcessed = video.isProcessed || !!result.data?.includes(video.id);
                return { ...video, isProcessed };
            });
            handleSuccess(videos);
        }
        catch (error) {
            handleFailure(error);
        }
    }, [data, handleFailure, handleSuccess, literaryWorkId]);
    useEffect(() => {
        const videosIds = data
            ?.filter((video) => !video.isProcessed)
            ?.map((video) => video.id);
        if (!videosStatusUpdated && videosIds?.length > 0) {
            handleUpdateProcessing(videosIds);
            setVideosStatusUpdated(true);
        }
    }, [data, handleUpdateProcessing, literaryWorkId, videosStatusUpdated]);
    useEffect(() => {
        fetchItems();
    }, [fetchItems]);
    return { data, requestStatus };
}
