import React from 'react';
import { RequestStatus } from '@campus/commons';
import { useLiteraryWorkPageContext } from '../../context';
import { useFetchPresentationItems } from './hooks';
import { ReadOnlyItemList, EditableItemList } from './components';
import { Container, PlaceholderContainer, PlaceholderText } from './styles';
const statusAndPlaceholders = {
    [RequestStatus.Loading]: 'Carregando itens da obra...',
    [RequestStatus.Failed]: 'Falha ao carregar itens da obra'
};
const PresentationTab = () => {
    const { isReadOnlyOnGeneral, literaryWorkId } = useLiteraryWorkPageContext();
    const { data, requestStatus } = useFetchPresentationItems({ literaryWorkId });
    if (requestStatus in statusAndPlaceholders) {
        return (React.createElement(PlaceholderContainer, null,
            React.createElement(PlaceholderText, null, statusAndPlaceholders[requestStatus])));
    }
    return (React.createElement(Container, null, isReadOnlyOnGeneral ? (React.createElement(ReadOnlyItemList, { presentationItems: data })) : (React.createElement(EditableItemList, { presentationItems: data }))));
};
export default PresentationTab;
