import styled, { css } from 'styled-components';
import { PageSelector as Selector } from '@campus/components';
export const hovering = {
    color: 'hsl(0, 0%, 60%)',
    transitionMs: '150ms'
};
const loadingStyles = css `
  .page-selector__page-numbers__item.selected {
    opacity: 0.6;
  }
`;
export const PageSelector = styled(Selector) `
  width: 100%;

  .page-selector__go-to-previous-page,
  .page-selector__go-to-next-page,
  .page-selector__page-numbers__item {
    height: 32px;

    background: ${({ theme }) => theme.colors.white};

    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.colors.gray200};

    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.gray500};

    &[disabled],
    &[disabled]:hover {
      border-color: ${({ theme }) => theme.colors.gray200};
      background-color: ${({ theme }) => theme.colors.gray50};
    }

    &:not([disabled]) {
      transition: background-color ${hovering.transitionMs};

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray50};
      }
    }
  }

  .page-selector__page-numbers {
    column-gap: 8px;
  }

  .page-selector__page-numbers__item.selected {
    cursor: default;
    border-color: transparent;
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.blue1};

    &[disabled]:hover,
    &:not([disabled]):hover {
      border-color: transparent;
      background-color: ${({ theme }) => theme.colors.blue1};
    }
  }

  ${({ $loading }) => ($loading ? loadingStyles : '')}
`;
