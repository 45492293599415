import { Api } from '@campus/auth';
import { UserInfo, Response } from '../models';
export const getTeacherInfo = async (id) => {
    try {
        const response = await Api.INSTANCE.getApi().get(`/teacher/user/info`, {
            params: { id: id }
        });
        return Response.fromData(UserInfo.fromJson(response.data), response.status);
    }
    catch (e) {
        return Response.error(e);
    }
};
