export class DataPoint {
    _x;
    _y;
    _group;
    radius;
    diameter;
    hoverRadiusOffset;
    constructor(chartModel) {
        if (!chartModel) {
            throw new Error('chartModel must have a value');
        }
        this._group = [chartModel];
        this._x = chartModel.bubbleX;
        this._y = chartModel.bubbleY;
        this.radius = chartModel.bubbleRadius;
        this.diameter = this.radius + this.radius;
        this.hoverRadiusOffset = chartModel.bubbleHoverRadiusOffset;
    }
    // this is required by the BubbleDataPoint interface
    get r() {
        return this.radius;
    }
    get x() {
        return this._x;
    }
    get y() {
        return this._y;
    }
    get group() {
        return this._group;
    }
    canGroupWith(p2) {
        // if the centers of the two bubbles are
        // separated by an euclidean distance of
        // up to the sum of the radius of the
        // bubbles, then they intersect
        const xDiff = Math.pow(p2.x - this.x, 2);
        const yDiff = Math.pow(p2.y - this.y, 2);
        const distance = Math.sqrt(xDiff + yDiff);
        return distance < this.radius + p2.radius;
    }
    groupWith(p2) {
        // the bubbles are grouped by creating
        // a new bubble in the middle of them
        this._x = (this._x + p2.x) / 2;
        this._y = (this._y + p2.y) / 2;
        this._group = this._group.concat(p2.group);
    }
}
